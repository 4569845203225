/**
 * @author Basit Raza <razabasit88@gmail.com>
 * @link http://be.net/basit_raza Author Website
 * @since 2021-01-18
 */
import React from 'react';
import PageHeader from '../../../components/page-header';
import ChangeTitle from '../../../components/change_page_title';
import PageFooter from '../../../components/page-footer';
import SideBar from '../../../components/side-bar';
import BreadCrumb from '../../../components/bread-crumb';

import Swal from "sweetalert2";
import $ from "jquery";
import {adminApiCall} from "../../../utils/ajax_request";
import Notifications, { notify } from 'react-notify-toast';
import {dateTimeFormat, snakeToCamel, toLowerCase} from "../../../utils/helpers";
import Pagination from "react-js-pagination";
import {baseDomain} from "../../../config";


class CountryList extends React.Component {
	breadcrumbNavigation = [
		{
			title: 'Manage Amenities',
			link: '#'
		},
		{
			title: 'Amenities List',
			link: '#'
		}
	]

	state = {
		list: [],
		pagination: {
			current_page: 1,
			total_pages: '',
			total_records: '',
			itemsCountPerPage: 24
		}
	}
	componentDidMount() {
		this.listing();
	}

	listing = async (page) => {
		try{
			// console.log(queryString)
			$('#loader').show();
			// if(!data){
			let data = {
				current_page : page ? page : this.state?.pagination?.current_page
			}
			// }
			let res = await adminApiCall('amenities/list','POST' , JSON.stringify(data))
			if (!res) {
				notify.show("Something_went_wrong",'error',2000);
				return false;
			}
			if ( res.hasOwnProperty('status') ) {
				if ( res.status === 200 && res.data ) {
					this.setState({list: res.data,
						pagination: {...this.state.pagination , current_page: res.current_page, total_pages: res.total_pages , total_records: res.total_records}})
					console.log(this.state.list)
				} else if ( res.status === 400 ) {
					this.setState({"data" : []})
					notify.show(res.message,'error',2000);
				}
			}
		}
		catch ( e ){
			notify.show("Something_gone_wrong",'error',2000);
		}
		finally {
			$('#loader').hide();
		}
	}

	//===== change status of user =========
	changeStatus = async (e , type, id) => {
		let confirm = window.confirm(`Are you sure you want to ${e.target.checked===true?`Active`:`Disabled`} this amenity?`);
		if (confirm){
			if (id){
				try{
					$('#loader').show();
					let res = await adminApiCall('amenities/change_status','POST' , JSON.stringify({amenity_id: id, type: type,status: e.target.checked}))
					if (!res) {
						notify.show("Something_went_wrong",'error',2000);
						return false;
					}
					if ( res.hasOwnProperty('status') ) {
						if ( res.status === 200 && res.data ) {
							notify.show(res.message,'success',2000);
							setTimeout(()=>{this.listing()}, 2000)
						} else if ( res.status === 400 ) {
							notify.show(res.message,'error',2000);
						}
					}
				}
				catch ( e ){
					notify.show("Something_gone_wrong",'error',2000);
				}
				finally {
					$('#loader').hide();
				}
			}else{
				notify.show("Please choose admin to verify",'error',2000);
			}
		}
	}


	renderListing =  () => {
		if (this.state.list && this.state.list.length > 0) {
			let listing = [];
			try {
				for (const [index, element] of this.state.list.entries()) {
					listing.push(
							<tr key={element?._id+index}>
								<td>{this.state?.pagination?.current_page > 1 ? (this.state?.pagination?.current_page*this.state?.pagination?.itemsCountPerPage)-this.state?.pagination?.itemsCountPerPage+index+1 :index + 1}</td>
								<td>{element?.icon? <img className={"img-80"} src={baseDomain+element?.icon[0]?.url}/>: baseDomain+'/images/amenities/default.jpg'  }</td>
								<td>{  element?.title }</td>
								<td><span className={"badge badge-pill badge-info"}> {element?.type && snakeToCamel(element?.type)}</span></td>
								<td>
									{/*{element?.status === "1" ? <span className={"badge badge-pill badge-success"}>  Active </span> : <span className={"badge badge-pill badge-danger"}>  Disabled </span> } */}
									<div className="toggleBtn " >
										{/*onChange={(e)=> {this.setState({isStatusChecked: e.target.checked})} }*/}
										<input type="checkbox" id={`togglePrice${index}`} checked={element?.status === '1' ? 'checked': ''} onChange={(e)=> this.changeStatus(e,element?.type,element?._id)} name={"status"}  />
										<label htmlFor={`togglePrice${index}`}>
											<span className="on">Active</span>
											<span className="off ">Disable</span>
										</label>
									</div>
								</td>
								<td>
									<button className="myBtn btn-danger btnSm" onClick={()=>this.hitFire(element?._id)}>
										<i class="fa fa-trash"></i>
									</button>
									<button class="myBtn btn-success btnSm mx-1" onClick={() => this.props.history.push("edit-amenity?id="+element?._id)}>
										<i class="fa fa-edit"></i>
									</button>
									{/*<button class="myBtn btn-primary btnSm mr-1" onClick={this.hitBann}>
										<i class="fa fa-ban"></i>
									</button>*/}
									{/*<button class="myBtn btn-info btnSm" data-toggle="modal"
											data-target="#countryDetail">
										<i class=" fa fa-eye"></i>
									</button>*/}
								</td>
							</tr>
					)
				}
			} catch (e) {
				notify.show("Something_gone_wrong", 'error', 2000);
			}
			return listing;
		}
	}

	hitFire(id = '') {
		console.log(this.state.pagination)
		if (id){
			Swal.fire({
				title: 'Are you sure?',
				text: "You won't be able to revert this!",
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Yes, delete it!'
			}).then(async (result) => {
				if (result.isConfirmed){
					let res = await adminApiCall('amenities/delete','POST',JSON.stringify({amenity_id:id}))
					if (!res) {
						Swal.fire(
							'Error!',
							'Something went wrong Please try again.',
							'error'
						)
						return false;
					}
					if ( res.hasOwnProperty('status') ) {
						if ( res.status === 200) {
							Swal.fire(
								'Deleted!!',
								'Record has been deleted.',
								'success'
							)
							this.listing()
						} else if ( res.status === 400 ) {
							notify.show(res.message,'error',1500);
						}
					}
				}
			})
		}else{
			notify.show("No record selected",'error',2000);
		}

	}
	handlePageChange(pageNumber){
		this.setState({pagination: {...this.state.pagination , current_page: pageNumber}} )
		this.listing(pageNumber)
	}
	render() {
		return (
			/*Content Area*/
			<React.Fragment>
				<ChangeTitle title={'Amenity'} />
				{/*SideBar*/}
				<SideBar />

				{/*Page Header*/}
				<PageHeader />
				<Notifications options={{zIndex: 10000}}/>
				<BreadCrumb navigation={this.breadcrumbNavigation} />

				{/* main content starts here */}

				<section class="pageWrapper">
					<div class="container-fluid">
						<div class="row">
							<div class="col-12">
								<div class="contentCard">
									<div class="cardHeader clearfix">
										<h1>Amenities List</h1>
											<button type={"submit"} className="myBtn" onClick={()=> this.props.history.push('add-amenity')}>+ Create Amenity
											</button>
									</div>
									<div class="cardBody">
										<div class="table-responsive customStriped">
											<table class="table">
												<thead>
													<tr>
														<th>#</th>
														<th>Image</th>
														<th>Title</th>
														<th>Industry </th>
														<th> Status </th>
														<th>Action</th>

													</tr>
												</thead>
												<tbody>
												{this.renderListing()}
												</tbody>
											</table>

										</div>
										<div class="paginationWrapper clearfix">
											<Pagination
												activePage={this.state?.pagination?.current_page}
												itemsCountPerPage={this.state?.pagination?.itemsCountPerPage}
												totalItemsCount={this.state?.pagination?.total_records}
												pageRangeDisplayed={this.state?.pagination?.total_pages}
												onChange={this.handlePageChange.bind(this) }
												itemClass={"page-item"}
												linkClass={"page-link"}
											/>
											{/*<ul class="pagination">
												<li class="page-item previous disabled">
													<a href="#" class="page-link">
														<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
															viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
															stroke-linecap="round" stroke-linejoin="round"
															class="feather feather-arrow-left">
															<line x1="19" y1="12" x2="5" y2="12"></line>
															<polyline points="12 19 5 12 12 5"></polyline>
														</svg>
													</a>
												</li>
												<li class="page-item active"><a href="#" class="page-link">1</a></li>
												<li class="page-item "><a href="#" class="page-link">2</a></li>
												<li class="page-item next" id="style-3_next">
													<a href="#" class="page-link">
														<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
															viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
															stroke-linecap="round" stroke-linejoin="round"
															class="feather feather-arrow-right">
															<line x1="5" y1="12" x2="19" y2="12"></line>
															<polyline points="12 5 19 12 12 19"></polyline>
														</svg>
													</a>
												</li>
											</ul>*/}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>

				<div class="modal fade" id="countryDetail" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
					<div class="modal-dialog modal-xl" role="document">
						<div class="modal-content">
							<div class="modal-header">
								<h5 class="modal-title" id="exampleModalLabel">Country Details</h5>
								<button type="button" class="close" data-dismiss="modal" aria-label="Close">
									<svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
								</button>
							</div>
							<div class="modal-body">
								<div className="row dataOnly">
									<div className="col-12 col-md-6">
										<div className="formData">
											<label>Country ID</label>
											<div className="infoText">Kenya</div>
										</div>
									</div>
									<div className="col-12 col-md-6">
										<div className="formData">
											<label>Added Date</label>
											<div className="infoText">20 May, 2021</div>
										</div>
									</div>
									<div className="col-12 col-md-6">
										<div className="formData">
											<label>Currency</label>
											<div className="infoText">KSH</div>
										</div>
									</div>
									<div className="col-12 col-md-6">
										<div className="formData">
											<label>Rate</label>
											<div className="infoText">KE</div>
										</div>
									</div>

									<div className="col-12">
										<hr />
										<div className="">
											<div className="cardHeader">
												<h1>Shipping Charges Details</h1>
											</div>
											<div className="table-responsive customStriped">
												<table class="table">
													<thead>
														<tr>
															<th scope="col">Name</th>
															<th scope="col">Rate</th>
															<th scope="col">Of</th>
														</tr>
													</thead>
													<tbody>
														<tr>
															<td>US Sales Tax</td>
															<td>8%</td>
															<td>Product Price</td>
														</tr>
														<tr>
															<td>Processing Fee</td>
															<td>5%</td>
															<td>Product Price + US Sales Tax</td>
														</tr>
														<tr>
															<td>Shipping Cost*</td>
															<td>$90</td>
															<td>Per KG </td>
														</tr>
														<tr>
															<td>Kenya Sales Tax – VAT</td>
															<td>5%</td>
															<td>Percentage </td>
														</tr>
													</tbody>
												</table>
											</div>
										</div>
									</div>

								</div>


							</div>
							<div class="modal-footer">
								<button class="myBtn btnSm btn-danger">Delete</button>
								<button type="button" class="myBtn btn-success btnSm btn-info" data-dismiss="modal" aria-label="Close" onClick={(e) => { this.props.history.push('edit-feature') }}>Edit</button>
							</div>
						</div>
					</div>
				</div>
				<PageFooter />
			</React.Fragment>
		);
	}
}

export default CountryList;

