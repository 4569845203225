import Swal from "sweetalert2";
import moment from "moment";

function logout(){
    let language = localStorage.getItem('lang');
    localStorage.clear()
    if(language){
        localStorage.setItem('lang',language)
    }
    Swal.fire({
        title: "Logout_successful",
        text: "Redirecting_you_to_login",
        icon: 'success',
        showConfirmButton: false
    })
    setTimeout(()=>{window.location.href='/'},2000)
}
function dateTimeFormat(dateTime) {
    return moment(dateTime).format('YYYY-MM-DD hh:mm A')
}
function dateFormat(date){
    return moment(date).format('YYYY-MM-DD')
}
function dateFormatForMail(date){
    return moment(date).format('MMM DD')
}
function toLowerCase(param){
    return param.toLowerCase();
}
//======================= multi selecting ==================
const onMultiSelectHandle = async ( selectedList, selectedItem) => {

    let selectOptionsArray = []
    for (const selectedListElement of selectedList) {
        selectOptionsArray.push(selectedListElement._id)
    }
    return selectOptionsArray.join(",")
}
const onMultiRemoveHandle = async (selectedList, selectedItem) => {
    let selectOptionsArray = []
    for (const selectedListElement of selectedList) {
        selectOptionsArray.push(selectedListElement._id)
    }
    return selectOptionsArray.join(",")
}

const snakeToCamel = str =>
    str.replace(/([-_][a-z])/g, group =>
        group
            .toUpperCase()
            .replace('-', ' ')
            .replace('_', ' ')
    ).charAt(0).toUpperCase() + str.slice(1).replace('_' , ' ');
const replaceUnderScoreWithSpace = str =>
    str.replace(/_/g, ' ')
export {
    logout,
    dateTimeFormat,
    dateFormat,
    toLowerCase,
    snakeToCamel,
    onMultiSelectHandle,
    onMultiRemoveHandle,
    dateFormatForMail,
    replaceUnderScoreWithSpace
}
