import React from "react";
import ChangeTitle from "../../../../components/change_page_title";
import SideBar from "../../../../components/side-bar";
import PageHeader from "../../../../components/page-header";
import Notifications, { notify } from "react-notify-toast";
import $ from "jquery";
import BreadCrumb from "../../../../components/bread-crumb";
class AddDiscounts extends React.Component {
    breadcrumbNavigation = [
        {
          title: "Manage Discounts",
          link: "#",
        },
        {
          title: "Add Discounts",
          link: "#",
        },
      ];
      handleSubmit = async (event) => {
        try {
          $("#loader").show();
          event.preventDefault();
          let formData = new FormData(event.target);
          // formData.append("status", this.state.isStatusChecked);
          // let res = await adminApiCallForFormData("amenities/create", formData);
          let res = {status:200};
          if (!res) {
            notify.show("Something_went_wrong", "error", 2000);
            return false;
          }
          if (res.hasOwnProperty("status")) {
            if (res.status == 200) {
              notify.show(res.message, "success", 2000);
              setTimeout(() => {
                window.location.reload();
              }, 2000);
            } else if (res.status == 400) {
              notify.show(res.message, "error", 2000);
            }
          }
        } catch (e) {
          console.log(e);
          notify.show("Something_gone_wrong", "error", 2000);
        } finally {
          $("#loader").hide();
        }
      };
    
  render() {
    return (
      <>
        <ChangeTitle title="Manage Discounts" />
        <SideBar />
        <PageHeader />
        <Notifications options={{ zIndex: 10000 }} />
        <BreadCrumb navigation={this.breadcrumbNavigation} />
        <section class="pageWrapper">
          <div class="container-fluid">
            <div class="row">
              <div class="col-12">
                <div class="contentCard">
                  <div class="cardHeader clearfix">
                    <h1>Add Discount</h1>
                  </div>
                  <div class="cardBody">
                    <form onSubmit={this.handleSubmit}>
                      <div className="row formWrapper">
                        <div className="col-12 col-md-12">
                          <div className="form-group inlineField">
                            <div className="row align-items-center">
                              <div className="col-12 col-md-4 col-lg-3">
                                <label>Discount Code</label>
                              </div>
                              <div className="col-12 col-md-8 col-lg-9">
                                <input
                                  type="text"
                                  name={"discount-code"}
                                  placeholder="Please enter your name"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-md-12">
                          <div className="form-group inlineField">
                            <div className="row align-items-center">
                              <div className="col-12 col-md-4 col-lg-3">
                                <label>Email</label>
                              </div>
                              <div className="col-12 col-md-8 col-lg-9">
                                <input
                                  type="text"
                                  name={"email"}
                                  placeholder="Please enter email address"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-md-12">
                          <div className="form-group inlineField">
                            <div className="row align-items-center">
                              <div className="col-12 col-md-4 col-lg-3">
                                <label>Phone</label>
                              </div>
                              <div className="col-12 col-md-8 col-lg-9">
                                <input
                                  name="phone"
                                  type="number"
                                  placeholder="Please enter your phone number"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-12 col-md-12 text-right">
                          <button
                            type={"submit"}
                            class="myBtn"
                            onClick={this.register}
                          >
                            Add{" "}
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}
export default AddDiscounts;
