import React, { useEffect } from "react";
import Notifications, { notify } from "react-notify-toast";

function DiscountForm() {
  const [values, setValues] = React.useState({ amount: 0, _id: "", name: "" });
  const [state, setState] = React.useState("State added");

  const fech = async () => {
    const url = "https://dev-api.getspaceshuttle.com/api/admin/discount/list";
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("adminToken"),
        },
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      const res = data?.data;
      setValues({
        name: res[0]?.name,
        amount: res[0]?.amount,
        _id: res[0]?._id,
      });
      console.log(data, "i am data");
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    fech();
  }, []);
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const url =
        "https://dev-api.getspaceshuttle.com/api/admin/discount/update";
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `${localStorage.getItem("adminToken")}`, // Use Bearer token if required
        },
        body: JSON.stringify({
          _id: values?._id,
          amount: values?.amount,
        }),
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      if (data) {
        fech();
        notify.show("Discount updated");
      }
      console.log(data); // Handle the response data as needed
    } catch (err) {
      console.error("Error:", err);
    }
  };
  console.log(values, "valuesfasdfasdfsda");
  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div className="row formWrapper">
          <div className="col-12 col-md-12">
            <div className="form-group inlineField">
              <div className="row align-items-center">
                <div className="col-12 col-md-4 col-lg-3">
                  <label>Amount</label>
                </div>
                <div className="col-12 col-md-8 col-lg-9">
                  <input
                    value={values.amount}
                    type="number"
                    onChange={(e) =>
                      setValues({ ...values, amount: e.target.value })
                    }
                    name={"amount"}
                    placeholder="Please enter email address"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-12">
            <div className="form-group inlineField">
              <div className="row align-items-center">
                <div className="col-12 col-md-4 col-lg-3">
                  <label>Name</label>
                </div>
                <div className="col-12 col-md-8 col-lg-9">
                  <input
                    value={values?.name}
                    onChange={(e) =>
                      setValues({ ...values, name: e.target.value })
                    }
                    name="phone"
                    type="text"
                    placeholder="Please enter your phone number"
                    disabled
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-12 text-right">
            <button type={"submit"} class="myBtn">
              Update{" "}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}

export default DiscountForm;
