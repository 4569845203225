/**
 * @author Basit Raza <razabasit88@gmail.com>
 * @link http://be.net/basit_raza Author Website
 * @since 2021-01-18
 */
import React from 'react';
import PageHeader from '../../../../components/page-header';
import ChangeTitle from '../../../../components/change_page_title';
import PageFooter from '../../../../components/page-footer';
import SideBar from '../../../../components/side-bar';
import BreadCrumb from '../../../../components/bread-crumb';
import $ from "jquery";
import {adminApiCall, adminApiCallForFormData} from "../../../../utils/ajax_request";
import Notifications, { notify } from 'react-notify-toast';
import { Multiselect } from 'multiselect-react-dropdown';
import { storageProvider } from "../../../../config";

class AddStorageCategory extends React.Component {

	breadcrumbNavigation = [
		{
			title: 'Storage Management',
			link: '#'
		},
		{
			title: 'Add Storage Category',
			link: '#'
		}
	]
	state = {
		isStatusChecked : false,
		amenities_list: [],
		selected_amenities: ''
	}
	componentDidMount() {
		this.multiSelectlisting()
	}

	multiSelectlisting = async () => {
		try{
			let res = await adminApiCall('amenities/amenities_select_list','POST' , JSON.stringify( {type: storageProvider} ))
			if (!res) {
				notify.show("Something_went_wrong",'error',2000);
				return false;
			}
			if ( res.hasOwnProperty('status') ) {
				if ( res.status === 200 && res.data ) {
					this.setState({amenities_list: res.data})
					console.log(this.state.amenities_list)
				} else if ( res.status === 400 ) {
					this.setState({"data" : []})
					notify.show(res.message,'error',2000);
				}
			}
		}
		catch ( e ){
			notify.show("Something_gone_wrong",'error',2000);
		}
		finally {
		}
	}

	handleSubmit = async (event) => {
		try{
			$('#loader').show();
			event.preventDefault()
			let formData = new FormData(event.target);
			formData.append("amenities_ids", this.state.selected_amenities);
			formData.append("type", storageProvider);
			let res = await adminApiCallForFormData('search_filter_category/create',formData)
			if (!res) {
				notify.show("Something_went_wrong",'error',2000);
				return false;
			}
			if ( res.hasOwnProperty('status') ) {
				if ( res.status == 200 ) {
					notify.show(res.message,'success',2000);
					setTimeout(()=>{window.location.reload()},2000)
				} else if ( res.status == 400 ) {
					notify.show(res.message,'error',2000);
				}
			}
		}catch (e) {
			console.log(e);
			notify.show("Something_gone_wrong",'error',2000);
		}finally {
			$('#loader').hide();
		}

	}

	onSelectHandle = ( selectedList, selectedItem) => {

		let selectOptionsArray = []
		for (const selectedListElement of selectedList) {
			selectOptionsArray.push(selectedListElement._id)
		}
			console.log(selectOptionsArray)

		this.setState({selected_amenities: selectOptionsArray.join(",")})
	}
	onRemoveHandle = (selectedList, selectedItem) => {
		let selectOptionsArray = []
		for (const selectedListElement of selectedList) {
			selectOptionsArray.push(selectedListElement._id)
		}
		this.setState({selected_amenities: selectOptionsArray.join(",")})
	}

	onKeyPressHandle = (event) => {
		event.key === 'Enter' && event.preventDefault();
	}

	render() {
		return (
			/*Content Area*/
			<React.Fragment>

				<ChangeTitle title={'Storage categories'} />

				{/*SideBar*/}
				<SideBar />

				{/*Page Header*/}
				<PageHeader />
				<Notifications options={{zIndex: 10000}}/>
				<BreadCrumb navigation={this.breadcrumbNavigation} />

				{/* main content starts here */}

				<section class="pageWrapper">
					<div class="container-fluid">
						<div class="row">
							<div class="col-12">
								<div class="contentCard">
									<div class="cardHeader clearfix">
										<h1>Add Storage Category</h1>
									</div>
									<div class="cardBody">
										<form onSubmit={this.handleSubmit}>
											<div className="row formWrapper">
												<div className="col-12 col-md-12">
													<div className="form-group inlineField">
														<div className="row align-items-center">
															<div className="col-12 col-md-4 col-lg-3">
																<label>Name</label>
															</div>
															<div className="col-12 col-md-8 col-lg-9">
																<input type="text" name={"name"} placeholder="Please enter name."
																	   onKeyPress={this.onKeyPressHandle}
																/>
															</div>
														</div>
													</div>
												</div>
												<div className="col-12 col-md-12">
													<div className="form-group inlineField">
														<div className="row align-items-center">
															<div className="col-12 col-md-4 col-lg-3">
																<label>Minimum (Square Feet)</label>
															</div>
															<div className="col-12 col-md-8 col-lg-9">
																<input type="number" name={"minimum_square_feet"} placeholder="Please enter minimum (square feet)."
																	   onKeyPress={this.onKeyPressHandle}
																/>
															</div>
														</div>
													</div>
												</div>
												<div className="col-12 col-md-12">
													<div className="form-group inlineField">
														<div className="row align-items-center">
															<div className="col-12 col-md-4 col-lg-3">
																<label>Maximum (Square Feet)</label>
															</div>
															<div className="col-12 col-md-8 col-lg-9">
																<input type="number" name={"maximum_square_feet"} placeholder="Please enter maximum (square feet)."
																	   onKeyPress={this.onKeyPressHandle}
																/>
															</div>
														</div>
													</div>
												</div>
												<div className="col-12 col-md-12">
													<div className="form-group inlineField">
														<div className="row align-items-center">
															<div className="col-12 col-md-4 col-lg-3">
																<label>Amenities </label>
															</div>
															<div className="col-12 col-md-8 col-lg-9">
																<Multiselect
																	options={this.state.amenities_list}
																	displayValue="title"
																	onSelect={( list , item)=>this.onSelectHandle( list , item)}
																	onRemove={(list , item)=>this.onRemoveHandle(list , item)}
																>
																</Multiselect>
															</div>
														</div>
													</div>
												</div>
												<div className="col-12 col-md-12">
													<div className="form-group inlineField">
														<div className="row align-items-center">
															<div className="col-12 col-md-4 col-lg-3">
																<label>Media Files</label>
															</div>
															<div className="col-12 col-md-8 col-lg-9">
																<div className="inputFile">
																	<input name="media_file" type="file" multiple />
																</div>
															</div>
														</div>
													</div>
												</div>
												<div className="col-12 col-md-12">
													<div className="form-group inlineField">
														<div className="row align-items-center">
															<div className="col-12 col-md-4 col-lg-3">
																<label>Description</label>
															</div>
															<div className="col-12 col-md-8 col-lg-9">
																<textarea name="description" rows="5" onKeyPress={this.onKeyPressHandle}/>
															</div>
														</div>
													</div>
												</div>
												<div className="col-12 col-md-12">
													<div className="form-group inlineField">
														<div className="row align-items-center">
															<div className="col-12 col-md-4 col-lg-3">
																<label>Status</label>
															</div>
															<div className="col-12 col-md-8 col-lg-9">
																<div className="toggleBtn" >
																	<input type="checkbox" id="togglePrice" checked={"checked"} name={"status"} onChange={(e)=> {this.setState({isStatusChecked: e.target.checked})} } />
																		<label htmlFor="togglePrice">
																			<span className="on">ON</span>
																			<span className="off">OFF</span>
																		</label>
																</div>
															</div>
														</div>
													</div>
												</div>
												<div class="col-12 col-md-12 text-right">
													<button type={"submit"} class="myBtn" onClick={this.register}>Register </button>
												</div>
											</div>
										</form>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>

				<PageFooter />
			</React.Fragment>
		);
	}
}

export default AddStorageCategory;

