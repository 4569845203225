import React from "react";
import PageHeader from "../../../components/page-header";
import ChangeTitle from "../../../components/change_page_title";
import PageFooter from "../../../components/page-footer";
import SideBar from "../../../components/side-bar";
import BreadCrumb from "../../../components/bread-crumb";
import Notifications, { notify } from "react-notify-toast";
import $ from "jquery";
import { adminApiCall, adminRegister } from "../../../utils/ajax_request";
import { snakeToCamel, dateTimeFormat } from "../../../utils/helpers";
import Pagination from "react-js-pagination";
import "bootstrap-daterangepicker/daterangepicker.css";
import Swal from "sweetalert2";
const password = require("secure-random-password");
class StoreProviderManagementList extends React.Component {
  breadcrumbNavigation = [
    {
      title: "Manage Admin",
      link: "#",
    },
    {
      title: "Admins Listing",
      link: "#",
    },
  ];
  state = {
    list: [],
    isVerified: false,
    newPassword: "",
    errorMsg: "",
    email: "",
    filterValues: {
      user_name: "",
      role: "",
    },
    pagination: {
      current_page: 1,
      total_pages: "",
      total_records: "",
      itemsCountPerPage: 24,
    },
  };
  componentDidMount() {
    this.listing();
  }
  listing = async (page) => {
    try {
      $("#loader").show();
      let data = {
        current_page: page ? page : this.state?.pagination?.current_page,
        ...(this.state?.filterValues?.user_name && {
          user_name: this.state?.filterValues?.user_name,
        }),
        ...(this.state?.filterValues?.role && {
          role: this.state?.filterValues?.role,
        }),
      };
      let res = await adminApiCall("list", "POST", JSON.stringify(data));
      if (!res) {
        notify.show("Something_went_wrong", "error", 2000);
        return false;
      }
      if (res.hasOwnProperty("status")) {
        if (res.status === 200 && res.data) {
          this.setState({
            list: res.data,
            pagination: {
              ...this.state.pagination,
              current_page: res.current_page,
              total_pages: res.total_pages,
              total_records: res.total_records,
            },
          });
        } else if (res.status === 400) {
          this.setState({ list: [] });
          notify.show(res.message, "error", 2000);
        } else {
          this.setState({ list: [] });
        }
      }
    } catch (e) {
      notify.show("Something_gone_wrong", "error", 2000);
    } finally {
      $("#loader").hide();
    }
  };

  changeStatus = async (e, email) => {
    let confirm = window.confirm(
      `Are you sure you want to ${
        e.target.checked === true ? `verified` : `Unverified`
      } this admin?`
    );
    if (confirm) {
      if (email) {
        try {
          $("#loader").show();
          let res = await adminApiCall(
            "verify_account",
            "POST",
            JSON.stringify({ email: email, status: e.target.checked })
          );
          if (!res) {
            notify.show("Something_went_wrong", "error", 2000);
            return false;
          }
          if (res.hasOwnProperty("status")) {
            if (res.status === 200 && res.data) {
              notify.show(res.message, "success", 2000);
              setTimeout(() => {
                this.listing();
              }, 2000);
              this.renderListing();
            } else if (res.status === 400) {
              notify.show(res.message, "error", 2000);
            }
          }
        } catch (e) {
          notify.show("Something_gone_wrong", "error", 2000);
        } finally {
          $("#loader").hide();
        }
      } else {
        notify.show("Please choose admin to verify", "error", 2000);
      }
    }
  };

  renderListing = () => {
    let listing = [];
    if (this.state.list && this.state.list.length > 0) {
      try {
        for (const [index, element] of this.state.list.entries()) {
          listing.push(
            <>
              <tr>
                <td>{index + 1}</td>
                <td>{element?.first_name + " " + element?.last_name}</td>
                <td>{element?.email}</td>
                <td>{element?.phone}</td>
                <td>
                  {element?.created_at && dateTimeFormat(element?.created_at)}
                </td>
                <td>
                  {" "}
                  <span className={"badge badge-pill badge-info"}>
                    {" "}
                    {(element?.role && snakeToCamel(element?.role)) ||
                      "admin"}{" "}
                  </span>{" "}
                </td>
                <td>
                  <div className="toggleBtn">
                    <input
                      type="checkbox"
                      id={`togglePrice${index}`}
                      checked={element?.status === "VERIFIED" ? "checked" : ""}
                      onChange={(e) => this.changeStatus(e, element?.email)}
                      name={"status"}
                    />
                    <label htmlFor={`togglePrice${index}`}>
                      <span className="on">YES</span>
                      <span className="off">NO</span>
                    </label>
                  </div>
                </td>
                <td>
                  <button
                    className="myBtn btn-danger btnSm"
                    onClick={() => this.hitFire(element?._id)}
                  >
                    <i class="fa fa-trash"></i>
                  </button>
                  <button
                    class="myBtn btn-success btnSm mx-1"
                    onClick={() =>
                      this.props.history.push("edit-admin?id=" + element?._id)
                    }
                  >
                    <i class="fa fa-edit"></i>
                  </button>
                  <button class="myBtn btn-info btnSm">
                    <i class=" fa fa-eye"></i>
                  </button>
                  <button
                    className="myBtn btn-danger btnSm ml-1"
                    data-toggle="modal"
                    data-target="#changeInfo"
                    onClick={() => this.setState({ email: element?.email })}
                  >
                    <i className=" fa fa-key"></i>
                  </button>
                </td>
              </tr>
            </>
          );
        }
      } catch (e) {
        notify.show("Something_gone_wrong", "error", 2000);
      }
      return listing;
    } else {
      listing.push(
        <tr>
          <td colSpan={10}>No Record found</td>
        </tr>
      );
      return listing;
    }
  };

  // notify toaste start here
  notify() {
    notify.show("res.message", "error", 2000);
  }

  hitFire = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let res = await adminApiCall(
          "delete",
          "POST",
          JSON.stringify({ admin_id: id })
        );
        if (!res) {
          Swal.fire(
            "Error!",
            "Something went wrong Please try again.",
            "error"
          );
          return false;
        }
        if (res.hasOwnProperty("status")) {
          if (res.status === 200) {
            Swal.fire("Deleted!!", "Record has been deleted.", "success");
            this.listing();
          } else if (res.status === 400) {
            notify.show(res.message, "error", 1500);
          }
        }
      }
    });
  };
  hitBann() {
    Swal.fire({
      title: "Are you sure?",
      text: "You Want to bann this User",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes!",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire("Banned!", "This user is banned now.", "success");
      }
    });
  }

  handleGeneratePassword = async () => {
    const newPassword = password.randomPassword({
      characters:
        password.lower + password.upper + password.digits + password.symbols,
    });
    this.setState({ newPassword: newPassword, errorMsg: "" });
  };

  handleChangePassword = async () => {
    if (this.state.newPassword) {
      this.setState({ errorMsg: "" });
      try {
        $("#loader").show();
        let res = await adminRegister(
          "auth/admin/reset_admin_password",
          JSON.stringify({
            email: this.state?.email,
            new_password: this.state.newPassword,
          })
        );
        if (!res) {
          notify.show("Something_went_wrong", "error", 2000);
          return false;
        }
        if (res.hasOwnProperty("status")) {
          if (res.status === 200) {
            notify.show(res.message, "success", 2000);
            setTimeout(() => {
              window.location.reload();
            }, 2000);
          } else if (res.status === 400) {
            notify.show(res.message, "error", 2000);
          }
        }
      } catch (e) {
        notify.show("Something_gone_wrong", "error", 2000);
      } finally {
        $("#loader").hide();
      }
    } else {
      this.setState({ errorMsg: "Please generate new password" });
    }
  };
  searchHandle = () => {
    this.listing();
  };

  handlePageChange(pageNumber) {
    this.setState({
      pagination: { ...this.state.pagination, current_page: pageNumber },
    });
    this.listing(pageNumber);
  }

  render() {
    return (
      /*Content Area*/
      <React.Fragment>
        <ChangeTitle title={"Manage User"} />
        {/*SideBar*/}
        <SideBar />
        {/*Page Header*/}
        <PageHeader />
        <Notifications options={{ zIndex: 10000 }} />
        <BreadCrumb navigation={this.breadcrumbNavigation} />
        {/* main content starts here */}
        <section class="pageWrapper">
          <div class="container-fluid">
            <div class="row">
              <div class="col-12">
                <div class="contentCard">
                  <div class="cardHeader clearfix">
                    <svg
                      className="mr-1"
                      enable-background="new 0 0 20 20"
                      height="20"
                      viewBox="0 0 512 512"
                      width="20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g>
                        <path d="m90.5 0h-30v362h-30v90h30v60h30v-60h30v-90h-30zm0 422h-30v-30h30z" />
                        <path d="m481.5 362h-30v-362h-30v362h-30v90h30v60h30v-60h30zm-30 60h-30v-30h30z" />
                        <path d="m331.5 0h-30v222h-30v90h30v200h30v-200h30v-90h-30zm0 282h-30v-30h30z" />
                        <path d="m210.5 0h-30v53.652h-30v90h30v368.348h30v-368.348h30v-90h-30zm0 113.652h-30v-30h30z" />
                      </g>
                    </svg>
                    <h1>Filters</h1>
                    <span class="toogleCard open">
                      <i class="fa fa-angle-down"></i>
                    </span>
                  </div>

                  <div class="cardBody">
                    <div class="row formWrapper">
                      <div class="col-12 col-md-12">
                        <div class="form-group inlineField">
                          <div class="row align-items-center">
                            <div class="col-12 col-md-4 col-lg-3">
                              <label>User Name:</label>
                            </div>
                            <div class="col-12 col-md-8 col-lg-9">
                              <input
                                type="text"
                                value={this.state?.filterValues?.user_name}
                                onChange={(e) =>
                                  this.setState({
                                    filterValues: {
                                      ...this.state?.filterValues,
                                      user_name: e.target.value,
                                    },
                                  })
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-12 col-md-12 text-right">
                        <button
                          className="myBtn"
                          onClick={() =>
                            this.setState(
                              {
                                filterValues: {
                                  ...this.state?.filterValues,
                                  user_name: "",
                                },
                              },
                              () => {
                                this.searchHandle();
                              }
                            )
                          }
                        >
                          Reset <i className="fa fa-refresh"></i>
                        </button>
                        <button
                          className="ml-3 myBtn"
                          onClick={this.searchHandle}
                        >
                          Search <i className="fa fa-search"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12">
                <div class="contentCard">
                  <div class="cardHeader">
                    <h1>All Users List</h1>
                    <button
                      className="myBtn mt-2"
                      onClick={() => this.props.history.push("add-admin")}
                    >
                      + Create Admin
                    </button>
                  </div>
                  <div class="cardBody">
                    <div class="table-responsive customStriped">
                      <table class="table">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>User Name</th>
                            <th>Email</th>
                            <th>Phone No</th>
                            <th>Create Date</th>
                            <th>Role</th>
                            {/*<th>Status</th>*/}
                            <th>Verified</th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>{this.renderListing()}</tbody>
                      </table>
                    </div>
                    <div class="paginationWrapper clearfix">
                      <div className="paginationWrapper clearfix">
                        <Pagination
                          activePage={this.state?.pagination?.current_page}
                          itemsCountPerPage={
                            this.state?.pagination?.itemsCountPerPage
                          }
                          totalItemsCount={
                            this.state?.pagination?.total_records
                          }
                          pageRangeDisplayed={
                            this.state?.pagination?.total_pages
                          }
                          onChange={this.handlePageChange.bind(this)}
                          itemClass={"page-item"}
                          linkClass={"page-link"}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div
          class="modal fade"
          id="changeInfo"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-xl" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">
                  Reset Password
                </h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <svg
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-x"
                  >
                    <line x1="18" y1="6" x2="6" y2="18"></line>
                    <line x1="6" y1="6" x2="18" y2="18"></line>
                  </svg>
                </button>
              </div>
              <div class="modal-body">
                <div className="row formWrapper">
                  <div className="col-12 col-md-6">
                    <div class="form-group">
                      <label>New Password:</label>
                      <input
                        type="text"
                        id={"new_password"}
                        value={this.state.newPassword}
                      />
                    </div>
                  </div>
                  <div className="col-12 col-md-6 generate-password-btn">
                    <button
                      className="myBtn btn-danger"
                      onClick={this.handleGeneratePassword}
                    >
                      {" "}
                      Generate Password
                    </button>
                  </div>
                </div>
                <div className={"row formWrapper"}>
                  <div className="col-12 col-md-6">
                    <span className={"error_msg"}>{this.state?.errorMsg}</span>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <div className="tableDetails text-center">
                  <button
                    class="myBtn btnSm btn-simple"
                    data-dismiss="modal"
                    onClick={() => this.setState({ newPassword: "" })}
                  >
                    {" "}
                    Cancel
                  </button>
                  <button
                    class="myBtn btnSm btn-success ml-2"
                    onClick={this.handleChangePassword}
                  >
                    Reset
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <PageFooter />
      </React.Fragment>
    );
  }
}

export default StoreProviderManagementList;
