import React from 'react';
import ChangeTitle from '../../../../components/change_page_title';
import SideBar from '../../../../components/side-bar';
import PageHeader from '../../../../components/page-header';
import Notifications, { notify } from 'react-notify-toast';
import BreadCrumb from '../../../../components/bread-crumb';
import Swal from 'sweetalert2';
class EditDiscounts extends React.Component { 
    state = {
        recordDetails: "",
        isIconExist: false,
      };
    breadcrumbNavigation = [
        {
          title: "Manage Discounts",
          link: "#",
        },
        {
          title: "Edit Discounts",
          link: "#",
        },
      ];

     listing = [
        {
          id:1,
          code: "John Doe",
          email: "john.doe@example.com",
          phone: "123-456-7890",
          
        },
        {
          id:2,
          code: "Jane Smith",
          email: "jane.smith@example.com",
          phone: "987-654-3210",
        },
        {
          id:3,
          code: "Mike Johnson",
          email: "mike.johnson@example.com",
          phone: "555-123-4567",
        },
        {
          id:4,
          code: "Emily Davis",
          email: "emily.davis@example.com",
          phone: "444-789-0123",
        },
      ];
      componentDidMount() {
        const urlParams = new URLSearchParams(window.location.search);
        const id = urlParams.get("id");
        console.log(id, "fadsfasdfasfdasId");
        if (!id) {
          Swal.fire({
            title: "Error",
            text: "Invalid_access_error",
            icon: "error",
          });
        } else {
          this.getRecordDetail(id);
        }
      }
      getRecordDetail = (id) => {
        const obj = this.listing.find((element) => element.id === Number(id));
        this.setState({
          recordDetails: obj,
          isIconExist: false,
        });
      };
    render() {
        return (
            <>
            <ChangeTitle title="Manage Discounts" />
            <SideBar />
            <PageHeader />
            <Notifications options={{ zIndex: 10000 }} />
            <BreadCrumb navigation={this.breadcrumbNavigation} />
            <section class="pageWrapper">
              <div class="container-fluid">
                <div class="row">
                  <div class="col-12">
                    <div class="contentCard">
                      <div class="cardHeader clearfix">
                        <h1>Edit Discount</h1>
                      </div>
                      <div class="cardBody">
                        <form onSubmit={this.handleSubmit}>
                          <div className="row formWrapper">
                            <div className="col-12 col-md-12">
                              <div className="form-group inlineField">
                                <div className="row align-items-center">
                                  <div className="col-12 col-md-4 col-lg-3">
                                    <label>Discount Code</label>
                                  </div>
                                  <div className="col-12 col-md-8 col-lg-9">
                                    <input
                                    value = {this.state.recordDetails?.code}
                                    onChange={(e)=> 
                                        this.setState({
                                        recordDetails:{
                                            ...this.state.recordDetails,
                                            code:e.target.value,
                                        }
                                        })
                                    }
                                    
                                      type="text"
                                      name={"discount code"}
                                      placeholder="Please enter your name"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-12 col-md-12">
                              <div className="form-group inlineField">
                                <div className="row align-items-center">
                                  <div className="col-12 col-md-4 col-lg-3">
                                    <label>Email</label>
                                  </div>
                                  <div className="col-12 col-md-8 col-lg-9">
                                    <input
                                    value = {this.state.recordDetails?.email}
                                    onChange={(e)=> 
                                        this.setState({
                                        recordDetails:{
                                            ...this.state.recordDetails,
                                            email:e.target.value,
                                        }
                                        })
                                    }
                                    
                                      type="text"
                                      name={"email"}
                                      placeholder="Please enter email address"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-12 col-md-12">
                              <div className="form-group inlineField">
                                <div className="row align-items-center">
                                  <div className="col-12 col-md-4 col-lg-3">
                                    <label>Phone</label>
                                  </div>
                                  <div className="col-12 col-md-8 col-lg-9">
                                    <input
                                    value = {this.state.recordDetails?.phone}
                                    onChange={(e)=> 
                                        this.setState({
                                        recordDetails:{
                                            ...this.state.recordDetails,
                                            phone:e.target.value,
                                        }
                                        })
                                    }
                                    
                                      name="phone"
                                      type="text"
                                      placeholder="Please enter your phone number"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-12 col-md-12 text-right">
                              <button
                                type={"submit"}
                                class="myBtn"
                                // onClick={this.register}
                              >
                                Add{" "}
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </>   
        )
    }

}
export default EditDiscounts;