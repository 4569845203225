import React from 'react';
import PageHeader from '../../../components/page-header';
import ChangeTitle from '../../../components/change_page_title';
import PageFooter from '../../../components/page-footer';
import SideBar from '../../../components/side-bar';
import BreadCrumb from '../../../components/bread-crumb';
// date time picker 
import DateRangePicker from 'react-bootstrap-daterangepicker';
// you will also need the css that comes with bootstrap-daterangepicker
import 'bootstrap-daterangepicker/daterangepicker.css';

class AddAddress extends React.Component {

	breadcrumbNavigation = [
		{
			title: 'Address Book',
			link: '#'
		},
		{
			title: 'Add Address',
			link: '#'
		}
	]


	handleEvent(event, picker) {
		console.log(picker.startDate.toDate());
		console.log(picker.endDate.toDate());
	}

	render() {
		return (
			/*Content Area*/
			<React.Fragment>

				<ChangeTitle title={'Add Address'} />

				{/*SideBar*/}
				<SideBar />

				{/*Page Header*/}
				<PageHeader />

				<BreadCrumb navigation={this.breadcrumbNavigation} />

				{/* main content starts here */}
				<section class="pageWrapper">
					<div class="container-fluid">
						<div class="row">
							<div class="col-12">
								<div class="contentCard">
									<div class="cardHeader">
										<h1>Add New Wearhouse Address</h1>
									</div>
									<div class="cardBody formWrapper">
										<form action="">
											<div className="row">
												<div className="col-12 col-md-4">
													<label className="my-1">Address Title</label>
													<input type="text" ></input>
												</div>
												<div className="col-12 col-md-4">
													<label className="my-1">Country</label>
													<select>
														<option value="">USA</option>
													</select>
												</div>
												<div className="col-12 col-md-4">
													<label className="my-1">City</label>
													<input type="text" ></input>
												</div>
												<div className="col-12 col-md-4">
													<label className="my-1">Street Address</label>
													<input type="text" ></input>
												</div>
												<div className="col-12 col-md-4">
													<label className="my-1">Phone</label>
													<input type="text" ></input>
												</div>
											</div>
											<div class="text-right">
												<button class="myBtn mt-2">Add Address</button>
											</div>
										</form>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
				<PageFooter />
			</React.Fragment>
		);
	}
}

export default AddAddress;

