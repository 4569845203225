/**
 * @author Basit Raza <razabasit88@gmail.com>
 * @link http://be.net/basit_raza Author Website
 * @since 2021-01-18
 */
import React from "react";
import PageHeader from "../../../../components/page-header";
import ChangeTitle from "../../../../components/change_page_title";
import PageFooter from "../../../../components/page-footer";
import SideBar from "../../../../components/side-bar";
import BreadCrumb from "../../../../components/bread-crumb";
import $ from "jquery";
import {
  adminApiCall,
  adminApiCallForFormData,
} from "../../../../utils/ajax_request";
import Notifications, { notify } from "react-notify-toast";
import Swal from "sweetalert2";
import ReactFancyBox from "react-fancybox";
import "react-fancybox/lib/fancybox.css";
import { baseDomain } from "../../../../config";

class EditEmailTemplate extends React.Component {
  breadcrumbNavigation = [
    {
      title: "Email Management",
      link: "#",
    },
    {
      title: "Edit Email Template",
      link: "#",
    },
  ];
  state = {
    recordDetail: "",
  };
  componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search);
    const id = urlParams.get("id");
    if (!id) {
      Swal.fire({
        title: "Error",
        text: "Invalid_access_error",
        icon: "error",
      });
    } else {
      this.getRecordDetail(id);
    }
  }

  getRecordDetail = async (id) => {
    try {
      $("#loader").show();
      let res = await adminApiCall(
        "email/email_template/get",
        "POST",
        JSON.stringify({ id: id })
      );
      if (!res) {
        Swal.fire({
          title: "Error",
          text: "Something_went_wrong",
          icon: "error",
        });
        return false;
      }
      if (res.hasOwnProperty("status")) {
        if (res?.status === 200 && res?.data) {
          this.setState({ recordDetail: res?.data });
        } else if (res?.status === 400) {
          Swal.fire({
            title: "Error",
            text: res?.message,
            icon: "error",
          });
        }
      }
    } catch (e) {
      Swal.fire({
        title: "Error",
        text: "Something_gone_wrong",
        icon: "error",
      });
    } finally {
      $("#loader").hide();
    }
  };
  handleSubmit = async (event) => {
    try {
      $("#loader").show();
      event.preventDefault();
      let data = {
        id: this.state?.recordDetail?._id,
        template_name: this.state?.recordDetail?.template_name,
        description: this.state?.recordDetail?.description,
      };
      let res = await adminApiCall(
        "email/email_template/update",
        "POST",
        JSON.stringify(data)
      );
      if (!res) {
        notify.show("Something_went_wrong", "error", 2000);
        return false;
      }
      if (res.hasOwnProperty("status")) {
        if (res.status == 200) {
          notify.show(res.message, "success", 2000);
        } else if (res.status == 400) {
          notify.show(res.message, "error", 2000);
        }
      }
    } catch (e) {
      console.log(e);
      notify.show("Something_gone_wrong", "error", 2000);
    } finally {
      $("#loader").hide();
    }
  };

  render() {
    return (
      /*Content Area*/
      <React.Fragment>
        <ChangeTitle title={"Truck type"} />

        {/*SideBar*/}
        <SideBar />

        {/*Page Header*/}
        <PageHeader />
        <Notifications options={{ zIndex: 10000 }} />
        <BreadCrumb navigation={this.breadcrumbNavigation} />

        {/* main content starts here */}

        <section class="pageWrapper">
          <div class="container-fluid">
            <div class="row">
              <div class="col-12">
                <div class="contentCard">
                  <div class="cardHeader clearfix">
                    <h1>Edit Truck Type</h1>
                  </div>
                  <div class="cardBody">
                    <form onSubmit={this.handleSubmit}>
                      <div className="row formWrapper">
                        <div className="col-12 col-md-12">
                          <div className="form-group inlineField">
                            <div className="row align-items-center">
                              <div className="col-12 col-md-4 col-lg-3">
                                <label>Title</label>
                              </div>
                              <div className="col-12 col-md-8 col-lg-9">
                                <input
                                  type="text"
                                  name={"template_name"}
                                  value={
                                    this.state?.recordDetail?.template_name
                                  }
                                  onChange={(e) =>
                                    this.setState({
                                      recordDetail: {
                                        ...this.state.recordDetail,
                                        template_name: e.target.value,
                                      },
                                    })
                                  }
                                  placeholder="Please enter a feature title."
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-md-12">
                          <div className="form-group inlineField">
                            <div className="row align-items-center">
                              <div className="col-12 col-md-4 col-lg-3">
                                <label>Description</label>
                              </div>
                              <div className="col-12 col-md-8 col-lg-9">
                                <textarea
                                  name="description"
                                  rows="5"
                                  value={this.state?.recordDetail?.description}
                                  onChange={(e) =>
                                    this.setState({
                                      recordDetail: {
                                        ...this.state.recordDetail,
                                        description: e.target.value,
                                      },
                                    })
                                  }
                                ></textarea>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="col-12 col-md-12 text-right">
                          <button
                            type={"submit"}
                            class="myBtn"
                            onClick={this.register}
                          >
                            Update{" "}
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <PageFooter />
      </React.Fragment>
    );
  }
}

export default EditEmailTemplate;
