import React from "react";
import ChangeTitle from "../../../components/change_page_title";
import SideBar from "../../../components/side-bar";
import PageHeader from "../../../components/page-header";
import Notifications, { notify } from "react-notify-toast";
import BreadCrumb from "../../../components/bread-crumb";
import Pagination from "react-js-pagination";
import Swal from "sweetalert2";
import DiscountForm from "../../../components/DiscountForm/DiscountForm";
class Discounts extends React.Component {
  // const [state,setState] = useState('')
  // const [state,setState] = useState('')
  // const [state,setState] = useState('')
  // const [state,setState] = useState('')
  // const [state,setState] = useState('')
  // const [state,setState] = useState('')
  // const [state,setState] = useState('')
  state = {
    recordDetails: "",
    isIconExist: false,
  };

  breadcrumbNavigation = [
    {
      title: "Manage User",
      link: "#",
    },
    {
      title: "Discounts",
      link: "#",
    },
  ];
  state = {
    payDate: null,
    transactionId: "",
    referId: "",
    list: [],
    changePassword: {
      newPassword: "",
      errorMsg: "",
      email: "",
      role: "",
      user_id: "",
    },
    filterValues: {
      user_name: "",
    },
    pagination: {
      current_page: 1,
      total_pages: "",
      total_records: "",
      itemsCountPerPage: 24,
    },
  };
  renderListing = () => {
    let listing = [
      {
        id: 1,
        code: "John Doe",
        email: "john.doe@example.com",
        phone: "123-456-7890",
      },
      {
        id: 2,
        code: "Jane Smith",
        email: "jane.smith@example.com",
        phone: "987-654-3210",
      },
      {
        id: 3,
        code: "Mike Johnson",
        email: "mike.johnson@example.com",
        phone: "555-123-4567",
      },
      {
        id: 4,
        code: "Emily Davis",
        email: "emily.davis@example.com",
        phone: "444-789-0123",
      },
    ];
    return listing?.map((element, idx) => {
      return (
        <>
          <tr>
            <td>{idx + 1}</td>
            <td>{element?.code}</td>
            <td>{element?.email}</td>
            <td>{element?.phone}</td>
            <td>
              <button className="myBtn btn-danger btnSm"
                onClick={() => this.hitFire(element?.id)}>
                <i class="fa fa-trash"></i>
              </button>
              <button class="myBtn btn-success btnSm mx-1"
                onClick={() => this.props.history.push("edit-discounts?id=" + element?.id)}>
                <i class="fa fa-edit"></i>
              </button>
            </td>
          </tr>
        </>
      );
    });
  };
  // componentDidMount() {
  //   this.listing();
  // }
  delayedAction() {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve({ status: 200 });
      }, 1000);
    });
  }
  // commit for testing yar aik aur kaam mat kar aisa jo karney ka na ho
  // commitfortesting
  hitFire(id = "") {
    console.log(this.state.pagination);
    if (id) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          // let res = await adminApiCall('amenities/delete','POST',JSON.stringify({amenity_id:id}))
          let res = await this.delayedAction();
          if (!res) {
            Swal.fire(
              "Error!",
              "Something went wrong Please try again.",
              "error"
            );
            return false;
          }
          if (res.hasOwnProperty("status")) {
            if (res.status === 200) {
              Swal.fire("Deleted!!", "Record has been deleted.", "success");
              // this.listing()
            } else if (res.status === 400) {
              notify.show(res.message, "error", 1500);
            }
          }
        }
      });
    } else {
      notify.show("No record selected", "error", 2000);
    }
  }
  render() {
    return (
      <React.Fragment>
        <ChangeTitle title="Manage User" />
        <SideBar />
        <PageHeader />
        <Notifications options={{ zIndex: 10000 }} />
        <BreadCrumb navigation={this.breadcrumbNavigation} />
        <section class="pageWrapper">
          <div class="container-fluid">
            <div class="row">
              <div class="col-12">
                <div class="contentCard">
                  {/* <div class="cardHeader clearfix">
                    <h1>All Referrals List</h1>
                    <button
                      type={"submit"}
                      className="myBtn"
                      onClick={() => this.props.history.push("add-discounts")}
                    >
                      + Create leads{" "}
                    </button>
                  </div> */}
                  <div class="cardBody">
                   <DiscountForm/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default Discounts;
