import React from "react";
import ChangeTitle from "../../../../components/change_page_title";
import SideBar from "../../../../components/side-bar";
import PageHeader from "../../../../components/page-header";
import Notifications, { notify } from "react-notify-toast";
import BreadCrumb from "../../../../components/bread-crumb";
class AddLeads extends React.Component {
    breadcrumbNavigation = [
        {
          title: "Manage Leads",
          link: "#",
        },
        {
          title: "Add Leads",
          link: "#",
    },
      ];
  render() {
    return (
      <>
        <ChangeTitle title="Manage User" />
        <SideBar />
        <PageHeader />
        <Notifications options={{ zIndex: 10000 }} />
        <BreadCrumb navigation={this.breadcrumbNavigation} />
        <section class="pageWrapper">
          <div class="container-fluid">
            <div class="row">
              <div class="col-12">
                <div class="contentCard">
                  <div class="cardHeader clearfix">
                    <h1>Add Leads</h1>
                  </div>
                  <div class="cardBody">
                    <form onSubmit={this.handleSubmit}>
                      <div className="row formWrapper">
                        <div className="col-12 col-md-12">
                          <div className="form-group inlineField">
                            <div className="row align-items-center">
                              <div className="col-12 col-md-4 col-lg-3">
                                <label>User</label>
                              </div>
                              <div className="col-12 col-md-8 col-lg-9">
                                <input
                                  type="text"
                                  name={"user"}
                                  placeholder="Please enter your name"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-md-12">
                          <div className="form-group inlineField">
                            <div className="row align-items-center">
                              <div className="col-12 col-md-4 col-lg-3">
                                <label>Provider</label>
                              </div>
                              <div className="col-12 col-md-8 col-lg-9">
                                <input
                                  type="text"
                                  name={"provider"}
                                  placeholder="Please enter email address"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-md-12">
                          <div className="form-group inlineField">
                            <div className="row align-items-center">
                              <div className="col-12 col-md-4 col-lg-3">
                                <label>Details</label>
                              </div>
                              <div className="col-12 col-md-8 col-lg-9">
                                <input
                                  name="Details"
                                  type="number"
                                  placeholder="Please enter your phone number"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-md-12">
                          <div className="form-group inlineField">
                            <div className="row align-items-center">
                              <div className="col-12 col-md-4 col-lg-3">
                                <label>Truck</label>
                              </div>
                              <div className="col-12 col-md-8 col-lg-9">
                                <input
                                  name="truck"
                                  type="text"
                                  placeholder="Please enter provider"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-md-12">
                          <div className="form-group inlineField">
                            <div className="row align-items-center">
                              <div className="col-12 col-md-4 col-lg-3">
                                <label>Url</label>
                              </div>
                              <div className="col-12 col-md-8 col-lg-9">
                                <input
                                  name="truck"
                                  type="text"
                                  placeholder="Please enter provider"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        
                        <div class="col-12 col-md-12 text-right">
                          <button
                            type={"submit"}
                            class="myBtn"
                            // onClick={this.register}
                          >
                            Add{" "}
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default AddLeads;
