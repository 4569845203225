import React from 'react';
import PageHeader from '../../../components/page-header';
import ChangeTitle from '../../../components/change_page_title';
import PageFooter from '../../../components/page-footer';
import SideBar from '../../../components/side-bar';
import BreadCrumb from '../../../components/bread-crumb';
import Notifications, {notify} from "react-notify-toast";
// date time picker
import DateRangePicker from 'react-bootstrap-daterangepicker';
// you will also need the css that comes with bootstrap-daterangepicker
import 'bootstrap-daterangepicker/daterangepicker.css';
import Swal from "sweetalert2";
import $ from "jquery";
import {adminApiCall, adminApiCallForFormData} from "../../../utils/ajax_request";

class AccouhntSetting extends React.Component {

	breadcrumbNavigation = [
		{
			title: 'Account Setting',
			link: '#'
		},
		{
			title: 'Update profile',
			link: '#'
		}
	]
	state = {
		recordDetail: '',
		isIconExist : false,
		isCurrPassChecked: false,
		isNewPassChecked: false,
		isConfirmPassChecked: false,
		password: {
			currentPassword: '',
			newPassword: '',
			confirmPassword: ''
		},
		isPassMatch: false,
		errorMsg: ""
	}
	componentDidMount () {
			this.getRecordDetail()
	}
	getRecordDetail = async () => {
		try{
			$('#loader').show();
			let res = await adminApiCall('profile')
			if (!res) {
				Swal.fire({
					title: "Error",
					text: "Something_went_wrong",
					icon: 'error',
				})
				return false;
			}
			if ( res.hasOwnProperty('status') ) {
				console.log(res);
				if ( res?.status === 200 && res?.data ) {
					this.setState({recordDetail: res?.data });
				} else if ( res?.status === 400 ) {
					Swal.fire({
						title: "Error",
						text: res?.message,
						icon: 'error',
					})
				}
			}
		}
		catch ( e ){
			Swal.fire({
				title: "Error",
				text: "Something_gone_wrong",
				icon: 'error',
			})
		}
		finally {
			$('#loader').hide();
		}
	}
	handleSubmit = async (event) => {
		try{
			$('#loader').show();
			event.preventDefault()
			let jsonData = {
				admin_id: this.state?.recordDetail?._id,
				...this.state?.recordDetail?.first_name && {first_name: this.state?.recordDetail?.first_name},
				...this.state?.recordDetail?.last_name && {last_name: this.state?.recordDetail?.last_name},
				...this.state?.recordDetail?.email && {email: this.state?.recordDetail?.email},
				...this.state?.recordDetail?.phone && {phone: this.state?.recordDetail?.phone},
			}
			let res = await adminApiCall('update_profile',"POST",JSON.stringify(jsonData))
			if (!res) {
				notify.show("Something_went_wrong",'error',2000);
				return false;
			}
			if ( res.hasOwnProperty('status') ) {
				if ( res.status == 200 ) {
					notify.show(res.message,'success',2000);
					setTimeout(()=>{window.location.reload()},2000)
				} else if ( res.status == 400 ) {
					notify.show(res.message,'error',2000);
				}else{
					notify.show(res.message,'error',2000);
				}
			}
		}catch (e) {
			console.log(e);
			notify.show("Something_gone_wrong",'error',2000);
		}finally {
			$('#loader').hide();
		}

	}
	handleChangePassword = async (event) => {
		event.preventDefault()
		if(!this.state?.password?.currentPassword | !this.state?.password?.newPassword | !this.state?.password?.confirmPassword ){
			this.setState({errorMsg: "please enter all required fields"})
			return
		}
		if (this.state.isPassMatch){
			this.setState({errorMsg: "Password is not matching"})
			return
		}
		try{
			$('#loader').show();
			event.preventDefault()
			let jsonData = {
				...this.state?.password?.currentPassword && {current_password: this.state?.password?.currentPassword},
				...this.state?.password?.newPassword && {new_password: this.state?.password?.newPassword},
			}
			let res = await adminApiCall('change_password',"POST",JSON.stringify(jsonData))
			if (!res) {
				notify.show("Something_went_wrong",'error',2000);
				return false;
			}
			if ( res.hasOwnProperty('status') ) {
				if ( res.status == 200 ) {
					notify.show(res.message,'success',2000);
					setTimeout(()=>{window.location.reload()},2000)
				} else if ( res.status == 400 ) {
					notify.show(res.message,'error',2000);
				}else{
					notify.show(res.message,'error',2000);
				}
			}
		}catch (e) {
			console.log(e);
			notify.show("Something_gone_wrong",'error',2000);
		}finally {
			$('#loader').hide();
		}
	}
	render() {
		return (
			/*Content Area*/
			<React.Fragment>

				<ChangeTitle title={'Account Setting'} />

				{/*SideBar*/}
				<SideBar />

				{/*Page Header*/}
				<PageHeader />
				<Notifications options={{zIndex: 10000}}/>
				<BreadCrumb navigation={this.breadcrumbNavigation} />

				{/* main content starts here */}
				<section class="pageWrapper">
					<div class="container-fluid">
						<div class="row">
							<div class="col-8">
								<div class="contentCard">
									<div class="cardHeader">
										<h1>Edit Profile</h1>
									</div>
									<hr/>
									<div class="cardBody formWrapper">
										<form>
											<div className="row">
												<div className="col-12">
													<h2 class="cardInnerTitle clearfix">Personal Detail</h2>
												</div>
												<div className="col-12 col-md-4">
													<label>First Name</label>
													<input type="text" value={this.state?.recordDetail?.first_name}
														   onChange={(e)=> this.setState({recordDetail: {...this.state.recordDetail, first_name : e.target.value }})}
													>
													</input>
												</div>
												<div className="col-12 col-md-4">
													<label>Last Name</label>
													<input type="text" value={this.state?.recordDetail?.last_name}
														   onChange={(e)=> this.setState({recordDetail: {...this.state.recordDetail, last_name : e.target.value }})}
													>
													</input>
												</div>
												<div className="col-12 col-md-4">
													<label>Email</label>
													<input type="text" value={this.state?.recordDetail?.email}
														   onChange={(e)=> this.setState({recordDetail: {...this.state.recordDetail, email : e.target.value }})}
													>
													</input>
												</div>
												<div className="col-12 col-md-4">
													<label>Phone Number</label>
													<input type="text" value={this.state?.recordDetail?.phone}
														   onChange={(e)=> this.setState({recordDetail: {...this.state.recordDetail, phone : e.target.value }})}
													>
													</input>
												</div>
											</div>
											<div className="text-right">
												<button className="myBtn mt-2" onClick={this.handleSubmit}>Update</button>
											</div>
										</form>
									</div>
								</div>
							</div>
							<div className="col-4">
								<div className="contentCard">
									<div className="cardHeader">
										<h1>Update Password</h1>
									</div>
									<hr/>
									<div className="cardBody formWrapper">
										<form>
											<div className="col-12 col-md-12">
												<label>Current Password</label>
												<input type={this.state?.isCurrPassChecked ? "text" : "password"} className={"password-input"}
												   onChange={(e) => this.setState({
															   password: {
																...this.state.password,
															   currentPassword: e.target.value
														   }
												   })}
												>
												</input>
												<i className={` fa fa-eye${!this.state.isCurrPassChecked? `-slash`: ``} password-input-eye-icon`} onClick={()=> {this.state?.isCurrPassChecked ? this.setState({isCurrPassChecked: false}): this.setState({isCurrPassChecked: true}) }}>
												</i>
											</div>
											<div className="col-12 col-md-12">
												<label>New Password</label>
												<input type={this.state?.isNewPassChecked ? "text" : "password"} className={"password-input"}
													   onChange={(e) => this.setState({
														   password: {
															   ...this.state.password,
															   newPassword: e.target.value
														   }
													   })}
												>
												</input>
												<i className={` fa fa-eye${!this.state.isNewPassChecked? `-slash`: ``} password-input-eye-icon`} onClick={()=> {this.state?.isNewPassChecked ? this.setState({isNewPassChecked: false}): this.setState({isNewPassChecked: true}) }}> </i>
											</div>
											<div className="col-12 col-md-12">
												<label>Confirm Password</label>
												<input type={this.state?.isConfirmPassChecked ? "text" : "password"} className={"password-input"}
													   onChange={(e) => {
													   	if (this.state?.password?.newPassword === e.target.value ){
													   		this.setState({isPassMatch: false, errorMsg: ""})
														}else{
													   		this.setState({isPassMatch: true , errorMsg: "Password is not matching"})
														}
													   	this.setState({
														   password: {
															   ...this.state.password,
															   confirmPassword: e.target.value
														   }
													   })}
												}
												>
												</input>
												<i className={` fa fa-eye${!this.state.isConfirmPassChecked? `-slash`: ``} password-input-eye-icon`} onClick={()=> {this.state?.isConfirmPassChecked ? this.setState({isConfirmPassChecked: false}): this.setState({isConfirmPassChecked: true}) }}> </i>
											</div>
											<div className="col-12 col-md-12">
												<span className={"error_msg"}>{this.state?.errorMsg}</span>
											</div>

											<div className="text-right">
												<button className="myBtn mt-2" onClick={this.handleChangePassword}>Update</button>
											</div>
										</form>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
				<PageFooter />
			</React.Fragment>
		);
	}
}

export default AccouhntSetting;
