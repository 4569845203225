import React from "react";
import ChangeTitle from "../../../components/change_page_title";
import SideBar from "../../../components/side-bar";
import PageHeader from "../../../components/page-header";
import BreadCrumb from "../../../components/bread-crumb";
import Notifications, { notify } from "react-notify-toast";
import PageFooter from "../../../components/page-footer";
import { withRouter } from "react-router-dom";
class Reservations extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      error: null,
      loading: true,
      filter: null,
    };
  }
  componentDidMount() {
    this.fetchData();
    this.fetchDataB();
    console.log(this.state.data, "data");
  }

  breadcrumbNavigation = [
    {
      title: "Manage User",
      link: "#",
    },
    {
      title: "Reservations",
      link: "#",
    },
  ];
  handleRedirect = (element) => {
    const { history } = this.props;
    const url = `truck-order-detail?id=${element?._id}&order_id=${element?.order_id}`;
    history.push(url);
  };

  async fetchDataB() {
    try {
      const response = await fetch(
        `${process.env.backendbaseurl}/api/public/list_filter_data`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            authorization: "",
          },
        }
      );
      const data = await response.json();
      // console.log('all amminities data', data.data.truck_provider.amenities);
      console.log(data?.data, "i am all filtersss");
      this.setState({
        filter: data?.data,
        // loading: false
      });
    } catch (error) {
      console.error(error);
    }
  }
  renderListing = (data) => {
    return data?.map((element, idx) => {
      return (
        <>
          <tr>
            <td>{idx + 1}</td>
            <td>{`${element?.created_by_info?.first_name ?? "--"}  ${
              element?.created_by_info?.last_name ?? "--"
            }`}</td>
            <td
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems:'center',
                gap: "10px",
              }}
            >
              {element?.order_id}
             {
              element?.order_id && element?._id && <button
                className="myBtn btn-danger btnSm"
                onClick={() => this.handleRedirect(element)}
              >
                <i className=" fa fa-eye"></i>
              </button>
             } 
            </td>
            <td>{element?.created_by_info?.email}</td>
            <td>{element?.created_by_info?.phone_no}</td>
            <td>{`${element?.company_info?.first_name ?? "--"} ${
              element?.company_info?.last_name
            }`}</td>
            <td>$ {element?.reservation_fee}</td>
            {/* <td>{element?.truck}</td>
            <td>{element?.details?.licensePlate}</td> */}
            {/* <td>
              <button
                className="myBtn btn-danger btnSm"
                // onClick={() => this.hitFire(element?._id)}
              >
                <i class="fa fa-trash"></i>
              </button>
              <button
                class="myBtn btn-success btnSm mx-1"
                onClick={() => this.props.history.push("edit-reservations?id=" + element?.id)}>
                <i class="fa fa-edit"></i>
              </button>
            </td> */}
          </tr>
        </>
      );
    });
  };

  // /api/admin/reservation/list

  async fetchData() {
    const apiUrl =
      "https://dev-api.getspaceshuttle.com/api/admin/reservation/list";
    try {
      const token = localStorage.getItem("adminToken");
      const response = await fetch(apiUrl, {
        method: "GET",
        headers: {
          Authorization: `${token}`,
        },
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      this.setState({
        data: data?.data,
        loading: false,
      });
    } catch (error) {
      // Handle errors, update state and log the error
      this.setState({
        error,
        loading: false,
      });
      console.error("Error fetching data:", error);
    }
  }
  render() {
    const { data, err, filter } = this.state;

    console.log(data, "i am data");
    return (
      <React.Fragment>
        <ChangeTitle title="Manage User" />
        <SideBar />
        <PageHeader />
        <Notifications options={{ zIndex: 10000 }} />
        <BreadCrumb navigation={this.breadcrumbNavigation} />
        <section class="pageWrapper">
          <div class="container-fluid">
            <div class="row">
              <div class="col-12">
                <div class="contentCard">
                  <div class="cardHeader clearfix">
                    <h1>All Reservations List</h1>
                    <div
                      type={"submit"}
                      // className="myBtn"
                      onClick={() =>
                        this.props.history.push("add-reservations")
                      }
                    >
                      {/* + Create Add On{" "} */}
                    </div>
                  </div>
                  <div class="cardBody">
                    <div class="table-responsive customStriped">
                      <table class="table">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Name</th>
                            <th>Order Id</th>
                            <th>Email</th>
                            <th>Phone</th>
                            <th>Provider</th>
                            <th>Reservation Fee</th>
                            {/* <th>Action</th> */}
                          </tr>
                        </thead>
                        <tbody>{this.renderListing(data)}</tbody>
                      </table>
                    </div>
                    <div class="paginationWrapper clearfix">
                      <div className="paginationWrapper clearfix">
                        {/* <Pagination
                          activePage={this.state?.pagination?.current_page}
                          itemsCountPerPage={
                            this.state?.pagination?.itemsCountPerPage
                          }
                          totalItemsCount={
                            this.state?.pagination?.total_records
                          }
                          pageRangeDisplayed={
                            this.state?.pagination?.total_pages
                          }
                          onChange={this.handlePageChange.bind(this)}
                          itemClass={"page-item"}
                          linkClass={"page-link"}
                        /> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <PageFooter />
      </React.Fragment>
    );
  }
}

export default withRouter(Reservations);
