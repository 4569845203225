/**
 * @author Basit Raza <razabasit88@gmail.com>
 * @link http://be.net/basit_raza Author Website
 * @since 2021-01-18
 */
import React from "react";
import PageHeader from "../../components/page-header";
import ChangeTitle from "../../components/change_page_title";
import PageFooter from "../../components/page-footer";
import SideBar from "../../components/side-bar";
import BreadCrumb from "../../components/bread-crumb";
// date time picker
import DateRangePicker from "react-bootstrap-daterangepicker";
// you will also need the css that comes with bootstrap-daterangepicker
import "bootstrap-daterangepicker/daterangepicker.css";

class Dashboard extends React.Component {
  breadcrumbNavigation = [
    {
      title: "Dashboard",
      link: "#",
    },
    {
      title: "Dashboard",
      link: "#",
    },
  ];
  handleEvent(event, picker) {
    console.log(picker.startDate.toDate());
    console.log(picker.endDate.toDate());
  }
  render() {
    return (
      /*Content Area*/
      <React.Fragment>
        <ChangeTitle title={"Dashboard"} />
        {/*SideBar*/}
        <SideBar />
        {/*Page Header*/}
        <PageHeader />
        <BreadCrumb navigation={this.breadcrumbNavigation} />
        {/* main content starts here */}
        <section class="pageWrapper">
          <div class="container-fluid">
            <div class="row">
              <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                <div class="dashboardCard info">
                  <div className="cardContent">
                    <h6>Total Number User</h6>
                    <h3>2,580</h3>
                    <a href="javascript:void(0)">view all </a>
                  </div>
                  <div className="cardIcon">
                    <i class="fa fa-user"></i>
                  </div>
                </div>
              </div>
              <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                <div class="dashboardCard success">
                  <div className="cardContent">
                    <h6>Revenue</h6>
                    <h3>$28626858</h3>
                    <a href="javascript:void(0)">view all </a>
                  </div>
                  <div className="cardIcon">
                    <i class="fa fa-user"></i>
                  </div>
                </div>
              </div>
              <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                <div class="dashboardCard primary">
                  <div className="cardContent">
                    <h6>Total Funds</h6>
                    <h3>2,580</h3>
                    <a href="javascript:void(0)">view all </a>
                  </div>
                  <div className="cardIcon">
                    <i class="fa fa-user"></i>
                  </div>
                </div>
              </div>
              <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                <div class="dashboardCard danger">
                  <div className="danger">
                    <h6>Pending Orders</h6>
                    <h3>2,580</h3>
                    <a href="javascript:void(0)">view all </a>
                  </div>
                  <div className="cardIcon">
                    <i class="fa fa-user"></i>
                  </div>
                </div>
              </div>
              <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                <div class="dashboardCard info">
                  <div className="cardContent">
                    <h6>Expected Packages</h6>
                    <h3>2,580</h3>
                    <a href="javascript:void(0)">view all </a>
                  </div>
                  <div className="cardIcon">
                    <i class="fa fa-user"></i>
                  </div>
                </div>
              </div>
              <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                <div class="dashboardCard success">
                  <div className="cardContent">
                    <h6>Completed Orders</h6>
                    <h3>2,580</h3>
                    <a href="javascript:void(0)">view all </a>
                  </div>
                  <div className="cardIcon">
                    <i class="fa fa-user"></i>
                  </div>
                </div>
              </div>
              <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                <div class="dashboardCard primary">
                  <div className="cardContent">
                    <h6>Received Packages</h6>
                    <h3>2,580</h3>
                    <a href="javascript:void(0)">view all </a>
                  </div>
                  <div className="cardIcon">
                    <i class="fa fa-user"></i>
                  </div>
                </div>
              </div>
              <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                <div class="dashboardCard danger">
                  <div className="cardContent">
                    <h6>Pending Assisted Purchases</h6>
                    <h3>2,580</h3>
                    <a href="javascript:void(0)">view all </a>
                  </div>
                  <div className="cardIcon">
                    <i class="fa fa-user"></i>
                  </div>
                </div>
              </div>
              <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                <div class="dashboardCard info">
                  <div className="cardContent">
                    <h6>Completed Assisted Purchases</h6>
                    <h3>2,580</h3>
                    <a href="javascript:void(0)">view all </a>
                  </div>
                  <div className="cardIcon">
                    <i class="fa fa-user"></i>
                  </div>
                </div>
              </div>
              <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                <div class="dashboardCard success">
                  <div className="cardContent">
                    <h6>Pending Invoices</h6>
                    <h3>2,580</h3>
                    <a href="javascript:void(0)">view all </a>
                  </div>
                  <div className="cardIcon">
                    <i class="fa fa-user"></i>
                  </div>
                </div>
              </div>
              <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                <div class="dashboardCard primary">
                  <div className="cardContent">
                    <h6>Ready to Ship</h6>
                    <h3>2,580</h3>
                    <a href="javascript:void(0)">view all </a>
                  </div>
                  <div className="cardIcon">
                    <i class="fa fa-user"></i>
                  </div>
                </div>
              </div>
              <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                <div class="dashboardCard danger">
                  <div className="cardContent">
                    <h6>Delivered</h6>
                    <h3>2,580</h3>
                    <a href="javascript:void(0)">view all </a>
                  </div>
                  <div className="cardIcon">
                    <i class="fa fa-user"></i>
                  </div>
                </div>
              </div>
              <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                <div class="dashboardCard info">
                  <div className="cardContent">
                    <h6>Open Tickets</h6>
                    <h3>2,580</h3>
                    <a href="javascript:void(0)">view all </a>
                  </div>
                  <div className="cardIcon">
                    <i class="fa fa-user"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <PageFooter />
      </React.Fragment>
    );
  }
}

export default Dashboard;
