/**
 * @author Basit Raza <razabasit88@gmail.com>
 * @link http://be.net/basit_raza Author Website
 * @since 2021-01-18
 */
import React from "react";
import PageHeader from "../../../../components/page-header";
import ChangeTitle from "../../../../components/change_page_title";
import PageFooter from "../../../../components/page-footer";
import SideBar from "../../../../components/side-bar";
import BreadCrumb from "../../../../components/bread-crumb";
import $ from "jquery";
import {
  adminApiCall,
  adminApiCallForFormData,
} from "../../../../utils/ajax_request";
import Notifications, { notify } from "react-notify-toast";
import Swal from "sweetalert2";
import ReactFancyBox from "react-fancybox";
import "react-fancybox/lib/fancybox.css";
import { baseDomain, storageProvider } from "../../../../config";
import { Multiselect } from "multiselect-react-dropdown";

class AddCountry extends React.Component {
  breadcrumbNavigation = [
    {
      title: "Storage Management",
      link: "#",
    },
    {
      title: "Edit Storage Category",
      link: "#",
    },
  ];
  state = {
    recordDetail: "",
    isMediaFileExist: false,
    multi_select_selected_values: "",
    multi_select_preselected_values: "",
    amenities_list: [],
  };
  componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search);
    const id = urlParams.get("id");
    if (!id) {
      Swal.fire({
        title: "Error",
        text: "Invalid_access_error",
        icon: "error",
      });
    } else {
      this.getRecordDetail(id);
    }
  }
  multiSelectlisting = async () => {
    try {
      let res = await adminApiCall(
        "amenities/amenities_select_list",
        "POST",
        JSON.stringify({ type: storageProvider })
      );
      if (!res) {
        notify.show("Something_went_wrong", "error", 2000);
        return false;
      }
      if (res.hasOwnProperty("status")) {
        if (res.status === 200 && res.data) {
          if (
            this.state?.recordDetail?.amenities_ids &&
            this.state?.recordDetail?.amenities_ids?.length > 0 &&
            this.state?.recordDetail?.amenities_ids[0] !== " "
          ) {
            let multiSelectPreSelectOptionsList =
              this.state.recordDetail.amenities_ids.map((ele, index) => {
                return res.data.find((obj) => obj._id == ele);
              });
            console.log(multiSelectPreSelectOptionsList);
            this.setState({
              multi_select_preselected_values:
                multiSelectPreSelectOptionsList.includes(undefined)
                  ? []
                  : multiSelectPreSelectOptionsList,
            });
          }
          this.setState({ amenities_list: res.data });
        } else if (res.status === 400) {
          this.setState({ data: [] });
          notify.show(res.message, "error", 2000);
        }
      }
    } catch (e) {
      notify.show("Something_gone_wrong", "error", 2000);
    } finally {
    }
  };
  getRecordDetail = async (id) => {
    try {
      $("#loader").show();
      let res = await adminApiCall(
        "search_filter_category/get",
        "POST",
        JSON.stringify({ search_filter_category_id: id, type: storageProvider })
      );
      if (!res) {
        Swal.fire({
          title: "Error",
          text: "Something_went_wrong",
          icon: "error",
        });
        return false;
      }
      if (res.hasOwnProperty("status")) {
        console.log(res);
        if (res?.status === 200 && res?.data) {
          this.setState({
            recordDetail: res?.data,
            isMediaFileExist: res?.data?.media_file?.[0]?.url,
          });
          this.multiSelectlisting();
        } else if (res?.status === 400) {
          Swal.fire({
            title: "Error",
            text: res?.message,
            icon: "error",
          });
        }
      }
    } catch (e) {
      Swal.fire({
        title: "Error",
        text: "Something_gone_wrong",
        icon: "error",
      });
    } finally {
      $("#loader").hide();
    }
  };
  handleSubmit = async (event) => {
    try {
      $("#loader").show();
      event.preventDefault();
      // console.log(this.state?.multi_select_preselected_values)
      // let a = this.state?.multi_select_preselected_values.map(e => e._id).join(",")
      // console.log(a)
      // console.log(this.state?.a)
      // return
      let formData = new FormData(event.target);
      formData.append(
        "search_filter_category_id",
        this.state?.recordDetail?._id
      );
      formData.append("amenities_ids", this.state.multi_select_selected_values);
      // ? this.state?.multi_select_selected_values : this.state?.multi_select_preselected_values.map(e => e._id).join(",")
      formData.append("type", storageProvider);
      let res = await adminApiCallForFormData(
        "search_filter_category/update",
        formData
      );
      if (!res) {
        notify.show("Something_went_wrong", "error", 2000);
        return false;
      }
      if (res.hasOwnProperty("status")) {
        if (res.status == 200) {
          notify.show(res.message, "success", 2000);
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        } else if (res.status == 400) {
          notify.show(res.message, "error", 2000);
        }
      }
    } catch (e) {
      console.log(e);
      notify.show("Something_gone_wrong", "error", 2000);
    } finally {
      $("#loader").hide();
    }
  };
  onSelectHandle = (selectedList, selectedItem) => {
    let selectOptionsArray = [];
    for (const selectedListElement of selectedList) {
      selectOptionsArray.push(selectedListElement._id);
    }
    this.setState({
      multi_select_selected_values: selectOptionsArray.join(","),
    });
  };
  onRemoveHandle = (selectedList, selectedItem) => {
    let selectOptionsArray = [];
    for (const selectedListElement of selectedList) {
      selectOptionsArray.push(selectedListElement._id);
    }
    this.setState({
      multi_select_selected_values: selectOptionsArray.join(","),
    });
  };

  render() {
    return (
      /*Content Area*/
      <React.Fragment>
        <ChangeTitle title={"Storage categories"} />

        {/*SideBar*/}
        <SideBar />

        {/*Page Header*/}
        <PageHeader />
        <Notifications options={{ zIndex: 10000 }} />
        <BreadCrumb navigation={this.breadcrumbNavigation} />

        {/* main content starts here */}

        <section class="pageWrapper">
          <div class="container-fluid">
            <div class="row">
              <div class="col-12">
                <div class="contentCard">
                  <div class="cardHeader clearfix">
                    <h1>Edit Storage Category</h1>
                  </div>
                  <div class="cardBody">
                    <form onSubmit={this.handleSubmit}>
                      <div className="row formWrapper">
                        <div className="col-12 col-md-12">
                          <div className="form-group inlineField">
                            <div className="row align-items-center">
                              <div className="col-12 col-md-4 col-lg-3">
                                <label>Name</label>
                              </div>
                              <div className="col-12 col-md-8 col-lg-9">
                                <input
                                  type="text"
                                  name={"name"}
                                  value={this.state?.recordDetail?.name}
                                  onChange={(e) =>
                                    this.setState({
                                      recordDetail: {
                                        ...this.state.recordDetail,
                                        name: e.target.value,
                                      },
                                    })
                                  }
                                  onKeyPress={this.onKeyPressHandle}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-md-12">
                          <div className="form-group inlineField">
                            <div className="row align-items-center">
                              <div className="col-12 col-md-4 col-lg-3">
                                <label>Minimum (Square Feet)</label>
                              </div>
                              <div className="col-12 col-md-8 col-lg-9">
                                <input
                                  type="number"
                                  name={"minimum_square_feet"}
                                  value={
                                    this.state?.recordDetail
                                      ?.minimum_square_feet
                                  }
                                  onChange={(e) =>
                                    this.setState({
                                      recordDetail: {
                                        ...this.state.recordDetail,
                                        minimum_square_feet: e.target.value,
                                      },
                                    })
                                  }
                                  onKeyPress={this.onKeyPressHandle}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-md-12">
                          <div className="form-group inlineField">
                            <div className="row align-items-center">
                              <div className="col-12 col-md-4 col-lg-3">
                                <label>Maximum (Square Feet)</label>
                              </div>
                              <div className="col-12 col-md-8 col-lg-9">
                                <input
                                  type="number"
                                  name={"maximum_square_feet"}
                                  value={
                                    this.state?.recordDetail
                                      ?.maximum_square_feet
                                  }
                                  onChange={(e) =>
                                    this.setState({
                                      recordDetail: {
                                        ...this.state.recordDetail,
                                        maximum_square_feet: e.target.value,
                                      },
                                    })
                                  }
                                  onKeyPress={this.onKeyPressHandle}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-md-12">
                          <div className="form-group inlineField">
                            <div className="row align-items-center">
                              <div className="col-12 col-md-4 col-lg-3">
                                <label>Amenities </label>
                              </div>
                              <div className="col-12 col-md-8 col-lg-9">
                                <Multiselect
                                  options={this.state?.amenities_list}
                                  displayValue={"title"}
                                  selectedValues={
                                    this.state?.multi_select_preselected_values
                                  }
                                  onSelect={(list, item) =>
                                    this.onSelectHandle(list, item)
                                  }
                                  onRemove={(list, item) =>
                                    this.onRemoveHandle(list, item)
                                  }
                                ></Multiselect>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-md-12">
                          <div className="form-group inlineField">
                            <div className="row align-items-center">
                              <div className="col-12 col-md-4 col-lg-3">
                                <label>Media Files</label>
                              </div>
                              <div className="col-12 col-md-8 col-lg-9">
                                {this.state?.isMediaFileExist ? (
                                  this.state?.recordDetail?.media_file?.[0]
                                    ?.mime_type !== "video" ? (
                                    <>
                                      <span
                                        className={
                                          "change-cursor-image-span-hover"
                                        }
                                        onClick={() =>
                                          this.setState({
                                            isMediaFileExist: false,
                                          })
                                        }
                                      >
                                        X
                                      </span>
                                      <ReactFancyBox
                                        thumbnail={
                                          this.state?.recordDetail &&
                                          baseDomain +
                                            this.state?.recordDetail
                                              ?.media_file?.[0]?.url
                                        }
                                        image={
                                          this.state?.recordDetail &&
                                          baseDomain +
                                            this.state?.recordDetail
                                              ?.media_file?.[0]?.url
                                        }
                                      />
                                    </>
                                  ) : (
                                    <>
                                      <video width="100" height="100" controls>
                                        <source
                                          src={
                                            this.state?.recordDetail &&
                                            baseDomain +
                                              this.state?.recordDetail
                                                ?.media_file?.[0]?.url
                                          }
                                          type="video/mp4"
                                        />
                                      </video>
                                      <span
                                        className={
                                          "change-cursor-image-span-hover"
                                        }
                                        onClick={() =>
                                          this.setState({
                                            isMediaFileExist: false,
                                          })
                                        }
                                      >
                                        X
                                      </span>
                                    </>
                                  )
                                ) : (
                                  <div className="inputFile">
                                    <input
                                      name="media_file"
                                      type="file"
                                      multiple
                                    />
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-md-12">
                          <div className="form-group inlineField">
                            <div className="row align-items-center">
                              <div className="col-12 col-md-4 col-lg-3">
                                <label>Description</label>
                              </div>
                              <div className="col-12 col-md-8 col-lg-9">
                                <textarea
                                  name="description"
                                  rows="5"
                                  onKeyPress={this.onKeyPressHandle}
                                  value={this.state?.recordDetail?.description}
                                  onChange={(e) =>
                                    this.setState({
                                      recordDetail: {
                                        ...this.state.recordDetail,
                                        description: e.target.value,
                                      },
                                    })
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-md-12">
                          <div className="form-group inlineField">
                            <div className="row align-items-center">
                              <div className="col-12 col-md-4 col-lg-3">
                                <label>Status</label>
                              </div>
                              <div className="col-12 col-md-8 col-lg-9">
                                <div className="toggleBtn">
                                  <input
                                    type="checkbox"
                                    id="togglePrice"
                                    checked={
                                      this.state?.recordDetail?.status === "1"
                                        ? "checked"
                                        : null
                                    }
                                    name={"status"}
                                    onChange={(e) =>
                                      this.setState({
                                        recordDetail: {
                                          ...this.state.recordDetail,
                                          status: e.target.checked,
                                        },
                                      })
                                    }
                                  />
                                  <label htmlFor="togglePrice">
                                    <span className="on">ON</span>
                                    <span className="off">OFF</span>
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-12 col-md-12 text-right">
                          <button
                            type={"submit"}
                            class="myBtn"
                            onClick={this.register}
                          >
                            Update{" "}
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <PageFooter />
      </React.Fragment>
    );
  }
}

export default AddCountry;
