/**
 * @author Basit Raza <razabasit88@gmail.com>
 * @link http://be.net/basit_raza Author Website
 * @since 2021-01-18
 */
import React from "react";
import ChangeTitle from "../../../components/change_page_title";
import "./style.css";
import Swal from "sweetalert2";
import $ from "jquery";
import { adminLogin } from "../../../utils/ajax_request";
class Login extends React.Component {
  componentDidMount() {
    if (
      localStorage.getItem("adminToken") &&
      localStorage.getItem("adminName")
    ) {
      Swal.fire({
        title: "Already logged in",
        text: "Redirecting you to dashboard",
        icon: "success",
        showConfirmButton: false,
      });
      setTimeout(() => {
        window.location.href = "/dashboard";
      }, 2000);
    }
  }
  componentDidMount() {
    $("body").css(
      "background-image",
      "linear-gradient(rgb(70 74 83), rgb(4 4 4))"
    );
    $("body").css("background-repeat", "no-repeat");
    $("body").css("height", "100vh");
  }

  componentWillUnmount() {
    $("body").css("background-image", "unset");
    $("body").css("background-repeat", "unset");
    $("body").css("height", "unset");
  }

  //============ login user ==========
  async login() {
    try {
      if ($("#inputEmail").val() && $("#inputPassword").val()) {
        $("#loader").show();
        let res = await adminLogin(
          JSON.stringify({
            email: $("#inputEmail").val(),
            password: $("#inputPassword").val(),
          })
        );
        if (!res) {
          Swal.fire({
            title: "Error",
            text: "Something_went_wrong",
            icon: "error",
          });
          return false;
        }
        if (res.hasOwnProperty("status")) {
          if (res.status === 200 && res.hasOwnProperty("data")) {
            localStorage.setItem("adminToken", res.data.token);
            localStorage.setItem(
              "adminName",
              res.data.adminData.first_name + " " + res.data.adminData.last_name
            );
            localStorage.setItem("role", res?.data?.adminData?.role);
            Swal.fire({
              title: "Login_successful",
              text: "Redirecting_you_to_dashboard",
              icon: "success",
              showConfirmButton: false,
            });
            setTimeout(() => {
              window.location.href = "/dashboard";
            }, 2000);
          } else if (res.status === 300) {
            Swal.fire({
              title: "Error!",
              text: res.message,
              icon: "error",
            });
          } else {
            Swal.fire({
              title: "Error!",
              text: res.message,
              icon: "error",
            });
          }
        }
      }
    } catch (e) {
      Swal.fire({
        title: "Error",
        text: "Something_gone_wrong",
        icon: "error",
      });
      console.log(e);
    } finally {
      $("#loader").hide();
    }
  }

  render() {
    return (
      /*Content Area*/
      <React.Fragment>
        <ChangeTitle title={"Login"} />
        <div className="container">
          <div className="">
            {/* <div className="row align-items-center">
							<div className="col-12 col-md-5">
								<img id="profile-img" className="profile-img-card" src="/assets/images/user.jpg" />
								<div className="form-signin">
									<span id="reauth-email" className="reauth-email"></span>
									<input type="text" id="inputEmail" name="email" className="form-control" placeholder="Email" required autoFocus />
									<div className="input-group">
										<input type="password" id="inputPassword" name="password" className="form-control mb-0" placeholder="Password" required />
									</div>
									<div id="remember" className="checkbox my-2">
										<label><input type="checkbox" value="remember-me" checked /> Remember me</label>
										<a href="#" className="forgot-password pull-right">Forget Password</a>
									</div>
									<button className="btn btn-lg btn-primary btn-block btn-signin">Sign in</button>
								</div>
							</div>
							<div className="col-12 col-md-6 text-center">

							</div>
						</div> */}
            <div className="card loginWrapper">
              <div className="signinDetail text-center">
                {/*<img src="/assets/images/logo.svg" alt="" />*/}
                <h3>Welcome Back</h3>
                <p>Login to Manage Your Account</p>
              </div>
              <div className="form-signin">
                <div className="form-group">
                  <input
                    type="text"
                    id="inputEmail"
                    name="email"
                    className="form-control"
                    placeholder="Email"
                    required
                    autoFocus
                  />
                </div>
                <div className="form-group">
                  <input
                    type="password"
                    id="inputPassword"
                    name="password"
                    className="form-control mb-0"
                    placeholder="Password"
                    required
                  />
                </div>
                <div id="remember" className="checkbox my-2">
                  <label>
                    <input type="checkbox" value="remember-me" checked />{" "}
                    Remember me
                  </label>
                  <a href="#" className="forgot-password pull-right">
                    Forget Password
                  </a>
                </div>
                <button
                  className="btn btn-lg btn-primary btn-block btn-signin"
                  onClick={this.login}
                >
                  Sign in
                </button>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Login;
