/**
 * @author Basit Raza <razabasit88@gmail.com>
 * @link http://be.net/basit_raza Author Website
 * @since 2021-01-18
 */
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import {
    Route,
    BrowserRouter as Router,
    Switch
} from 'react-router-dom';


/*======================= AUTHENTICATION ========================*/
import Login from './pages/authentication/login';

/*======================== PAGE IMPORTS ========================*/
import Dashboard from './pages/dashboard';
//=========== ACCOUNT SETTING =================================
import AccountSetting from './pages/authentication/account-setting';

/*===================== USER MANAGEMENT ======================*/
import StoreProviderManagementList from './pages/user-management/store-provider-listing';
import EditStoreProvider from './pages/user-management/edit-store-provider';
import Reservations from './pages/user-management/reservations';
import Discounts from './pages/user-management/discounts';
import AddDiscounts from './pages/user-management/discounts/add-discounts';
import EditDiscounts from './pages/user-management/discounts/edit-discounts';
import AddReservations from './pages/user-management/reservations/add-reservations';
import EditReservations from './pages/user-management/reservations/edit-reservations';
import Leads from './pages/user-management/leads';
import AddLeads from './pages/user-management/leads/add-leads';
import EditLeads from './pages/user-management/leads/edit-leads';
import TruckProviderManagementList from './pages/user-management/truck-provider-listing';
import ConsumerManagementList from './pages/user-management/consumer-listing';
import ReferFriend from './pages/user-management/refer-friend';

import AdminManagementList from './pages/admin-management/admin-listing';
import CustomerProfile from './pages/user-management/customer-profile';
import AddAdmin from './pages/admin-management/add-user';
import EditAdmin from './pages/admin-management/edit-user';


/*========================= END ==============================*/
/*===================== USER MANAGEMENT ======================*/
import RoleListing from './pages/roles-management/roles-list';
import AddRole from './pages/roles-management/add-role';
/*========================= END ==============================*/
/*===================== PRODUCT MANAGEMENT ======================*/
import ProductList from './pages/product-management/product-list';
import AddProduct from './pages/product-management/add-product';
import EditProduct from './pages/product-management/edit-product';
/*========================= END ==============================*/
/*===================== PACKAGES MANAGEMENT ======================*/
import AllPackages from './pages/packages/all-packages';
import Shipments from './pages/shipment';
/*========================= END ==============================*/
/*===================== FEATURE MANAGEMENT ======================*/
import FeatureList from './pages/feature-management/features-list';
import AddFeature from './pages/feature-management/add-feature';
import EditFeature from './pages/feature-management/edit-feature';
/*========================= END ==============================*/
/*===================== ADDoN MANAGEMENT ======================*/
import AddOnList from './pages/add-ons-management/addOns-list';
import AddAddOn from './pages/add-ons-management/add-addOn';
import EditAddOn from './pages/add-ons-management/edit-addOn';
/*========================= END ==============================*/
/*===================== Truck MANAGEMENT ======================*/
//=============== STORAGE CATEGORY =====================
import StorageCategoryList from './pages/storage-management/storage-category/storage-categories-list';
import AddStorageCategory from './pages/storage-management/storage-category/add-storage-category';
import EditStorageCategory from './pages/storage-management/storage-category/edit-storage-category';
//======================== END ===============================
/*===================== Truck MANAGEMENT ======================*/
//==================== TRUCK CATEGORY LIST ====================
import TruckCategoryList from './pages/truck-management/truck-category/truck-categories-list';
import AddTruckCategory from './pages/truck-management/truck-category/add-truck-category';
import EditTruckCategory from './pages/truck-management/truck-category/edit-truck-category';
//=============== TRUCK TYPE =====================
import TruckTypeList from './pages/truck-management/truck-types/truck-types-list';
import AddTruckType from './pages/truck-management/truck-types/add-truck-type';
import EditTruckType from './pages/truck-management/truck-types/edit-truck-type';
/*========================= END ==============================*/
/*===================== AMENITY MANAGEMENT ======================*/
import AmenityList from './pages/amenity-management/amenities-list';
import AddAmenity from './pages/amenity-management/add-amenity';
import EditAmenity from './pages/amenity-management/edit-amenity';
/*========================= END ==============================*/
/*===================== PACKAGES MANAGEMENT ======================*/
import AddressListing from './pages/address-book/address-list';
import AddAddress from './pages/address-book/add-address';
import EditAddress from './pages/address-book/edit-address';
import CustomerAddress from './pages/address-book/customer-address-list';
// import EditCountry from './pages/address-book/edit-address';
/*========================= END ==============================*/
/*===================== SETTINGS ======================*/
import ApiSetting from './pages/settings/api-settings';
import SiteSetting from './pages/settings/site-settings';
/*====================== END ===================================*/
/*===================== EMAIL MANAGEMENT ======================*/
import EmailLogs from './pages/email-management/email-logs';
import ViewEmail from './pages/email-management/view-email';
//-==================== EMAIL TEMPLATE =======================
import EmailTemplateList from './pages/email-management/email-template/email-templates-list';
import AddEmailTemplate from './pages/email-management/email-template/add-email-template';
import EditEmailTemplate from './pages/email-management/email-template/edit-email-template';
import CreateEmailTemplate from './pages/email-management/email-template/create-email-template';
//============= ORDER MANAGEMENT ========================
import TruckOrderList from './pages/order-management/truck-order-listing';
import TruckOrderDetail from './pages/order-management/truck-order-detail';
import StorageUnitOrderList from './pages/order-management/storage-unit-order-listing';
import StorageUnitOrderDetail from './pages/order-management/storage-unit-order-detail';

/*===================== 404 PAGE ======================*/
import Client404 from './pages/404/notfound';
const routing = (
    <Router>
        <Switch>
            {/*=========================================================*/}
            <Route exact path="/" component={Login}/>
            <Route exact path="/dashboard" component={Dashboard}/>
            <Route exact path="/account-setting" component={AccountSetting}/>
            {/*==================== USER MANAGEMENT ===================*/}
            <Route exact path="/storage-provider-list" component={StoreProviderManagementList}/>
            <Route exact path="/edit-storage-provider" component={EditStoreProvider}/>
            {/*==================== Reservations ===================*/}
            <Route exact path="/reservations" component={Reservations}/>
            <Route exact path="/add-reservations" component={AddReservations}/>
            <Route exact path="/edit-reservations" component={EditReservations}/>
            {/*==================== Reservations end ===================*/}
            {/*==================== Leads ===================*/}
            <Route exact path="/leads" component={Leads}/>
            <Route exact path="/add-leads" component={AddLeads}/>
            <Route exact path="/edit-leads" component={EditLeads}/>
            {/*==================== Leads end ===================*/}
            {/*==================== Discounts ===================*/}
            <Route exact path="/discounts" component={Discounts}/>
            <Route exact path="/add-discounts" component={AddDiscounts}/>
            <Route exact path="/edit-discounts" component={EditDiscounts}/>
            {/*==================== Discounts End  ===================*/}
            <Route exact path="/truck-provider-list" component={TruckProviderManagementList}/>
            <Route exact path="/consumer-list" component={ConsumerManagementList}/>
            <Route exact path="/refer-friend" component={ReferFriend}/>
            <Route exact path="/admin-list" component={AdminManagementList}/>
            <Route exact path="/customer-profile" component={CustomerProfile}/>
            <Route exact path="/add-admin" component={AddAdmin}/>
            <Route exact path="/edit-admin" component={EditAdmin}/>
            {/*==================== USER MANAGEMENT ===================*/}
            <Route exact path="/role-list" component={RoleListing}/>
            <Route exact path="/add-role" component={AddRole}/>
            {/*==================== ORDER MANAGEMENT ===================*/}
            <Route exact path="/truck-orders-list" component={TruckOrderList}/>
            <Route exact path="/truck-order-detail" component={TruckOrderDetail}/>
            <Route exact path="/storage-unit-orders-list" component={StorageUnitOrderList}/>
            <Route exact path="/storage-unit-order-detail" component={StorageUnitOrderDetail}/>
            {/*==================== PRODUCT MANAGEMENT ===================*/}
            <Route exact path="/product-list" component={ProductList}/>
            <Route exact path="/add-product" component={AddProduct}/>
            <Route exact path="/edit-product" component={EditProduct}/>
            {/*==================== PACKAGES MANAGEMENT ===================*/}
            <Route exact path="/packages" component={AllPackages}/>
            <Route exact path="/shipment" component={Shipments}/>
            {/*======================= CLIENT 404 ========================*/}
            {/*==================== FEATURE MANAGEMENT ===================*/}
            <Route exact path="/feature-list" component={FeatureList}/>
            <Route exact path="/add-feature" component={AddFeature}/>
            <Route exact path="/edit-feature" component={EditFeature}/>
            {/*==================== STORAGE MANAGEMENT ===================*/}
            {/*==================== STORAGE CATEGORY ===================*/}
            <Route exact path="/storage-category-list" component={StorageCategoryList}/>
            <Route exact path="/add-storage-category" component={AddStorageCategory}/>
            <Route exact path="/edit-storage-category" component={EditStorageCategory}/>
            {/*==================== TRUCK MANAGEMENT ===================*/}
            {/* =================== TRUCK CATEGORY =====================*/}
            <Route exact path="/truck-category-list" component={TruckCategoryList}/>
            <Route exact path="/add-truck-category" component={AddTruckCategory}/>
            <Route exact path="/edit-truck-category" component={EditTruckCategory}/>
            {/*==================== TRUCK TYPE ===================*/}
            <Route exact path="/truck-type-list" component={TruckTypeList}/>
            <Route exact path="/add-truck-type" component={AddTruckType}/>
            <Route exact path="/edit-truck-type" component={EditTruckType}/>
            {/*==================== ADDON MANAGEMENT ===================*/}
            <Route exact path="/add-ons-list" component={AddOnList}/>
            <Route exact path="/add-add-on" component={AddAddOn}/>
            <Route exact path="/edit-add-on" component={EditAddOn}/>
            {/*==================== AMENITY MANAGEMENT ===================*/}
            <Route exact path="/amenities-list" component={AmenityList}/>
            <Route exact path="/add-amenity" component={AddAmenity}/>
            <Route exact path="/edit-amenity" component={EditAmenity}/>
            {/*==================== ADDRESS BOOK ===================*/}
            <Route exact path="/wearhouse-address-list" component={AddressListing}/>
            <Route exact path="/add-address" component={AddAddress}/>
            <Route exact path="/edit-address" component={EditAddress}/>
            <Route exact path="/customer-address-list" component={CustomerAddress}/>
            /*===================== SETTINGS ======================*/
            <Route exact path="/api-setting" component={ApiSetting}/>
            <Route exact path="/site-setting" component={SiteSetting}/>
            /*===================== SETTINGS ======================*/
            <Route exact path="/email-log" component={EmailLogs}/>
            <Route exact path="/view-email" component={ViewEmail}/>
            {/*==================== EMAIL TEMPLAE ==================*/}
            <Route exact path="/email-templates" component={EmailTemplateList}/>
            <Route exact path="/add-email-template" component={AddEmailTemplate}/>
            <Route exact path="/edit-email-template" component={EditEmailTemplate}/>
            <Route exact path="/create-email-template" component={CreateEmailTemplate}/>
            <Route component={Client404} />
        </Switch>
    </Router>
);
ReactDOM.render(routing, document.getElementById('root'));
