/**
 * @author Basit Raza <razabasit88@gmail.com>
 * @link http://be.net/basit_raza Author Website
 * @since 2021-01-18
 */
const env = "production";
const base = env === "dev" ? "localhost:3000" : "dev-api.getspaceshuttle.com";
// const user = env==='dev' ? 'localhost:3002' : 'manage.getspaceshuttle.com';
const user = env === "dev" ? "localhost:3002" : "dev-manage.getspaceshuttle.com";
const consumer = env === "dev" ? "localhost:3002" : "dev-app.getspaceshuttle.com";
// const consumer = env === "dev" ? "localhost:3002" : "localhost:8080";

const baseDomain = `https://${base}/`;
const userDomain = `https://${user}/`;
const consumerDomain = `https://${consumer}/`;

const adminApiDomain = `https://${base}/api/admin/`;
const adminLoginURL = `https://${base}/auth/admin/login`;
const sessionExpireStatus = 440;

//=============== Const var
const storeProvider = "store_provider";
const storeProviderManager = "store_provider_manager";
const storeProviderAccountant = "store_provider_accountant";

//==========
const storageProvider = "storage_provider";
const truckRental = "truck_rental";

const truckProvider = "truck_provider";
const truckProviderManager = "truck_provider_manager";
const truckProviderAccountant = "truck_provider_accountant";

const owner = "Owner";
const manager = "Manager";
const accountant = "Accountant";
const pending = "PENDING",
  inProgress = "INPROGRESS",
  cancelled = "CANCELLED",
  complete = "COMPLETED";

export {
  consumerDomain,
  baseDomain,
  adminApiDomain,
  userDomain,
  adminLoginURL,
  sessionExpireStatus,
  storeProvider,
  storeProviderManager,
  storeProviderAccountant,
  truckProvider,
  truckProviderManager,
  truckProviderAccountant,
  owner,
  manager,
  accountant,
  storageProvider,
  truckRental,
  pending,
  inProgress,
  cancelled,
  complete,
};
