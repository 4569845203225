/**
 * @author Basit Raza <razabasit88@gmail.com>
 * @link http://be.net/basit_raza Author Website
 * @since 2021-01-18
 */
import React from 'react';
import PageHeader from '../../../../components/page-header';
import ChangeTitle from '../../../../components/change_page_title';
import PageFooter from '../../../../components/page-footer';
import SideBar from '../../../../components/side-bar';
import BreadCrumb from '../../../../components/bread-crumb';

import Swal from "sweetalert2";
import $ from "jquery";
import {adminApiCall} from "../../../../utils/ajax_request";
import Notifications, { notify } from 'react-notify-toast';
import {dateTimeFormat, snakeToCamel, toLowerCase} from "../../../../utils/helpers";
import Pagination from "react-js-pagination";

class EmailTemplateList extends React.Component {
	breadcrumbNavigation = [
		{
			title: 'Email Management',
			link: '#'
		},
		{
			title: 'Email Templates List',
			link: '#'
		}
	]

	state = {
		list: [],
		pagination: {
			current_page: '',
			total_pages: '',
			total_records: ''
		}
	}
	componentDidMount() {
		this.listing();
	}

	listing = async () => {
		try{
			$('#loader').show();
			// if(!data){
			// let data = {
			// 	current_page : 1
			// }
			// 	, JSON.stringify(data)
			// }
			let res = await adminApiCall('email/email_template/list','POST' )
			if (!res) {
				notify.show("Something_went_wrong",'error',2000);
				return false;
			}
			if ( res.hasOwnProperty('status') ) {
				if ( res.status === 200 && res.data ) {
					this.setState({list: res.data,
					pagination: {...this.state.pagination , current_page: res.current_page, total_pages: res.total_pages , total_records: res.total_records}})
				} else if ( res.status === 400 ) {
					this.setState({"data" : []})
					notify.show(res.message,'error',2000);
				}
			}
		}
		catch ( e ){
			notify.show("Something_gone_wrong",'error',2000);
		}
		finally {
			$('#loader').hide();
		}
	}
	renderListing =  () => {
		if (this.state.list && this.state.list.length > 0) {
			let listing = [];
			try {
				for (const [index, element] of this.state.list.entries()) {
					listing.push(
							<tr key={element?._id+index}>
								<td>{index + 1}</td>
								<td>{element?.template_name }</td>
								<td>
									{/*<button className="myBtn btn-danger btnSm" onClick={()=>this.hitFire(element?._id)}>
										<i class="fa fa-trash"></i>
									</button>*/}
									<button class="myBtn btn-success btnSm mx-1" onClick={() => this.props.history.push("edit-email-template?id="+element?._id)}>
										<i class="fa fa-edit"></i>
									</button>
									<button class="myBtn btn-info btnSm mx-1" onClick={() => this.props.history.push("create-email-template?id="+element?._id)}>
										<i class="fa fa-cog"></i>
									</button>
								</td>
							</tr>

					)
				}
			} catch (e) {
				notify.show("Something_gone_wrong", 'error', 2000);
			}
			return listing;
		}
	}
	hitFire(id) {
		Swal.fire({
			title: 'Are you sure?',
			text: "You won't be able to revert this!",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Yes, delete it!'
		}).then(async (result) => {
			if (result.isConfirmed){
				let res = await adminApiCall('email/email_template/delete','POST',JSON.stringify({id:id}))
				if (!res) {
					Swal.fire(
						'Error!',
						'Something went wrong Please try again.',
						'error'
					)
					return false;
				}
				if ( res.hasOwnProperty('status') ) {
					if ( res.status === 200) {
						Swal.fire(
							'Deleted!!',
							'Record has been deleted.',
							'success'
						)
						this.listing()
					} else if ( res.status === 400 ) {
						notify.show(res.message,'error',1500);
					}
				}
			}
		})
	}
	render() {
		return (
			/*Content Area*/
			<React.Fragment>

				<ChangeTitle title={'Truck type'} />

				{/*SideBar*/}
				<SideBar />

				{/*Page Header*/}
				<PageHeader />
				<Notifications options={{zIndex: 10000}}/>
				<BreadCrumb navigation={this.breadcrumbNavigation} />

				{/* main content starts here */}

				<section class="pageWrapper">
					<div class="container-fluid">
						<div class="row">
							<div class="col-12">
								<div class="contentCard">
									<div class="cardHeader clearfix">
										<h1>Templates Name</h1>
										{/*<button type={"submit"} className="myBtn"*/}
										{/*		onClick={()=> this.props.history.push('add-email-template')}>+ Create Truck Type </button>*/}
									</div>
									<div class="cardBody">
										<div class="table-responsive customStriped">
											<table class="table">
												<thead>
													<tr>
														<th>#</th>
														<th>Title</th>
														<th>Action</th>

													</tr>
												</thead>
												<tbody>
												{this.renderListing()}
												</tbody>
											</table>

										</div>
										<div class="paginationWrapper clearfix">
											{/*<Pagination*/}
											{/*	activePage={this.state?.pagination?.current_page}*/}
											{/*	itemsCountPerPage={24}*/}
											{/*	totalItemsCount={this.state?.pagination?.total_records}*/}
											{/*	pageRangeDisplayed={this.state?.pagination?.total_pages}*/}
											{/*	onChange={()=>{}}*/}
											{/*	itemClass={"page-item"}*/}
											{/*	linkClass={"page-link"}*/}
											{/*/>*/}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
				<PageFooter />
			</React.Fragment>
		);
	}
}

export default EmailTemplateList;

