/**
 * @author Basit Raza <razabasit88@gmail.com>
 * @link http://be.net/basit_raza Author Website
 * @since 2021-01-18
 */
import React from 'react';
import PageHeader from '../../../components/page-header';
import ChangeTitle from '../../../components/change_page_title';
import PageFooter from '../../../components/page-footer';
import SideBar from '../../../components/side-bar';
import BreadCrumb from '../../../components/bread-crumb';
import $ from "jquery";
import {adminApiCall, adminApiCallForFormData} from "../../../utils/ajax_request";
import Notifications, { notify } from 'react-notify-toast';

class AddCountry extends React.Component {

	breadcrumbNavigation = [
		{
			title: 'Manage Location Features',
			link: '#'
		},
		{
			title: 'Add Location Feature',
			link: '#'
		}
	]
	state = {
		isStatusChecked : false,
	}

	handleSubmit = async (event) => {
		try{

			$('#loader').show();
			event.preventDefault()
			let formData = new FormData(event.target);
			// formData.append("status", this.state.isStatusChecked);
			let res = await adminApiCallForFormData('features/create',formData)
			if (!res) {
				notify.show("Something_went_wrong",'error',2000);
				return false;
			}
			if ( res.hasOwnProperty('status') ) {
				if ( res.status == 200 ) {
					notify.show(res.message,'success',2000);
					setTimeout(()=>{window.location.reload()},2000)
				} else if ( res.status == 400 ) {
					notify.show(res.message,'error',2000);
				}
			}
		}catch (e) {
			console.log(e);
			notify.show("Something_gone_wrong",'error',2000);
		}finally {
			$('#loader').hide();
		}

	}

	render() {
		return (
			/*Content Area*/
			<React.Fragment>

				<ChangeTitle title={'Location Feature'} />

				{/*SideBar*/}
				<SideBar />

				{/*Page Header*/}
				<PageHeader />
				<Notifications options={{zIndex: 10000}}/>
				<BreadCrumb navigation={this.breadcrumbNavigation} />

				{/* main content starts here */}

				<section class="pageWrapper">
					<div class="container-fluid">
						<div class="row">
							<div class="col-12">
								<div class="contentCard">
									<div class="cardHeader clearfix">
										<h1>Add Location Feature</h1>
									</div>
									<div class="cardBody">
										<form onSubmit={this.handleSubmit}>
											<div className="row formWrapper">
												<div className="col-12 col-md-12">
													<div className="form-group inlineField">
														<div className="row align-items-center">
															<div className="col-12 col-md-4 col-lg-3">
																<label>Title</label>
															</div>
															<div className="col-12 col-md-8 col-lg-9">
																<input type="text" name={"title"} placeholder="Please enter a feature title." />
															</div>
														</div>
													</div>
												</div>
												<div className="col-12 col-md-12">
													<div className="form-group inlineField">
														<div className="row align-items-center">
															<div className="col-12 col-md-4 col-lg-3">
																<label>Industry </label>
															</div>
															<div className="col-12 col-md-8 col-lg-9">
																<select name={"type"}>
																	<option value="storage_provider">Storage provider</option>
																	<option value="truck_rental">Truck Rental</option>
																</select>
															</div>
														</div>
													</div>
												</div>
												<div className="col-12 col-md-12">
													<div className="form-group inlineField">
														<div className="row align-items-center">
															<div className="col-12 col-md-4 col-lg-3">
																<label>Icon</label>
															</div>
															<div className="col-12 col-md-8 col-lg-9">
																<div className="inputFile">
																	<input name="icon" type="file" multiple />
																</div>
															</div>
														</div>
													</div>
												</div>
												<div className="col-12 col-md-12">
													<div className="form-group inlineField">
														<div className="row align-items-center">
															<div className="col-12 col-md-4 col-lg-3">
																<label>Description</label>
															</div>
															<div className="col-12 col-md-8 col-lg-9">
																<textarea name="description" rows="5"></textarea>
															</div>
														</div>
													</div>
												</div>
												<div className="col-12 col-md-12">
													<div className="form-group inlineField">
														<div className="row align-items-center">
															<div className="col-12 col-md-4 col-lg-3">
																<label>Status</label>
															</div>
															<div className="col-12 col-md-8 col-lg-9">
																<div className="toggleBtn" >
																	<input type="checkbox" id="togglePrice" checked={"checked"} name={"status"} onChange={(e)=> {this.setState({isStatusChecked: e.target.checked})} } />
																		<label htmlFor="togglePrice">
																			<span className="on">ON</span>
																			<span className="off">OFF</span>
																		</label>
																</div>
															</div>
														</div>
													</div>
												</div>
												<div class="col-12 col-md-12 text-right">
													<button type={"submit"} class="myBtn" onClick={this.register}>Register </button>
												</div>
											</div>
										</form>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>

				<PageFooter />
			</React.Fragment>
		);
	}
}

export default AddCountry;

