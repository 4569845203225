/**
 * @author Basit Raza <razabasit88@gmail.com>
 * @link http://be.net/basit_raza Author Website
 * @since 2021-01-18
 */
import React from "react";
import PageHeader from "../../../../components/page-header";
import ChangeTitle from "../../../../components/change_page_title";
import PageFooter from "../../../../components/page-footer";
import SideBar from "../../../../components/side-bar";
import BreadCrumb from "../../../../components/bread-crumb";
import Swal from "sweetalert2";
import $ from "jquery";
import { adminApiCall } from "../../../../utils/ajax_request";
import Notifications, { notify } from "react-notify-toast";
import {
  dateTimeFormat,
  snakeToCamel,
  toLowerCase,
} from "../../../../utils/helpers";
import Pagination from "react-js-pagination";
import { storageProvider, truckRental } from "../../../../config";

class StorageCategoryList extends React.Component {
  breadcrumbNavigation = [
    {
      title: "Truck Management",
      link: "#",
    },
    {
      title: "Truck Categories List",
      link: "#",
    },
  ];

  state = {
    list: [],
    // ordering: [{ id: "", order: 0 }],
    orderList: [],
    orderingListedZero: [],
    withoutOrderingListed: [],
    duplicates: [],

    back: false,

    // ordering: { order: "" },
    pagination: {
      current_page: "",
      total_pages: "",
      total_records: "",
    },
    no_record_error: "",
  };
  componentDidMount() {
    this.listing();
  }

  listing = async () => {
    try {
      $("#loader").show();
      // if(!data){
      let data = {
        current_page: 1,
        type: truckRental,
      };
      // }
      let res = await adminApiCall(
        "search_filter_category/list",
        "POST",
        JSON.stringify(data)
      );
      if (!res) {
        notify.show("Something_went_wrong", "error", 2000);
        return false;
      }
      if (res.hasOwnProperty("status")) {
        if (res.status === 200 && res.data) {
          this.setState({
            orderList: res.data,

            pagination: {
              ...this.state.pagination,
              current_page: res.current_page,
              total_pages: res.total_pages,
              total_records: res.total_records,
            },
          });
          console.log("abs", this.state.list);
        } else if (res.status === 400) {
          this.setState({ list: [] });
          notify.show(res.message, "error", 2000);
        } else {
          console.log(res);
          this.setState({ no_record_error: "no record found" });
        }
        let fillterStateZero = this.state?.list?.filter((el) => el?.order == 0);

        let fillterState = this.state?.list?.filter((el) => el?.order != 0);
        this.setState({ orderingListedZero: fillterStateZero });
        this.setState({ fillterState: fillterState });
        console.log("fillterStateZero", fillterStateZero);
        console.log("fillterState", fillterState);
      }
    } catch (e) {
      notify.show("Something_gone_wrong", "error", 2000);
    } finally {
      $("#loader").hide();
    }
  };

  // update list updateCatagoryListing
  // chekValidation = () => {
  //   let check = this.state.list?.foreach((e) => e?.order !== e?.order);
  //   console.log("check",check)
  //   if (this.state.list?.map((e) => e?.order !== e?.order)) {
  //     this.updatelisting();
  //   }
  // };

  updatelisting = async () => {
    try {
      $("#loader").show();
      let orderList = this.state.orderList.map((el) => {
        return { _id: el._id, order: el?.order };
      });
      let data = {
        orderList,
      };
      let res = await adminApiCall(
        "search_filter_category/update_order",
        "POST",
        // JSON.stringify(data)
        JSON.stringify(data)
      );
      if (!res) {
        notify.show("Something_went_wrong", "error", 2000);
        return false;
      }
      if (res.hasOwnProperty("status")) {
        if (res.status === 200) {
          console.log("response", res);
          this.listing();
          window.location.reload();

          console.log("abs", this.state.list);
        } else if (res.status === 400) {
          // this.setState({ list: [] });
          notify.show(res.message, "error", 2000);
        } else {
          console.log(res);
          this.setState({ no_record_error: "no record found" });
        }
      }
    } catch (e) {
      notify.show("Something_gone_wrong", "error", 2000);
    } finally {
      $("#loader").hide();
    }
  };
  renderListing = () => {
    let listing = [];
    if (this.state?.list && this.state.list.length > 0) {
      try {
        for (const [index, element] of this.state.list.entries()) {
          listing.push(
            <tr key={element?._id + index}>
              <td>{index + 1}</td>
              <td>
                <input
                  type="number"
                  placeholder="Please Input the order"
                  value={this.state.list?.map((item) => item.order)}
                  onChange={(e) => {
                    const inputValue = e.target.value;

                    this.setState(() => ({
                      orderList: [
                        ...this.state.orderList,
                        { _id: element?._id, order: inputValue },
                      ],
                    }));
                    console.log("onChange e.target.value: ", e.target.value);
                    console.log("onChange e.target.value state: ", this.state);
                  }}
                />
              </td>
              <td>{element?.name}</td>
              <td>{element?.description}</td>
              <td>
                {/*{element?.status === "1" ? <span className={"badge badge-pill badge-success"}> Active </span> : <span className={"badge badge-pill badge-danger"}> Disabled </span> }*/}
                <div className="toggleBtn ">
                  {/*onChange={(e)=> {this.setState({isStatusChecked: e.target.checked})} }*/}
                  <input
                    type="checkbox"
                    id={`togglePrice${index}`}
                    checked={element?.status === "1" ? "checked" : ""}
                    onChange={(e) =>
                      this.changeStatus(e, element?.type, element?._id)
                    }
                    name={"status"}
                  />
                  <label htmlFor={`togglePrice${index}`}>
                    <span className="on">Active</span>
                    <span className="off ">Disable</span>
                  </label>
                </div>
              </td>
              <td>
                <button
                  className="myBtn btn-danger btnSm"
                  onClick={() => this.hitFire(element?._id)}
                >
                  <i class="fa fa-trash"></i>
                </button>
                <button
                  class="myBtn btn-success btnSm mx-1"
                  onClick={() =>
                    this.props.history.push(
                      "edit-truck-category?id=" + element?._id
                    )
                  }
                >
                  <i class="fa fa-edit"></i>
                </button>
                {/*<button class="myBtn btn-primary btnSm mr-1" onClick={this.hitBann}>
										<i class="fa fa-ban"></i>
									</button>*/}
                {/*<button class="myBtn btn-info btnSm" data-toggle="modal"
											data-target="#countryDetail">
										<i class=" fa fa-eye"></i>
									</button>*/}
              </td>
            </tr>
          );
        }
      } catch (e) {
        notify.show("Something_gone_wrong", "error", 2000);
      }
      return listing;
    } else {
      console.log(listing);
      listing.push(
        <tr>
          <td colSpan={"7"}> {this.state?.no_record_error} </td>
        </tr>
      );
      return listing;
    }
  };
  //===== change status of truck type =========
  changeStatus = async (e, type, id) => {
    let confirm = window.confirm(
      `Are you sure you want to ${
        e.target.checked === true ? `Active` : `Disabled`
      } this Storage Category?`
    );
    if (confirm) {
      if (id) {
        try {
          $("#loader").show();
          let res = await adminApiCall(
            "search_filter_category/change_status",
            "POST",
            JSON.stringify({
              search_filter_category_id: id,
              status: e.target.checked,
              type: truckRental,
            })
          );
          if (!res) {
            notify.show("Something_went_wrong", "error", 2000);
            return false;
          }
          if (res.hasOwnProperty("status")) {
            if (res.status === 200) {
              notify.show(res.message, "success", 2000);
              setTimeout(() => {
                this.listing();
              }, 2000);
            } else if (res.status === 400) {
              notify.show(res.message, "error", 2000);
            }
          }
        } catch (e) {
          notify.show("Something_gone_wrong", "error", 2000);
        } finally {
          $("#loader").hide();
        }
      } else {
        notify.show("Please choose admin to verify", "error", 2000);
      }
    }
  };

  hitFire(id) {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let res = await adminApiCall(
          "search_filter_category/delete",
          "POST",
          JSON.stringify({ search_filter_category_id: id, type: truckRental })
        );
        if (!res) {
          Swal.fire(
            "Error!",
            "Something went wrong Please try again.",
            "error"
          );
          return false;
        }
        if (res.hasOwnProperty("status")) {
          if (res.status === 200) {
            Swal.fire("Deleted!!", "Record has been deleted.", "success");
            this.listing();
          } else if (res.status === 400) {
            notify.show(res.message, "error", 1500);
          }
        }
      }
    });
  }

  // handleChangeInput = (e, element) => {
  //   const inputValue = Number(e.target.value);
  //   let tmpOrdering = [...this.state.orderList];
  //   let selectedInput = tmpOrdering.filter((item) => item._id === element._id);
  //   if (selectedInput.length === 0) {
  //     this.setState({
  //       orderList: [
  //         ...this.state.orderList,
  //         { _id: element._id, order: inputValue },
  //       ],
  //     });
  //   } else {
  //     tmpOrdering?.map((item) => {
  //       if (item._id === selectedInput[0]._id) {
  //         item.order = Number(inputValue);
  //       }
  //     });
  //   }

  //   console.log("selectedInput", selectedInput);

  //   console.log("inputValue", inputValue);
  //   console.log("tmpOrdering orderList", this.state.orderList);
  //   console.log("tmpOrdering inn", tmpOrdering);
  // };
  // shahzaib's Solution
  // handleChangeInput = (e, element) => {
  //   const inputValue = Number(e.target.value);
  //   // let tmpOrdering = [...];
  //   // let selectedInput = tmpOrdering.filter((item) => item._id === element._id);
  //   let selectedInput = this.state.list.find((item) => {
  //     console.log(item);
  //     return item.order === inputValue && element.order !== item.order;
  //   });

  //   // id === id
  //   console.log("--->", selectedInput, inputValue);
  //   if (!selectedInput) {
  //     // Error Messgae Show
  //     console.log();
  //     this.setState({
  //       list: this.state.list.map((l) => {
  //         if (element._id === l._id) {
  //           return {
  //             ...l,
  //             order: inputValue,
  //           };
  //         }
  //         return l;
  //       }),
  //     });
  //   } else {
  //     // let foundIndex = tmpOrdering.findIndex((item) => item._id === selectedInput[0]._id);
  //     console.log("foundIndex", selectedInput);
  //     // if (tmpOrdering[foundIndex].order === inputValue) {
  //     //   // Do nothing, the order is already the same
  //     // } else {
  //     //   tmpOrdering[foundIndex].order = inputValue;
  //     //   this.setState({
  //     //     orderList: tmpOrdering,
  //     //   });
  //   }

  //   // console.log("tmpOrdering",tmpOrdering)

  //   // console.log("",inputValue)
  // };
  // handleChangeInput = (e, element) => {
  //   const inputValue = Number(e.target.value);

  //   // save
  //   // console.log(element);
  //   let tmpOrdering = [...this.state.orderList];

  //   let selectedInput = tmpOrdering.filter((item) => item._id === element._id);
  //   let selectedInputs = this.state.list.find((item) => {
  //     console.log(item);
  //     return item.order === inputValue && element.order !== item.order;
  //   });
  //   console.log("selectedInputs", selectedInputs);
  //   if (selectedInput.length === 0) {
  //     // console.log("inside ");
  //     this.setState({
  //       orderList: [
  //         ...this.state.orderList,
  //         { _id: element._id, order: inputValue },
  //       ],
  //     });
  //   } else {
  //     tmpOrdering.map((item) => {
  //       if (item._id === selectedInput[0]._id) {
  //         item.order = Number(inputValue);
  //       }
  //     });
  //   }

  //   console.log("inputValue", inputValue);
  //   console.log("tmpOrdering", this.state.orderList);
  // };
  handleChangeInput = (e, element) => {
    const inputValue = Number(e.target.value);
    let tmpOrdering = [...this.state.orderList];
    let selectedInput = tmpOrdering.filter((item) => item._id === element._id);

    if (selectedInput.length === 0) {
      this.setState({
        orderList: [
          ...this.state.orderList,
          { _id: element._id, order: inputValue },
        ],
      });
    } else {
      tmpOrdering.forEach((item) => {
        if (item._id === selectedInput[0]._id) {
          item.order = Number(inputValue);
        }
      });
    }

    console.log("inputValue", inputValue);
    console.log("tmpOrdering", this.state.orderList);
  };
  handleBurs = (e, element) => {
    // Clear the error state for the specific input element
    this.setState((prevState) => ({
      list: prevState.list.map((item) =>
        item._id === element._id ? { ...item, error: false } : item
      ),
    }));
  };

  handleKeyPress(e) {
    const pattern = /[0-9]/;
    const inputChar = String.fromCharCode(e.charCode);
    if (!pattern.test(inputChar) || parseInt(e.target.value) > 100) {
      e.preventDefault();
    }
  }

  checkValidation = (e) => {
    console.log("pressed", this.state.orderList);
    console.log("pressed");

    const duplicates = [];

    // Check for duplicates in orderList
    for (let i = 0; i < this.state.orderList.length; i++) {
      const currentOrder = this.state.orderList[i].order;
      console.log("duplicates", currentOrder);

      if (
        currentOrder !== 0 &&
        this.state.orderList.some(
          (item, index) => item.order === currentOrder && index !== i
        )
      ) {
        duplicates.push(currentOrder);
      }
    }

    if (duplicates.length > 0) {
      console.log("Duplicates found", duplicates);
      this.setState({ duplicates: duplicates });
      Swal.fire("Duplicatted Numbers Found", "", "error");
    } else {
      const duplicateInList = this.state.list.some((item) =>
        this.isDuplicate(item.order)
      );

      if (duplicateInList) {
        console.log("Duplicate found in list", duplicateInList);
        this.setState({ duplicates: [1] });
      } else {
        this.setState({ duplicates: [] });
        this.updatelisting();
      }
    }
  };
  isDuplicate(order) {
    const orderListDuplicates = this.state.orderList.filter(
      (item) => item.order === order && item.order !== 0
    );

    const listDuplicates = this.state.list.filter(
      (item) => item.order === order && item.order !== 0
    );

    return orderListDuplicates.length + listDuplicates.length > 1;
  }

  hasDuplicates(arr) {
    const orderArray = arr.map((obj) => obj.order);
    console.log(orderArray, "arr");

    for (let i = 0; i < orderArray.length; i++) {
      const currentOrder = orderArray[i];

      if (currentOrder !== 0 && orderArray.includes(currentOrder, i + 1)) {
        return true;
      }
    }

    return false;
  }
  // old one
  // handleChangeInput = (e, element) => {
  //   const inputValue = Number(e.target.value);
  //   let tmpOrdering = [...this.state.orderList];
  //   let selectedInputIndex = tmpOrdering.findIndex(
  //     (item) => item._id === element._id
  //   );
  //   if (selectedInputIndex === -1) {
  //     tmpOrdering.push({ _id: element._id, order: inputValue });
  //   } else {
  //     tmpOrdering[selectedInputIndex].order = inputValue;
  //   }
  //   this.setState({
  //     orderList: tmpOrdering,
  //   });
  //   console.log("inputValue", typeof inputValue);
  //   console.log("-----> ", this.state.orderList);
  //   console.log("tmpOrdering ", tmpOrdering);

  //   // new functionalty
  // };
  render() {
    console.log("list of all ordering ====>> ", this.state.orderList);
    console.log("------->||", this.state.list);
    return (
      /*Content Area*/
      <React.Fragment>
        <ChangeTitle title={"Truck categories"} />
        {/*SideBar*/}
        <SideBar />
        {/*Page Header*/}
        <PageHeader />
        <Notifications options={{ zIndex: 10000 }} />
        <BreadCrumb navigation={this.breadcrumbNavigation} />
        {/* main content starts here */}
        <section class="pageWrapper">
          <div class="container-fluid">
            <div class="row">
              <div class="col-12">
                <div class="contentCard">
                  <div class="cardHeader clearfix">
                    <h1>Truck Categories List</h1>
                    <div className="d-flex">
                      <button
                        type={"submit"}
                        className="myBtn btn-sm"
                        onClick={() =>
                          this.props.history.push("add-truck-category")
                        }
                      >
                        + Create Truck Category{" "}
                      </button>
                      {/* Delete Catagory List  */}

                      <div class="cardHeader clearfix ml-3">
                        <button
                          type={"submit"}
                          className="myBtn btn-sm"
                          onClick={() => this.checkValidation()}
                        >
                          Update Catagory Lsit
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="cardBody">
                    <div class="table-responsive customStriped">
                      <table class="table">
                        <thead>
                          <tr>
                            {/* <th>#</th> */}
                            <th>Ordering</th>
                            <th>Name</th>
                            <th>Description </th>
                            <th> Status </th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        {/* <tbody>{this.renderListing()}</tbody> */}

                        <tbody>
                          {this.state.orderList &&
                          Object.keys(this.state.orderList).length > 0 ? (
                            this.state.orderList
                              ?.slice()
                              ?.sort((a, b) => {
                                // Sort by order value
                                if (a.order === 0) return 1; // Move items with order 0 to the end
                                if (b.order === 0) return -1; // Move items with order 0 to the end
                                return a.order - b.order; // Sort ascending by order value
                              })
                              ?.map((element, index) => {
                                return (
                                  <tr key={element?._id + index}>
                                    {/* <td>{index + 1}</td> */}
                                    {/* {element.order} */}
                                    <td>
                                      <input
                                        type="text"
                                        className={
                                          this.isDuplicate(element?.order)
                                            ? "error"
                                            : ""
                                        }
                                        defaultValue={
                                          element?.order == 0
                                            ? ""
                                            : element?.order
                                        }
                                        // value={element?.order}
                                        onChange={(e) =>
                                          this.handleChangeInput(e, element)
                                        }
                                        onBlur={(e) =>
                                          this.handleBurs(e, element)
                                        }
                                        required
                                        pattern="[0-9]*"
                                        style={{
                                          width: "40px",
                                          minWidth: "0px !important",
                                        }}
                                      />
                                    </td>
                                    <td>{element?.name}</td>
                                    <td>{element?.description}</td>
                                    <td>
                                      {/*{element?.status === "1" ? <span className={"badge badge-pill badge-success"}> Active </span> : <span className={"badge badge-pill badge-danger"}> Disabled </span> }*/}
                                      <div className="toggleBtn ">
                                        {/*onChange={(e)=> {this.setState({isStatusChecked: e.target.checked})} }*/}
                                        <input
                                          type="checkbox"
                                          id={`togglePrice${index}`}
                                          checked={
                                            element?.status === "1"
                                              ? "checked"
                                              : ""
                                          }
                                          onChange={(e) =>
                                            this.changeStatus(
                                              e,
                                              element?.type,
                                              element?._id
                                            )
                                          }
                                          name={"status"}
                                        />
                                        <label htmlFor={`togglePrice${index}`}>
                                          <span className="on">Active</span>
                                          <span className="off ">Disable</span>
                                        </label>
                                      </div>
                                    </td>
                                    <td>
                                      <button
                                        className="myBtn btn-danger btnSm"
                                        onClick={() =>
                                          this.hitFire(element?._id)
                                        }
                                      >
                                        <i class="fa fa-trash"></i>
                                      </button>
                                      <button
                                        class="myBtn btn-success btnSm mx-1"
                                        onClick={() =>
                                          this.props.history.push(
                                            "edit-truck-category?id=" +
                                              element?._id
                                          )
                                        }
                                      >
                                        <i class="fa fa-edit"></i>
                                      </button>
                                      {/*<button class="myBtn btn-primary btnSm mr-1" onClick={this.hitBann}>
                                    <i class="fa fa-ban"></i>
                                  </button>*/}
                                      {/*<button class="myBtn btn-info btnSm" data-toggle="modal"
                                      data-target="#countryDetail">
                                    <i class=" fa fa-eye"></i>
                                  </button>*/}
                                    </td>
                                  </tr>
                                );
                              })
                          ) : (
                            <tr>
                              <td colSpan={"7"}>
                                {" "}
                                {this.state?.no_record_error}{" "}
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                    <div class="paginationWrapper clearfix">
                      <Pagination
                        activePage={this.state?.pagination?.current_page}
                        itemsCountPerPage={24}
                        totalItemsCount={this.state?.pagination?.total_records}
                        pageRangeDisplayed={this.state?.pagination?.total_pages}
                        onChange={() => {}}
                        itemClass={"page-item"}
                        linkClass={"page-link"}
                      />
                      {/*<ul class="pagination">
												<li class="page-item previous disabled">
													<a href="#" class="page-link">
														<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
															viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
															stroke-linecap="round" stroke-linejoin="round"
															class="feather feather-arrow-left">
															<line x1="19" y1="12" x2="5" y2="12"></line>
															<polyline points="12 19 5 12 12 5"></polyline>
														</svg>
													</a>
												</li>
												<li class="page-item active"><a href="#" class="page-link">1</a></li>
												<li class="page-item "><a href="#" class="page-link">2</a></li>
												<li class="page-item next" id="style-3_next">
													<a href="#" class="page-link">
														<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
															viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
															stroke-linecap="round" stroke-linejoin="round"
															class="feather feather-arrow-right">
															<line x1="5" y1="12" x2="19" y2="12"></line>
															<polyline points="12 5 19 12 12 19"></polyline>
														</svg>
													</a>
												</li>
											</ul>*/}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <div
          class="modal fade"
          id="countryDetail"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-xl" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">
                  Country Details
                </h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <svg
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-x"
                  >
                    <line x1="18" y1="6" x2="6" y2="18"></line>
                    <line x1="6" y1="6" x2="18" y2="18"></line>
                  </svg>
                </button>
              </div>
              <div class="modal-body">
                <div className="row dataOnly">
                  <div className="col-12 col-md-6">
                    <div className="formData">
                      <label>Country ID</label>
                      <div className="infoText">Kenya</div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                    <div className="formData">
                      <label>Added Date</label>
                      <div className="infoText">20 May, 2021</div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                    <div className="formData">
                      <label>Currency</label>
                      <div className="infoText">KSH</div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                    <div className="formData">
                      <label>Rate</label>
                      <div className="infoText">KE</div>
                    </div>
                  </div>

                  <div className="col-12">
                    <hr />
                    <div className="">
                      <div className="cardHeader">
                        <h1>Shipping Charges Details</h1>
                      </div>
                      <div className="table-responsive customStriped">
                        <table class="table">
                          <thead>
                            <tr>
                              <th scope="col">Name</th>
                              <th scope="col">Rate</th>
                              <th scope="col">Of</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>US Sales Tax</td>
                              <td>8%</td>
                              <td>Product Price</td>
                            </tr>
                            <tr>
                              <td>Processing Fee</td>
                              <td>5%</td>
                              <td>Product Price + US Sales Tax</td>
                            </tr>
                            <tr>
                              <td>Shipping Cost*</td>
                              <td>$90</td>
                              <td>Per KG </td>
                            </tr>
                            <tr>
                              <td>Kenya Sales Tax – VAT</td>
                              <td>5%</td>
                              <td>Percentage </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button class="myBtn btnSm btn-danger">Delete</button>
                <button
                  type="button"
                  class="myBtn btn-success btnSm btn-info"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={(e) => {
                    this.props.history.push("edit-feature");
                  }}
                >
                  Edit
                </button>
              </div>
            </div>
          </div>
        </div>
        <PageFooter />
      </React.Fragment>
    );
  }
}

export default StorageCategoryList;
