/**
 * @author Basit Raza <razabasit88@gmail.com>
 * @link http://be.net/basit_raza Author Website
 * @since 2021-01-18
 */
import React from 'react';
import PageHeader from '../../../components/page-header';
import ChangeTitle from '../../../components/change_page_title';
import PageFooter from '../../../components/page-footer';
import SideBar from '../../../components/side-bar';
import BreadCrumb from '../../../components/bread-crumb';

class ProductList extends React.Component {

	state = {
		productId : '12345',
	};


	breadcrumbNavigation = [
		{
			title: 'Product List',
			link: '#'
		},
		{
			title: 'Add Product',
			link: '#'
		}
	]

	render() {
		return (
			/*Content Area*/
			<React.Fragment>

				<ChangeTitle title={'Home'} />

				{/*SideBar*/}
				<SideBar />

				{/*Page Header*/}
				<PageHeader />

				<BreadCrumb navigation={this.breadcrumbNavigation} />

				{/* main content starts here */}

				<section class="pageWrapper">
					<div class="container-fluid">
						<div class="row">
							<div class="col-12">
								<div class="contentCard">
									<div class="cardHeader clearfix">
										<h1>Filters
											<svg id="Capa_1" enable-background="new 0 0 20 20" height="20" viewBox="0 0 512 512"
												width="20" xmlns="http://www.w3.org/2000/svg">
												<g>
													<path d="m90.5 0h-30v362h-30v90h30v60h30v-60h30v-90h-30zm0 422h-30v-30h30z" />
													<path
														d="m481.5 362h-30v-362h-30v362h-30v90h30v60h30v-60h30zm-30 60h-30v-30h30z" />
													<path
														d="m331.5 0h-30v222h-30v90h30v200h30v-200h30v-90h-30zm0 282h-30v-30h30z" />
													<path
														d="m210.5 0h-30v53.652h-30v90h30v368.348h30v-368.348h30v-90h-30zm0 113.652h-30v-30h30z" />
												</g>
											</svg>
										</h1>
										<span class="toogleCard open">
											<i class="fa fa-angle-down"></i>
										</span>
									</div>
									<div class="cardBody">
										<div class="row formWrapper align-items-center">
											<div class="col-12 col-md-12">
												<div class="form-group inlineField">
													<div class="row align-items-center">
														<div class="col-12 col-md-4 col-lg-3">
															<label>Search word:</label>
														</div>
														<div class="col-12 col-md-8 col-lg-9">
															<div class="searchWrapper">
																<select>
																	<option value="">Product Name</option>
																	<option value="">Product ID</option>
																</select>
																<input type="text" placeholder="Please enter a search word." />
															</div>
														</div>
													</div>
												</div>
											</div>
											<div class="col-12 col-md-12">
												<div class="form-group inlineField">
													<div class="row align-items-center">
														<div class="col-12 col-md-4 col-lg-3">
															<label>In Stock:</label>
														</div>
														<div class="col-12 col-md-8 col-lg-9">
															<div class="row">
																<div class="col">
																	<input class="customCheck" type="radio" id="insotck" name="sotck"
																		value="insotck" />
																	<label for="insotck"> Yes </label>
																</div>
																<div class="col">
																	<input class="customCheck" type="radio" id="outstock"
																		name="sotck" value="creditCard" />
																	<label for="outstock"> No</label>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
											<div class="col-12 col-md-12 text-right">
												<button class="myBtn">
													Search <i class="fa fa-search"></i>
												</button>
											</div>


										</div>
									</div>
								</div>
							</div>
							<div class="col-12">
								<div class="contentCard">
									<div class="cardHeader clearfix">
										<h1>Product List</h1>
									</div>
									<div class="cardBody">
										<div className="tableSorting">
											<a className="active" href="javascript:void(0)">Date<i className="fa fa-caret-up"></i></a>
											<a href="javascript:void(0)">Product Name <i className="fa fa-caret-up"></i></a>
											<a href="javascript:void(0)">Price <i className="fa fa-caret-up"></i></a>
										</div>
										<div class="table-responsive customStriped">
											<table class="table">
												<thead>
													<tr>
														<th>Product Name</th>
														<th>Image</th>
														<th>Product in stock</th>
														<th>Price</th>
														<th>Registration Date</th>
														<th>Action</th>

													</tr>
												</thead>
												<tbody>
													<tr>
														<td>Smart watch</td>
														<td><img class="img-80" src="/assets/images/placeholder.jpg" alt="alt-img" /></td>
														<td>80</td>
														<td>$84</td>
														<td>20 May, 2021</td>
														<td>
															<button class="myBtn btn-danger btnSm"><i class="fa fa-trash"></i></button>
															<button class="myBtn btn-success btnSm mx-1" onClick={(e) => { this.props.history.push('edit-product') }}><i class="fa fa-edit"></i></button>
															<button class="myBtn btn-info btnSm" data-toggle="modal" data-target="#productDetails"><i class="fa fa-eye"></i></button>
														</td>
													</tr>

												</tbody>
											</table>

										</div>
										<div class="paginationWrapper clearfix">
											<ul class="pagination">
												<li class="page-item previous disabled">
													<a href="#" class="page-link">
														<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
															viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
															stroke-linecap="round" stroke-linejoin="round"
															class="feather feather-arrow-left">
															<line x1="19" y1="12" x2="5" y2="12"></line>
															<polyline points="12 19 5 12 12 5"></polyline>
														</svg>
													</a>
												</li>
												<li class="page-item active"><a href="#" class="page-link">1</a></li>
												<li class="page-item "><a href="#" class="page-link">2</a></li>
												<li class="page-item next" id="style-3_next">
													<a href="#" class="page-link">
														<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
															viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
															stroke-linecap="round" stroke-linejoin="round"
															class="feather feather-arrow-right">
															<line x1="5" y1="12" x2="19" y2="12"></line>
															<polyline points="12 5 19 12 12 19"></polyline>
														</svg>
													</a>
												</li>
											</ul>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>

				<div class="modal fade" id="productDetails" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
					<div class="modal-dialog modal-xl" role="document">
						<div class="modal-content">
							<div class="modal-header">
								<h5 class="modal-title" id="exampleModalLabel">Product Details</h5>
								<button type="button" class="close" data-dismiss="modal" aria-label="Close">
									<svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
								</button>
							</div>
							<div class="modal-body">
								<div className="row dataOnly">
									<div className="col-12 col-md-6">
										<div className="formData">
											<label>Product ID</label>
											<div className="infoText">#15548212</div>
										</div>
									</div>
									<div className="col-12 col-md-6">
										<div className="formData">
											<label>Product Title</label>
											<div className="infoText">Smart Watch</div>
										</div>
									</div>
									<div className="col-12 col-md-6">
										<div className="formData">
											<label>Creation date</label>
											<div className="infoText">2021.08.10</div>
										</div>
									</div>
									<div className="col-12 col-md-6">
										<div className="formData">
											<label>Product In Stock</label>
											<div className="infoText">80</div>
										</div>
									</div>
									<div className="col-12 col-md-6">
										<div className="formData">
											<label>Price</label>
											<div className="infoText">$84</div>
										</div>
									</div>
									<div className="col-12 col-md-6">
										<div className="formData">
											<label>Brand</label>
											<div className="infoText">Apple</div>
										</div>
									</div>
									<div className="col-12 col-md-6">
										<div className="formData">
											<label>SKU</label>
											<div className="infoText">5821</div>
										</div>
									</div>
									<div className="col-12 col-md-6">
										<div className="formData">
											<label>Dimensions(in)</label>
											<div className="infoText">18 x 20 x 25</div>
										</div>
									</div>
									<div className="col-12 col-md-12">
										<div className="formData">
											<label>Product Images</label>
											<div className="infoText">
												<img class="img-80 mr-1" src="/assets/images/placeholder.jpg" alt="alt-img" />
												<img class="img-80 mr-1" src="/assets/images/placeholder.jpg" alt="alt-img" />
												<img class="img-80 mr-1" src="/assets/images/placeholder.jpg" alt="alt-img" />
											</div>
										</div>
									</div>
								</div>


							</div>
							<div class="modal-footer">
								<button class="myBtn btnSm btn-danger">Deactive Product</button>
								<button class="myBtn btnSm btn-danger">Delete</button>
								<button type="button" class="myBtn btn-success btnSm btn-info" data-dismiss="modal" aria-label="Close" onClick={(e) => { this.props.history.push('edit-product') }}>Edit</button>
							</div>
						</div>
					</div>
				</div>
				<PageFooter />
			</React.Fragment>
		);
	}
}

export default ProductList;

