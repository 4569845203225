import React from "react";
import PageHeader from "../../../components/page-header";
import ChangeTitle from "../../../components/change_page_title";
import PageFooter from "../../../components/page-footer";
import SideBar from "../../../components/side-bar";
import BreadCrumb from "../../../components/bread-crumb";
import Notifications, { notify } from "react-notify-toast";
import DatePicker from "react-datepicker";
import $ from "jquery";
import { adminApiCall, adminRegister } from "../../../utils/ajax_request";
import { Modal, Button, Form } from "react-bootstrap";

import {
  toLowerCase,
  snakeToCamel,
  dateTimeFormat,
} from "../../../utils/helpers";
// you will also need the css that comes with bootstrap-daterangepicker
import "bootstrap-daterangepicker/daterangepicker.css";
import "react-datepicker/dist/react-datepicker.css";

import Swal from "sweetalert2";
import Pagination from "react-js-pagination";

const password = require("secure-random-password");
class ReferFriend extends React.Component {
  breadcrumbNavigation = [
    {
      title: "Manage User",
      link: "#",
    },
    {
      title: "Store Provider Listing",
      link: "#",
    },
  ];
  state = {
    payDate: null,
    transactionId: "",
    referId: "",
    list: [],
    changePassword: {
      newPassword: "",
      errorMsg: "",
      email: "",
      role: "",
      user_id: "",
    },
    filterValues: {
      user_name: "",
    },
    pagination: {
      current_page: 1,
      total_pages: "",
      total_records: "",
      itemsCountPerPage: 24,
    },
  };
  componentDidMount() {
    this.listing();
  }

  listing = async (page) => {
    try {
      // console.log(queryString)
      $("#loader").show();
      // if(!data){
      let data = {
        current_page: page ? page : this.state?.pagination?.current_page,
        // ...this.state?.filterValues?.user_name && { user_name: this.state?.filterValues?.user_name },
        // ...this.state?.filterValues?.role && { role: this.state?.filterValues?.role }
      };
      // }
      let res = await adminApiCall(
        "referals/list",
        "POST",
        JSON.stringify(data)
      );
      if (!res) {
        notify.show("Something_went_wrong", "error", 2000);
        return false;
      }
      if (res.hasOwnProperty("status")) {
        if (res.status === 200 && res.data) {
          this.setState({
            list: res.data,
            pagination: {
              ...this.state.pagination,
              current_page: res.current_page,
              total_pages: res.total_pages,
              total_records: res.total_records,
            },
          });
          console.log(this.state.list);
        } else if (res.status === 400) {
          this.setState({ list: [] });
          notify.show(res.message, "error", 2000);
        } else {
          this.setState({ list: [] });
        }
      }
    } catch (e) {
      notify.show("Something_gone_wrong", "error", 2000);
    } finally {
      $("#loader").hide();
    }
  };

  handlePayClick = async () => {
    try {
      $("#loader").show();

      let data = {
        referal_id: this.state?.referId,
        transaction_id: this.state?.transactionId,
        date: this.state?.payDate,
      };

      let res = await adminApiCall(
        "referals/pay",
        "POST",
        JSON.stringify(data)
      );

      if (!res) {
        notify.show("Something_went_wrong", "error", 2000);
        return false;
      }

      if (res.hasOwnProperty("status")) {
        if (res.status === 200) {
          // Handle success
          notify.show(res?.message, "success", 2000);
          // Swal.fire("Payment Succesfully Done.", "success");

          this.listing();
        } else {
          notify.show("Payment failed", "error", 2000);
        }
      }
    } catch (e) {
      notify.show("Something_gone_wrong", "error", 2000);
    } finally {
      $("#loader").hide();
    }
  };

  handleKeyPress = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    const regex = /^[0-9]+$/;

    if (!regex.test(keyValue)) {
      event.preventDefault();
    }
  };

  renderListing = () => {
    let listing = [];
    if (this.state.list && this.state.list.length > 0) {
      let listing = [];
      try {
        for (const [index, element] of this.state.list.entries()) {
          listing.push(
            <>
              <tr>
                <td>{index + 1}</td>
                <td>{element?.order_info?.order_id}</td>
                <td>{"$" + element?.order_info?.admin_fee}</td>
                <td>{element?.commission ? element?.commission + "%" : ""}</td>

                <td>
                  {element?.referrer_info?.first_name +
                    " " +
                    element?.referrer_info?.last_name}{" "}
                  {element?.last_name && element?.last_name !== "false"
                    ? element?.last_name
                    : ""}
                </td>

                <td>{element?.referrer_info?.email}</td>
                <td>{element?.referrer_info?.phone}</td>
                <td>
                  {element?.created_at && dateTimeFormat(element?.created_at)}
                </td>

                <td className="d-flex" style={{ flexDirection: "column" }}>
               {element?.transaction_id &&  <span>ID: {element?.transaction_id}</span>}  
                {element?.paid_date &&  <span>
                    Date:{" "}
                    {element?.paid_date && dateTimeFormat(element?.paid_date)}
                  </span>}
                </td>
                <td>
                  {" "}
                  <span
                    className={`badge badge-pill badge-info ${
                      element?.status == "AWAITING PAYOUT"
                        ? "await-color"
                        : "paid-color"
                    }`}
                    // style={
                    //   element?.status == "AWAITING PAYOUT" && {
                    //     background: "red",
                    //   }
                    // }
                  >
                    {" "}
                    {element?.status}{" "}
                  </span>{" "}
                </td>
                <td>{"$" + element?.ammount}</td>

                <td>
                  <button
                    data-toggle="modal"
                    className={`myBtn  btnSm ml-1 ${
                      element?.status == "AWAITING PAYOUT"
                        ? "myBtn"
                        : "paid-color-gray"
                    }`}
                    // onClick={() => this.handlePayClick(element?._id)}
                    onClick={() => {
                      this.hitBann();
                      this.setState({
                        referId: element?._id,
                      });
                    }}
                    disabled={element?.status == "PAID"}
                  >
                    Pay{" "}
                  </button>
                </td>
              </tr>
            </>
          );
        }
      } catch (e) {
        notify.show("Something_gone_wrong", "error", 2000);
      }
      return listing;
    } else {
      listing.push(
        <tr>
          <td colSpan={10}>No Record found</td>
        </tr>
      );
      return listing;
    }
  };

  // notify toaste start here
  notify() {
    notify.show("res.message", "error", 2000);
  }

  hitFire = async () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      // let resp = await  adminApiCall('store_provider/list','POST' , JSON.stringify(data));
      if (result.isConfirmed) {
        Swal.fire("Deleted!", "Your file has been deleted.", "success");
      }
    });
  };
  // hitBann() {

  //   Swal.fire({
  //     // title: "Please Select Date and Transection Id",
  //     text: "Please Select Date and Transection Id",
  //     icon: "warning",
  //     showCancelButton: true,
  //     confirmButtonColor: "#3085d6",
  //     cancelButtonColor: "#d33",
  //     confirmButtonText: "Yes!",
  //   }).then((result) => {
  //     if (result.isConfirmed) {
  //       Swal.fire("Banned!", "This user is banned now.", "success");
  //     }
  //   });
  // }

  // hitBann = () => {
  //   const inputHtml = `
  //     <input
  //       type="text"
  //       id="transactionId"
  //       class="swal2-input"
  //       placeholder="Transaction ID"
  //     />
  //     <br>
  //     <label for="datePicker" style="margin-top: 10px;">Select Date:</label>
  //   `;

  //   Swal.fire({
  //     html: inputHtml,
  //     // icon: "warning",
  //     showCancelButton: true,
  //     confirmButtonColor: "#3085d6",
  //     cancelButtonColor: "#d33",
  //     confirmButtonText: "Yes!",
  //     preConfirm: () => {
  //       return {
  //         transactionId: document.getElementById("transactionId").value,
  //         selectedDate: this.state.selectedDate,
  //       };
  //     },
  //   }).then((result) => {
  //     if (result.isConfirmed) {
  //       const { transactionId, selectedDate } = result.value;
  //       if (transactionId && selectedDate) {
  //         Swal.fire("Banned!", "This user is banned now.", "success");
  //       } else {
  //         Swal.fire("Error", "Please fill in all fields.", "error");
  //       }
  //     }
  //   });

  //   // Render the date picker inside Swal
  //   const datePickerInput = document.getElementById("datePicker");
  //   if (datePickerInput) {
  //     const datePicker = (
  //       <DatePicker
  //         selected={this.state.selectedDate}
  //         onChange={(date) => this.setState({ selectedDate: date })}
  //         dateFormat="MM/dd/yyyy"
  //         className="swal2-input"
  //       />
  //     );
  //     datePickerInput.parentNode.replaceChild(datePicker, datePickerInput);
  //   }
  // };

  hitBann = () => {
    this.setState({ showModal: true });
  };

  handleConfirm = () => {
    const { transactionId, payDate } = this.state;
    if (transactionId && payDate) {
      this.setState({ showModal: false });
      this.handlePayClick();
    } else {
      Swal.fire("Error", "Please fill in all fields.", "error");
    }
  };

  handleCancel = () => {
    this.setState({ showModal: false });
  };
  //======= update password ==========
  handleGeneratePassword = async () => {
    const newPassword = password.randomPassword({
      characters:
        password.lower + password.upper + password.digits + password.symbols,
    });
    this.setState({
      changePassword: {
        ...this.state?.changePassword,
        newPassword: newPassword,
        errorMsg: "",
      },
    });
  };

  handleChangePassword = async () => {
    if (
      this.state?.changePassword?.newPassword &&
      this.state?.changePassword?.email &&
      this.state?.changePassword?.role &&
      this.state?.changePassword?.user_id
    ) {
      this.setState({
        changePassword: { ...this.state?.changePassword, errorMsg: "" },
      });
      try {
        $("#loader").show();
        let jsonData = {
          email: this.state?.changePassword?.email,
          new_password: this.state?.changePassword?.newPassword,
          user_id: this.state?.changePassword?.user_id,
          role: this.state?.changePassword?.role,
        };
        let res = await adminRegister(
          "auth/admin/reset_user_password",
          JSON.stringify(jsonData)
        );
        if (!res) {
          notify.show("Something_went_wrong", "error", 2000);
          return false;
        }
        if (res.hasOwnProperty("status")) {
          if (res.status === 200) {
            notify.show(res.message, "success", 2000);
            setTimeout(() => {
              window.location.reload();
            }, 2000);
          } else if (res.status === 400) {
            notify.show(res.message, "error", 2000);
          }
        }
      } catch (e) {
        notify.show("Something_gone_wrong", "error", 2000);
      } finally {
        $("#loader").hide();
      }
    } else {
      this.setState({
        changePassword: {
          ...this.state?.changePassword,
          errorMsg: "Please generate new password",
        },
      });
    }
  };
  searchHandle = () => {
    this.listing();
  };

  handlePageChange(pageNumber) {
    this.setState({
      pagination: { ...this.state.pagination, current_page: pageNumber },
    });
    this.listing(pageNumber);
  }

  render() {
    const { showModal, payDate, transactionId } = this.state;

    console.log("transaction_id", this.state?.transactionId);
    return (
      /*Content Area*/
      <React.Fragment>
        <ChangeTitle title={"Manage User"} />

        {/*SideBar*/}
        <SideBar />

        {/*Page Header*/}
        <PageHeader />

        <Notifications options={{ zIndex: 10000 }} />

        <BreadCrumb navigation={this.breadcrumbNavigation} />

        {/* main content starts here */}
        <section class="pageWrapper">
          <div class="container-fluid">
            <div class="row">
              {/* <div class="col-12">
								<div class="contentCard">
									 <div class="cardHeader clearfix">
										<svg className="mr-1" enable-background="new 0 0 20 20" height="20" viewBox="0 0 512 512"
											width="20" xmlns="http://www.w3.org/2000/svg">
											<g>
												<path d="m90.5 0h-30v362h-30v90h30v60h30v-60h30v-90h-30zm0 422h-30v-30h30z" />
												<path
													d="m481.5 362h-30v-362h-30v362h-30v90h30v60h30v-60h30zm-30 60h-30v-30h30z" />
												<path
													d="m331.5 0h-30v222h-30v90h30v200h30v-200h30v-90h-30zm0 282h-30v-30h30z" />
												<path
													d="m210.5 0h-30v53.652h-30v90h30v368.348h30v-368.348h30v-90h-30zm0 113.652h-30v-30h30z" />
											</g>
										</svg>
										<h1>Filters</h1>
										<span class="toogleCard open">
											<i class="fa fa-angle-down"></i>
										</span>
									</div>

									<div class="cardBody">
										<div class="row formWrapper">
											<div class="col-12 col-md-12">
												<div class="form-group inlineField">
													<div class="row align-items-center">
														<div class="col-12 col-md-4 col-lg-3">
															<label>User Name:</label>
														</div>
														<div class="col-12 col-md-8 col-lg-9">
															<input type="text"
																value={this.state?.filterValues?.user_name}
																onChange={(e) => this.setState({ filterValues: { ...this.state?.filterValues, user_name: e.target.value } })} />
														</div>
													</div>
												</div>
											</div>
											<div class="col-12 col-md-12 text-right">
												<button className="myBtn"
													onClick={() => this.setState({
														filterValues: {
															...this.state?.filterValues,
															user_name: ''
														}
													}, () => {
														this.searchHandle()
													})}>
													Reset <i className="fa fa-refresh"></i>
												</button>
												<button className="ml-3 myBtn"
													onClick={this.searchHandle}>
													Search <i className="fa fa-search"></i>
												</button>
											</div>


										</div>
									</div> 
								</div>
							</div> */}
              <div class="col-12">
                <div class="contentCard">
                  <div class="cardHeader">
                    <h1>All Referrals List</h1>
                  </div>
                  <div class="cardBody">
                    <div class="table-responsive customStriped">
                      <table class="table">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Order No</th>
                            <th>Deposit</th>
                            <th>Commission</th>
                            <th>Referal Name</th>
                            <th>Email</th>
                            <th>Phone No</th>
                            <th>Create Date</th>
                            <th>Transection Details</th>
                            <th>Status</th>
                            {/*<th>Status</th>*/}
                            {/* <th>Verified</th> */}
                            <th>Final Payout</th>

                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>{this.renderListing()}</tbody>
                      </table>
                    </div>
                    <div class="paginationWrapper clearfix">
                      <div className="paginationWrapper clearfix">
                        <Pagination
                          activePage={this.state?.pagination?.current_page}
                          itemsCountPerPage={
                            this.state?.pagination?.itemsCountPerPage
                          }
                          totalItemsCount={
                            this.state?.pagination?.total_records
                          }
                          pageRangeDisplayed={
                            this.state?.pagination?.total_pages
                          }
                          onChange={this.handlePageChange.bind(this)}
                          itemClass={"page-item"}
                          linkClass={"page-link"}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div
          className="modal fade"
          id="changeInfo"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-xl" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Reset Password
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <svg
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    className="feather feather-x"
                  >
                    <line x1="18" y1="6" x2="6" y2="18"></line>
                    <line x1="6" y1="6" x2="18" y2="18"></line>
                  </svg>
                </button>
              </div>
              <div className="modal-body">
                <div className="row formWrapper">
                  <div className="col-12 col-md-6">
                    <div className="form-group">
                      <label>New Password:</label>
                      <input
                        type="text"
                        id={"new_password"}
                        value={this.state.changePassword?.newPassword}
                      />
                    </div>
                  </div>
                  <div className="col-12 col-md-6 generate-password-btn">
                    <button
                      className="myBtn btn-danger"
                      onClick={this.handleGeneratePassword}
                    >
                      {" "}
                      Generate Password
                    </button>
                  </div>
                </div>
                <div className={"row formWrapper"}>
                  <div className="col-12 col-md-6">
                    <span className={"error_msg"}>
                      {this.state?.changePassword?.errorMsg}
                    </span>
                  </div>
                </div>
              </div>

              <div className="modal-footer">
                <div className="tableDetails text-center">
                  <button
                    className="myBtn btnSm btn-simple"
                    data-dismiss="modal"
                    onClick={() =>
                      this.setState({
                        changePassword: {
                          ...this.state?.changePassword,
                          newPassword: "",
                        },
                      })
                    }
                  >
                    Cancel
                  </button>
                  <button
                    className="myBtn btnSm btn-success ml-2"
                    onClick={this.handleChangePassword}
                  >
                    Reset
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <Modal show={showModal} onHide={this.handleCancel}>
            <Modal.Header closeButton>
              <Modal.Title style={{ color: "white" }}>Pay</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form.Group>
                <Form.Label>Transaction ID:</Form.Label>
                <Form.Control
                  type="text"
                  value={transactionId}
                  onChange={(e) =>
                    this.setState({ transactionId: e.target.value })
                  }
                />
              </Form.Group>

              <Form.Group
                className="d-flex"
                style={{ flexDirection: "column" }}
              >
                <Form.Label>Select Date:</Form.Label>
                <DatePicker
                  selected={payDate}
                  onChange={(date) => this.setState({ payDate: date })}
                  dateFormat="MM/dd/yyyy"
                  className="form-control"
                />
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.handleCancel}>
                Cancel
              </Button>
              <Button variant="primary" onClick={this.handleConfirm}>
                Confirm
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
        <PageFooter />
      </React.Fragment>
    );
  }
}

export default ReferFriend;
