/**
 * @author Basit Raza <razabasit88@gmail.com>
 * @link http://be.net/basit_raza Author Website
 * @since 2021-01-18
 */
import React from 'react';
import PageHeader from '../../components/page-header';
import ChangeTitle from '../../components/change_page_title';
import PageFooter from '../../components/page-footer';
import SideBar from '../../components/side-bar';
import BreadCrumb from '../../components/bread-crumb';

class Shipments extends React.Component {
	breadcrumbNavigation = [
		{
			title: 'Shipments',
			link: '#'
		},
		{
			title: 'Shipments',
			link: '#'
		}
	]

	render() {
		return (
			/*Content Area*/
			<React.Fragment>

				<ChangeTitle title={'Shipments'} />

				{/*SideBar*/}
				<SideBar />

				{/*Page Header*/}
				<PageHeader />

				<BreadCrumb navigation={this.breadcrumbNavigation} />

				{/* main content starts here */}

				<section class="pageWrapper">
					<div class="container-fluid">
						<div class="row">
							<div class="col-12">
								<div class="contentCard">
									<div class="cardHeader clearfix">
										<h1>Filters
											<svg id="Capa_1" enable-background="new 0 0 20 20" height="20" viewBox="0 0 512 512"
												width="20" xmlns="http://www.w3.org/2000/svg">
												<g>
													<path d="m90.5 0h-30v362h-30v90h30v60h30v-60h30v-90h-30zm0 422h-30v-30h30z" />
													<path
														d="m481.5 362h-30v-362h-30v362h-30v90h30v60h30v-60h30zm-30 60h-30v-30h30z" />
													<path
														d="m331.5 0h-30v222h-30v90h30v200h30v-200h30v-90h-30zm0 282h-30v-30h30z" />
													<path
														d="m210.5 0h-30v53.652h-30v90h30v368.348h30v-368.348h30v-90h-30zm0 113.652h-30v-30h30z" />
												</g>
											</svg>
										</h1>
										<span class="toogleCard open">
											<i class="fa fa-angle-down"></i>
										</span>
									</div>
									<div class="cardBody">
										<div class="row formWrapper align-items-center">
											<div class="col-12 col-md-12">
												<div class="form-group inlineField">
													<div class="row align-items-center">
														<div class="col-12 col-md-4 col-lg-3">
															<label>Shipment ID:</label>
														</div>
														<div class="col-12 col-md-8 col-lg-9">
															<input type="text" placeholder="Shipment ID." />
														</div>
													</div>
												</div>
											</div>
											<div class="col-12 col-md-12">
												<div class="form-group inlineField">
													<div class="row align-items-center">
														<div class="col-12 col-md-4 col-lg-3">
															<label>Customer Name:</label>
														</div>
														<div class="col-12 col-md-8 col-lg-9">
															<input type="text" placeholder="Search for Shipment with customer name." />
														</div>
													</div>
												</div>
											</div>
											<div class="col-12 col-md-12">
												<div class="form-group inlineField">
													<div class="row align-items-center">
														<div class="col-12 col-md-4 col-lg-3">
															<label>Shipment Status:</label>
														</div>
														<div class="col-12 col-md-8 col-lg-9">
															<div class="row">
																<div class="col">
																	<input class="customCheck" type="checkbox" id="shippend" name="shippend"
																		value="shippend" />
																	<label for="shippend"> Shipped </label>
																</div>
																<div class="col">
																	<input class="customCheck" type="checkbox" id="ready"
																		name="ready" value="creditCard" />
																	<label for="ready"> Ready for Pick Up</label>
																</div>
																<div class="col">
																	<input class="customCheck" type="checkbox" id="delivered"
																		name="delivered" value="delivered" />
																	<label for="delivered"> Delivered</label>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
											<div class="col-12 col-md-12">
												<div class="form-group inlineField">
													<div class="row align-items-center">
														<div class="col-12 col-md-4 col-lg-3">
															<label>Shipment Type:</label>
														</div>
														<div class="col-12 col-md-8 col-lg-9">
															<div class="row">
																<div class="col">
																	<input class="customCheck" type="radio" id="consolidate" name="pkgType"
																		value="consolidate" />
																	<label for="consolidate"> Consolidation </label>
																</div>
																<div class="col">
																	<input class="customCheck" type="radio" id="single"
																		name="pkgType" value="single" />
																	<label for="single"> Single Package</label>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
											<div class="col-12 col-md-12 text-right">
												<button class="myBtn">
													Search <i class="fa fa-search"></i>
												</button>
											</div>


										</div>
									</div>
								</div>
							</div>
							<div class="col-12">
								<div class="contentCard">
									<div class="cardHeader clearfix">
										<h1>Shipment List</h1>
									</div>
									<div class="cardBody">
										<div class="table-responsive customStriped">
											<table class="table">
												<thead>
													<tr>
														<th>Shipment ID</th>
														<th>Date</th>
														<th>Customer</th>
														<th>Origin</th>
														<th>Destination</th>
														<th>Status</th>
														<th>Shipment Type</th>
														<th>Action</th>
													</tr>
												</thead>
												<tbody>
													<tr>
														<td>SHP-123521</td>
														<td>20 May, 2021</td>
														<td>Jhon Doe</td>
														<td>USA</td>
														<td>Kenya</td>
														<td>Shipped</td>
														<td>Consolidate</td>
														<td>
															<button class="myBtn btn-info btnSm" data-toggle="modal" data-target="#shipmentDetails">
																<i class="fa fa-eye"></i></button>
														</td>
													</tr>

												</tbody>
											</table>

										</div>
										<div class="paginationWrapper clearfix">
											<ul class="pagination">
												<li class="page-item previous disabled">
													<a href="#" class="page-link">
														<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
															viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
															stroke-linecap="round" stroke-linejoin="round"
															class="feather feather-arrow-left">
															<line x1="19" y1="12" x2="5" y2="12"></line>
															<polyline points="12 19 5 12 12 5"></polyline>
														</svg>
													</a>
												</li>
												<li class="page-item active"><a href="#" class="page-link">1</a></li>
												<li class="page-item "><a href="#" class="page-link">2</a></li>
												<li class="page-item next" id="style-3_next">
													<a href="#" class="page-link">
														<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
															viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
															stroke-linecap="round" stroke-linejoin="round"
															class="feather feather-arrow-right">
															<line x1="5" y1="12" x2="19" y2="12"></line>
															<polyline points="12 5 19 12 12 19"></polyline>
														</svg>
													</a>
												</li>
											</ul>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
				<div class="modal fade" id="shipmentDetails" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
					<div class="modal-dialog modal-xl" role="document">
						<div class="modal-content">
							<div class="modal-header">
								<h5 class="modal-title" id="exampleModalLabel">Shipment Details</h5>
								<button type="button" class="close" data-dismiss="modal" aria-label="Close">
									<svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
								</button>
							</div>
							<div class="modal-body">
								<div className="row dataOnly">
									<div className="col-12 col-md-6">
										<div className="formData">
											<label>Shipment ID</label>
											<div className="infoText">SHP-15548212</div>
										</div>
									</div>
									<div className="col-12 col-md-6">
										<div className="formData">
											<label>Date</label>
											<div className="infoText">2021.08.10</div>
										</div>
									</div>
									<div className="col-12">
										<div className="">
											<label>Whats inside shipment</label>
											<div className="table-responsive customStriped">
												<table class="table">
													<thead>
														<tr>
															<th scope="col">Product Name</th>
															<th scope="col">Quantity</th>
															<th scope="col">Price (per unit)</th>
															<th scope="col">Dinmesions</th>
															<th scope="col">Weight</th>
															<th scope="col">Image</th>
														</tr>
													</thead>
													<tbody>
														<tr>
															<td>Smart Watch</td>
															<td>3</td>
															<td>$90</td>
															<td>18*12*25</td>
															<td>190G</td>
															<td>
																<img class="img-80" src="/assets/images/placeholder.jpg" alt="alt-img" />
															</td>
														</tr>
													</tbody>
												</table>
											</div>

										</div>
									</div>
								</div>
							</div>
							<div class="modal-footer">
								{/* <button class="myBtn btnSm btn-info">Review order information</button> */}
								<button type="button" class="myBtn btn-success btnSm btn-info" data-dismiss="modal" aria-label="Close">Close</button>
							</div>
						</div>
					</div>
				</div>
				<PageFooter />
			</React.Fragment>
		);
	}
}

export default Shipments;

