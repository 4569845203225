/**
 * @author Basit Raza <razabasit88@gmail.com>
 * @link http://be.net/basit_raza Author Website
 * @since 2021-01-18
 */
import React from 'react';
import PageHeader from '../../../components/page-header';
import ChangeTitle from '../../../components/change_page_title';
import PageFooter from '../../../components/page-footer';
import SideBar from '../../../components/side-bar';
import BreadCrumb from '../../../components/bread-crumb';
import $ from "jquery";
import {adminApiCall, adminApiCallForFormData} from "../../../utils/ajax_request";
import Notifications, { notify } from 'react-notify-toast';
import Swal from "sweetalert2";
import moment from "moment";

class ViewEmail extends React.Component {

    breadcrumbNavigation = [
        {
            title: 'Emails',
            link: '#'
        },
        {
            title: "Email logs",
            link: '#'
        }
    ]
    state = {
        recordDetail: '',
        email_id: ''
    }
    componentDidMount () {
        const urlParams = new URLSearchParams(window.location.search);
        const id = urlParams.get('id');
        if(!id){
            Swal.fire({
                title: "Error",
                text: "Invalid_access_error",
                icon: 'error',
            })
        }
        else {
            this.getEmail(id)
        }
    }
    getEmail = async (id) => {
        try{
            $('#loader').show();
            let res = await adminApiCall('email/get_email_log','POST' , JSON.stringify({email_log_id: id}))
            if (!res) {
                Swal.fire({
                    title: "Error",
                    text: "Something_went_wrong",
                    icon: 'error',
                })
                return false;
            }
            if ( res.hasOwnProperty('status') ) {
                console.log(res);
                if ( res?.status === 200 && res?.data ) {
                    this.setState({recordDetail: res?.data , email_id: res?.id});
                } else if ( res?.status === 400 ) {
                    Swal.fire({
                        title: "Error",
                        text: res?.message,
                        icon: 'error',
                    })
                }
            }
        }
        catch ( e ){
            Swal.fire({
                title: "Error",
                text: "Something_gone_wrong",
                icon: 'error',
            })
        }
        finally {
            $('#loader').hide();
        }
    }
    deleteEmails = async () => {
        if (this.state?.email_id){
            let id = []
            id.push(this.state?.email_id)
            Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then(async (result) => {
                if (result.isConfirmed){
                    let res = await adminApiCall('email/delete','POST',JSON.stringify({email_ids: id}))
                    if (!res) {
                        Swal.fire(
                            'Error!',
                            'Something went wrong Please try again.',
                            'error'
                        )
                        return false;
                    }
                    if ( res.hasOwnProperty('status') ) {
                        if ( res.status === 200) {
                            Swal.fire(
                                'Deleted!!',
                                'Record has been deleted.',
                                'success'
                            ).then(async  (result) => {
                                if (result.isConfirmed){
                                    this.props.history.push('email-log')
                                }
                            })
                        } else if ( res.status === 400 ) {
                            notify.show(res.message,'error',1500);
                        }
                    }
                }
            })
        }else{
            notify.show("No record selected",'error',2000);
        }

    }
    handlePageChange(pageNumber){
        this.setState({pagination: {...this.state.pagination , current_page: pageNumber}} )
        this.listing(pageNumber)
    }

    selectAllCheckBox = (e) => {
        if (e.target.checked){
            $('.customCheck').each(function() {
                this.checked = true
            })
        }else{
            $('.customCheck').each(function() {
                this.checked = false
            })
        }
    }

    render() {
        return (
            /*Content Area*/
            <React.Fragment>

                <ChangeTitle title={'Emails'} />

                {/*SideBar*/}
                <SideBar />

                {/*Page Header*/}
                <PageHeader />
                <Notifications options={{zIndex: 10000}}/>
                <BreadCrumb navigation={this.breadcrumbNavigation} />
                {/* main content starts here */}
                <section className="pageWrapper">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="contentCard">
                                    <hr/>
                                    <div className="cardHeader clearfix">
                                        <h1 className={"ml-5"}>{ this.state?.recordDetail?.data?.Subject}</h1>
                                    </div>
                                    <hr/>
                                    <div className="cardBody">
                                    {/*    =================================  */}
                                        <div className="col-sm-12">
                                            <div className="panel-sub-heading inner-all">
                                                <div className="row">
                                                    <div className="col-md-8 col-sm-8 col-xs-7">
                                                        <img
                                                            src="https://bootdey.com/img/Content/avatar/avatar1.png"
                                                            alt="..." className="img-circle senden-img"/>
                                                        <span className={"pl-3 pr-1 view-email-sender-address font-weight-bold"}> <u>{ this.state.recordDetail?.data?.From}</u></span>
                                                        <strong> to </strong>
                                                        <span className={"pl-1 view-email-sender-address"}> <u> { "<" + this.state?.recordDetail?.data?.Recipients?.[0]+ ">"} </u> </span>
                                                    </div>
                                                    <div className="col-md-4 col-sm-4 col-xs-5">
                                                        <p className="pull-right view-email-date-format">  {moment( this.state?.recordDetail?.data?.ReceivedAt).format( 'MMMM Do YYYY, h:mm a')} ( {moment(this.state?.recordDetail?.data?.ReceivedAt).startOf("minute").fromNow()} )
                                                            </p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="panel-body pl-5 pr-5 pt-3 pb-3">
                                                <div className="view-mail">
                                                    <p dangerouslySetInnerHTML={{ __html: this.state.recordDetail?.data?.HtmlBody }} />
                                                </div>

                                            </div>
                                            <hr/>
                                            <div className="panel-footer">
                                                <div className="pull-right">
                                                    <button className="btn btn-danger "
                                                            onClick={this.deleteEmails}
                                                            data-container="body" data-original-title="Trash"
                                                            data-toggle="tooltip" data-placement="top" title="">
                                                        <i className="fa fa-trash-o"></i></button>
                                                </div>
                                                <div className="clearfix"></div>
                                            </div>
                                        </div>
                                    {/*    =================================  */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <PageFooter />
            </React.Fragment>
        );
    }
}

export default ViewEmail;

