/**
 * @author Basit Raza <razabasit88@gmail.com>
 * @link http://be.net/basit_raza Author Website
 * @since 2021-01-18
 */
import React from 'react';
import PageHeader from '../../../../components/page-header';
import ChangeTitle from '../../../../components/change_page_title';
import PageFooter from '../../../../components/page-footer';
import SideBar from '../../../../components/side-bar';
import BreadCrumb from '../../../../components/bread-crumb';
import $ from "jquery";
import {adminApiCall, adminApiCallForFormData} from "../../../../utils/ajax_request";
import Notifications, { notify } from 'react-notify-toast';
import { Multiselect } from 'multiselect-react-dropdown';
import {storageProvider, truckRental} from "../../../../config";
import {multiSelectlisting} from "../../../../utils/api";
import {onMultiSelectHandle, onMultiRemoveHandle } from "../../../../utils/helpers";

class AddStorageCategory extends React.Component {

	breadcrumbNavigation = [
		{
			title: 'Truck Management',
			link: '#'
		},
		{
			title: 'Add Truck Category',
			link: '#'
		}
	]
	state = {
		isStatusChecked : false,
		amenities_list: [],
		truck_type_list: [],
		selected_amenities: '',
		selected_truck_type: ''
	}
	componentDidMount() {
		this.amenitiesSelectListing()
		this.truckTypeSelectListing()
	}

	amenitiesSelectListing = async () => {
		let jsonObject = {
			type: storageProvider
		}
		await multiSelectlisting('amenities/amenities_select_list', JSON.stringify(jsonObject) ).then((result) => {
			this.setState({amenities_list: result?.length > 0 ? result: []})
		})
	}
	truckTypeSelectListing = async () => {
		await multiSelectlisting('truck_provider/truck_types/truck_type_select').then((result) => {
			this.setState({truck_type_list: result?.length > 0 ? result: []})
		})
	}

	handleSubmit = async (event) => {
		try{
			$('#loader').show();
			event.preventDefault()
			let formData = new FormData(event.target);
			formData.append("amenities_ids", this.state.selected_amenities);
			formData.append("truck_types_ids", this.state.selected_truck_type);
			formData.append("type", truckRental);
			let res = await adminApiCallForFormData('search_filter_category/create',formData)
			if (!res) {
				notify.show("Something_went_wrong",'error',2000);
				return false;
			}
			if ( res.hasOwnProperty('status') ) {
				if ( res.status == 200 ) {
					notify.show(res.message,'success',2000);
					setTimeout(()=>{window.location.reload()},2000)
				} else if ( res.status == 400 ) {
					notify.show(res.message,'error',2000);
				}
			}
		}catch (e) {
			console.log(e);
			notify.show("Something_gone_wrong",'error',2000);
		}finally {
			$('#loader').hide();
		}

	}

	onAmenitySelectHandle = async ( selectedList, selectedItem) => {
		await onMultiSelectHandle(selectedList).then((result) => {
			this.setState({selected_amenities: result})
		})
	}
	onAmenityRemoveHandle = async (selectedList, selectedItem) => {
		await onMultiRemoveHandle(selectedList).then((result) => {
			this.setState({selected_amenities: result})
		})
	}
	onTruckTypeSelectHandle = async ( selectedList, selectedItem) => {
		await onMultiSelectHandle(selectedList).then((result) => {
			this.setState({selected_truck_type: result})
		})
	}
	onTruckTypeRemoveHandle = async (selectedList, selectedItem) => {
		await onMultiRemoveHandle(selectedList).then((result) => {
			this.setState({selected_truck_type: result})
		})
	}

	onKeyPressHandle = (event) => {
		event.key === 'Enter' && event.preventDefault();
	}

	render() {
		return (
			/*Content Area*/
			<React.Fragment>

				<ChangeTitle title={'Truck categories'} />

				{/*SideBar*/}
				<SideBar />

				{/*Page Header*/}
				<PageHeader />
				<Notifications options={{zIndex: 10000}}/>
				<BreadCrumb navigation={this.breadcrumbNavigation} />

				{/* main content starts here */}

				<section class="pageWrapper">
					<div class="container-fluid">
						<div class="row">
							<div class="col-12">
								<div class="contentCard">
									<div class="cardHeader clearfix">
										<h1>Add Truck Category</h1>
									</div>
									<div class="cardBody">
										<form onSubmit={this.handleSubmit}>
											<div className="row formWrapper">
												<div className="col-12 col-md-12">
													<div className="form-group inlineField">
														<div className="row align-items-center">
															<div className="col-12 col-md-4 col-lg-3">
																<label>Name</label>
															</div>
															<div className="col-12 col-md-8 col-lg-9">
																<input type="text" name={"name"} placeholder="Please enter name."
																	   onKeyPress={this.onKeyPressHandle}
																/>
															</div>
														</div>
													</div>
												</div>
												<div className="col-12 col-md-12">
													<div className="form-group inlineField">
														<div className="row align-items-center">
															<div className="col-12 col-md-4 col-lg-3">
																<label>Truck Type(s) </label>
															</div>
															<div className="col-12 col-md-8 col-lg-9">
																<Multiselect
																	options={this.state.truck_type_list}
																	displayValue="title"
																	onSelect={( list , item)=>this.onTruckTypeSelectHandle( list , item)}
																	onRemove={(list , item)=>this.onTruckTypeRemoveHandle(list , item)}
																>
																</Multiselect>
															</div>
														</div>
													</div>
												</div>
												<div className="col-12 col-md-12">
													<div className="form-group inlineField">
														<div className="row align-items-center">
															<div className="col-12 col-md-4 col-lg-3">
																<label>Amenities </label>
															</div>
															<div className="col-12 col-md-8 col-lg-9">
																<Multiselect
																	options={this.state.amenities_list}
																	displayValue="title"
																	onSelect={( list , item)=>this.onAmenitySelectHandle( list , item)}
																	onRemove={(list , item)=>this.onAmenityRemoveHandle(list , item)}
																>
																</Multiselect>
															</div>
														</div>
													</div>
												</div>
												<div className="col-12 col-md-12">
													<div className="form-group inlineField">
														<div className="row align-items-center">
															<div className="col-12 col-md-4 col-lg-3">
																<label>Media Files</label>
															</div>
															<div className="col-12 col-md-8 col-lg-9">
																<div className="inputFile">
																	<input name="media_file" type="file" multiple />
																</div>
															</div>
														</div>
													</div>
												</div>
												<div className="col-12 col-md-12">
													<div className="form-group inlineField">
														<div className="row align-items-center">
															<div className="col-12 col-md-4 col-lg-3">
																<label>Description</label>
															</div>
															<div className="col-12 col-md-8 col-lg-9">
																<textarea name="description" rows="5" onKeyPress={this.onKeyPressHandle}/>
															</div>
														</div>
													</div>
												</div>
												<div className="col-12 col-md-12">
													<div className="form-group inlineField">
														<div className="row align-items-center">
															<div className="col-12 col-md-4 col-lg-3">
																<label>Status</label>
															</div>
															<div className="col-12 col-md-8 col-lg-9">
																<div className="toggleBtn" >
																	<input type="checkbox" id="togglePrice" checked={"checked"} name={"status"} onChange={(e)=> {this.setState({isStatusChecked: e.target.checked})} } />
																		<label htmlFor="togglePrice">
																			<span className="on">ON</span>
																			<span className="off">OFF</span>
																		</label>
																</div>
															</div>
														</div>
													</div>
												</div>
												<div class="col-12 col-md-12 text-right">
													<button type={"submit"} class="myBtn" onClick={this.register}>Register </button>
												</div>
											</div>
										</form>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>

				<PageFooter />
			</React.Fragment>
		);
	}
}

export default AddStorageCategory;

