import React from 'react';
import PageHeader from '../../../components/page-header';
import ChangeTitle from '../../../components/change_page_title';
import PageFooter from '../../../components/page-footer';
import SideBar from '../../../components/side-bar';
import BreadCrumb from '../../../components/bread-crumb';
// date time picker 
import DateRangePicker from 'react-bootstrap-daterangepicker';
// you will also need the css that comes with bootstrap-daterangepicker
import 'bootstrap-daterangepicker/daterangepicker.css';
import Swal from "sweetalert2";

class CustomerProfile extends React.Component {

	breadcrumbNavigation = [
		{
			title: 'Manage User',
			link: '#'
		},
		{
			title: 'Customer Profile',
			link: '#'
		}
	]



	handleEvent(event, picker) {
		console.log(picker.startDate.toDate());
		console.log(picker.endDate.toDate());
	}

	hitFire() {
		Swal.fire({
			title: 'Are you sure?',
			text: "You won't be able to revert this!",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Yes, delete it!'
		}).then((result) => {
			if (result.isConfirmed) {
				Swal.fire(
					'Deleted!',
					'Your file has been deleted.',
					'success'
				)
			}
		})
	}

	render() {
		return (
			/*Content Area*/
			<React.Fragment>

				<ChangeTitle title={'Customer Profile'} />

				{/*SideBar*/}
				<SideBar />

				{/*Page Header*/}
				<PageHeader />

				<BreadCrumb navigation={this.breadcrumbNavigation} />

				{/* main content starts here */}
				<section class="pageWrapper">
					<div class="container-fluid">
						<div class="row">
							<div class="col-12">
								<div class="contentCard">
									<div class="cardHeader clearfix">
										<h1>Profile</h1>
									</div>
									<div class="cardBody">
										<div class="row dataOnly">
											<div class="col-12 col-md-6	">
												<div class="formData">
													<label>Customer Id</label>
													<div class="infoText">SG-528-596 <span class="badge badge-success">Active</span></div>
												</div>
											</div>
											<div class="col-12 col-md-6	">
												<div class="formData">
													<label>Name</label>
													<div class="infoText">Jhon Doe</div>
												</div>
											</div>
											<div class="col-12 col-md-6	">
												<div class="formData">
													<label>Email</label>
													<div class="infoText">SG-528-596</div>
												</div>
											</div>
											<div class="col-12 col-md-6	">
												<div class="formData">
													<label>Phone</label>
													<div class="infoText">SG-528-596</div>
												</div>
											</div>
											<div class="col-12 col-md-12 text-right">
												<button class="myBtn" data-toggle="modal" data-target="#chagneInfo">
													<i class="fa fa-edit"></i> Update Information
												</button>
											</div>
										</div>

									</div>
								</div>
								{/* profile details starts here */}
								<div className="profileDetail">
									<div className="detailInner">
										Total Balance
										<span>$250</span>
									</div>
									<div className="detailInner">
										Pending Invoices
										<span>8</span>
									</div>
									<div className="detailInner">
										Total Orders
										<span>235</span>
									</div>
									<div className="detailInner">
										Total Transections
										<span>$3,691</span>
									</div>
								</div>
								{/* previous transections */}

								<div class="cardHeader my-2 clearfix">
									<h1>Previous Transections</h1>
								</div>

								<div className="table-responsive customStriped">
									<table class="table textRight">
										<thead>
											<tr>
												<th>Transection Id</th>
												<th>Date</th>
												<th>Balance At that time</th>
												<th>Transection Ammount</th>
											</tr>
										</thead>
										<tbody>
											<tr>
												<td>
													<h6>INV-2582</h6>
												</td>
												<td>
													<h6>28 May, 2021</h6>
												</td>
												<td>
													<h1>$1,585</h1>
												</td>
												<td>
													<div class="text-danger">
														<h2>$180</h2>
														<p>Refunded Form Account</p>
													</div>
												</td>
											</tr>
											<tr>
												<td>
													<h6>INV-2582</h6>
												</td>
												<td>
													<h6>28 May, 2021</h6>
												</td>
												<td>
													<h1>$1,585</h1>
												</td>
												<td>
													<div class="text-success">
														<h2>$265</h2>
														<p>Added In Accont </p>
													</div>
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
				</section>

				<div class="modal fade" id="chagneInfo" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
					<div class="modal-dialog" role="document">
						<div class="modal-content">
							<div class="modal-header">
								<h5 class="modal-title" id="exampleModalLabel">Update Info</h5>
								<button type="button" class="close" data-dismiss="modal" aria-label="Close">
									<svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
								</button>
							</div>
							<div class="modal-body">
								<div className="formWrapper">
									<div class="form-group">
										<label>First Name:</label>
										<input type="text" />
									</div>
									<div class="form-group">
										<label>Last Name:</label>
										<input type="tel" />
									</div>
									<div class="form-group">
										<label>Email:</label>
										<input type="email" />
									</div>
									<div class="form-group">
										<label>Change Password:</label>
										<input type="password" />
									</div>
								</div>
							</div>
							<div class="modal-footer">
								<div className="tableDetails text-center">
									<button class="myBtn btnSm btn-simple" data-dismiss="modal"> Cancel</button>
									<button class="myBtn btnSm btn-success ml-2">Submit</button>
								</div>
							</div>
						</div>
					</div>
				</div>
				<PageFooter />
			</React.Fragment>
		);
	}
}

export default CustomerProfile;

