import React from "react";
import ChangeTitle from "../../../components/change_page_title";
import SideBar from "../../../components/side-bar";
import PageHeader from "../../../components/page-header";
import BreadCrumb from "../../../components/bread-crumb";
import Notifications, { notify } from "react-notify-toast";
class Leads extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      error: null,
      loading: true,
    };
  }

  componentDidMount() {
    this.fetchData();
    console.log(this.state.data, "data");
  }

  breadcrumbNavigation = [
    { title: "Manage Leads", link: "#" },
    { title: "Leads", link: "#" },
  ];
  listing = [
    {
      id: 1,
      user: "Mike Johnson",
      provider: "123 Freight",
      detail: "Details of the truck",
      truck: "Ram 1500",
      url: "www.youtube.com",
    },
    {
      id: 2,
      user: "Emily Davis",
      provider: "123 Freight",
      detail: "Details of the truck",
      truck: "Ram 1500",
      url: "www.youtube.com",
    },
    {
      id: 3,
      user: "Jane Smith",
      provider: "123 Freight",
      detail: "Details of the truck",
      truck: "Ram 1500",
      url: "www.youtube.com",
    },
    {
      id: 4,
      user: "John Doe",
      provider: "123 Freight",
      detail: "Details of the truck",
      truck: "Ram 1500",
      url: "www.youtube.com",
    },
    {
      id: 5,
      user: "Keenu Reves",
      provider: "123 Freight",
      detail: "Details of the truck",
      truck: "Ram 1500",
      url: "www.youtube.com",
    },
  ];

  handleRedirect = (element) => {
    const { history } = this.props;
    const url = `truck-order-detail?id=${element?.orderId}&order_id=${element?.orderNumber}`;
    history.push(url);
  };
  // commit for testing
  formatDate = (inputDate) => {
    const date = new Date(inputDate);
    const options = {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    };
    const formattedDate = date.toLocaleDateString("en-US", options);
    const [month, day, year] = formattedDate.split("/");
    return `${month}-${day}-${year}`;
  };

  renderListing = (data) => {
    return data?.map((element, idx) => {
      return (
        <tr key={idx}>
          <td>{idx + 1}</td>
          <td
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems:'center',
              gap: "10px",
            }}
          >
            {element?.orderNumber}
             {
              element?.orderNumber && element?.orderId && <button
              className="myBtn btn-danger btnSm"
              onClick={() => this.handleRedirect(element)}
            >
              <i className=" fa fa-eye"></i>
            </button> 
             } 
            
          </td>
          <td>{element?.email}</td>
          <td>{element?.phone}</td>
          <td>{this.formatDate(element?.created_at)}</td>
        </tr>
      );
    });
  };
  async fetchData() {
    const apiUrl = "https://dev-api.getspaceshuttle.com/api/admin/leads/list";
    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("adminToken"),
        },
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      this.setState({ data: data?.data, loading: false });
    } catch (error) {
      this.setState({
        error,
        loading: false,
      });
      console.error("Error fetching data:", error);
    }
  }

  render() {
    const { data, error, loading } = this.state;
    console.log(data, "i am data");
    return (
      <React.Fragment>
        <ChangeTitle title="Manage User" />
        <SideBar />
        <PageHeader />
        <Notifications options={{ zIndex: 10000 }} />
        <BreadCrumb navigation={this.breadcrumbNavigation} />
        <section class="pageWrapper">
          <div class="container-fluid">
            <div class="row">
              <div class="col-12">
                <div class="contentCard">
                  <div class="cardBody">
                    <div class="table-responsive customStriped">
                      <table class="table">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Order Id</th>
                            <th>E-mail</th>
                            <th>Phone</th>
                            <th>Date</th>
                          </tr>
                        </thead>
                        <tbody>{this.renderListing(data)}</tbody>
                      </table>
                    </div>
                    <div class="paginationWrapper clearfix">
                      <div className="paginationWrapper clearfix">
                        {/* <Pagination
                          activePage={this.state?.pagination?.current_page}
                          itemsCountPerPage={
                            this.state?.pagination?.itemsCountPerPage
                          }
                          totalItemsCount={
                            this.state?.pagination?.total_records
                          }
                          pageRangeDisplayed={
                            this.state?.pagination?.total_pages
                          }
                          onChange={this.handlePageChange.bind(this)}
                          itemClass={"page-item"}
                          linkClass={"page-link"}
                        /> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default Leads;
