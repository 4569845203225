/**
 * @author Basit Raza <razabasit88@gmail.com>
 * @link http://be.net/basit_raza Author Website
 * @since 2021-01-18
 */
import React from 'react';
import PageHeader from '../../../components/page-header';
import ChangeTitle from '../../../components/change_page_title';
import PageFooter from '../../../components/page-footer';
import SideBar from '../../../components/side-bar';
import BreadCrumb from '../../../components/bread-crumb';

class CustomerAddress extends React.Component {

	breadcrumbNavigation = [
		{
			title: 'Address Book',
			link: '#'
		},
		{
			title: 'Customer Address List',
			link: '#'
		}
	]

	render() {
		return (
			/*Content Area*/
			<React.Fragment>

				<ChangeTitle title={'Address List'} />

				{/*SideBar*/}
				<SideBar />

				{/*Page Header*/}
				<PageHeader />

				<BreadCrumb navigation={this.breadcrumbNavigation} />

				{/* main content starts here */}
				<section class="pageWrapper">
					<div class="container-fluid">
						<div class="row">
							<div class="col-12">
								<div class="contentCard">
									<div class="cardHeader">
										<h1>Customer Address List</h1>
									</div>
									<div class="cardBody">
										<div class="table-responsive customStriped">
											<table class="table">
												<thead>
													<tr>
														<th scope="col">Address Id</th>
														<th scope="col">Address Title</th>
														<th scope="col">Customer Name </th>
														<th scope="col">Country </th>
														<th scope="col">City </th>
														<th scope="col">Street Address </th>
														<th scope="col">Postal Code</th>
														<th scope="col">Action</th>
													</tr>
												</thead>
												<tbody>
													<tr>
														<td>ADR-54826</td>
														<td>Customer Address Title 1</td>
														<td>Jhon Doe</td>
														<td>Kenya</td>
														<td>Nairobi</td>
														<td>Street # 2152, Suite #1</td>
														<td>44558</td>
														<td>
															<button class="myBtn btn-info btnSm" data-toggle="modal" data-target="#ViewAddress"><i class="fa fa-eye"></i></button>
														</td>
													</tr>
												</tbody>
											</table>

										</div>
										<div class="paginationWrapper clearfix">
											<div class="tableInfo">Showing page 1 of 5</div>
											<ul class="pagination">
												<li class="page-item previous disabled">
													<a href="#" class="page-link">
														<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
															viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
															stroke-linecap="round" stroke-linejoin="round"
															class="feather feather-arrow-left">
															<line x1="19" y1="12" x2="5" y2="12"></line>
															<polyline points="12 19 5 12 12 5"></polyline>
														</svg>
													</a>
												</li>
												<li class="page-item active"><a href="#" class="page-link">1</a></li>
												<li class="page-item "><a href="#" class="page-link">2</a></li>
												<li class="page-item next" id="style-3_next">
													<a href="#" class="page-link">
														<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
															viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
															stroke-linecap="round" stroke-linejoin="round"
															class="feather feather-arrow-right">
															<line x1="5" y1="12" x2="19" y2="12"></line>
															<polyline points="12 5 19 12 12 19"></polyline>
														</svg>
													</a>
												</li>
											</ul>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
				<div class="modal fade" id="ViewAddress" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
					<div class="modal-dialog modal-xl" role="document">
						<div class="modal-content">
							<div class="modal-header">
								<h5 class="modal-title" id="exampleModalLabel">Address Details</h5>
								<button type="button" class="close" data-dismiss="modal" aria-label="Close">
									<svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
								</button>
							</div>
							<div class="modal-body">
								<div className="row dataOnly">
									<div className="col-12 col-md-6">
										<div className="formData">
											<label>Address ID</label>
											<div className="infoText">ADR-15548212 <span class="badge badge-success">Default</span></div>
										</div>
									</div>
									<div className="col-12 col-md-6">
										<div className="formData">
											<label>Address Title</label>
											<div className="infoText">USA Wearhouse</div>
										</div>
									</div>
									<div className="col-12 col-md-6">
										<div className="formData">
											<label>Country</label>
											<div className="infoText">USA</div>
										</div>
									</div>
									<div className="col-12 col-md-6">
										<div className="formData">
											<label>City</label>
											<div className="infoText">Los Angeles</div>
										</div>
									</div>
									<div className="col-12 col-md-6">
										<div className="formData">
											<label>Street Address</label>
											<div className="infoText">123 Main Street, Suite #1 Los Angeles, CA 90503</div>
										</div>
									</div>
								</div>
							</div>
							<div class="modal-footer">
								<button type="button" class="myBtn btn-success btnSm btn-info" data-dismiss="modal" aria-label="Close">Close</button>
							</div>
						</div>
					</div>
				</div>

				<PageFooter />
			</React.Fragment>
		);
	}
}

export default CustomerAddress;

