/**
 * @author Basit Raza <razabasit88@gmail.com>
 * @link http://be.net/basit_raza Author Website
 * @since 2021-01-18
 */
import React, { Fragment } from "react";
import PageHeader from "../../../components/page-header";
import ChangeTitle from "../../../components/change_page_title";
import PageFooter from "../../../components/page-footer";
import SideBar from "../../../components/side-bar";
import BreadCrumb from "../../../components/bread-crumb";
import { adminApiCall } from "../../../utils/ajax_request";
import Notifications, { notify } from "react-notify-toast";
import $ from "jquery";
import Swal from "sweetalert2";
import {
  dateTimeFormat,
  replaceUnderScoreWithSpace,
  snakeToCamel,
} from "../../../utils/helpers";

class TruckOrderDetail extends React.Component {
  breadcrumbNavigation = [
    {
      title: "Order Management",
      link: "#",
    },
    {
      title: "Order Detail",
      link: "#",
    },
  ];
  state = {
    order_detail: {},
    billing_state: {},
  };
  componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search);
    const id = urlParams.get("id");
    const order_id = urlParams.get("order_id");
    if (!id || !order_id) {
      notify.show("invalid page access", "error", 1500);
      setTimeout(() => {
        this.props.history.push("/truck-orders-list");
      }, 1500);
    } else {
      this.getOrder(id, order_id);
    }
  }

  //========== get order detail here =========
  getOrder = async (id, order_id) => {
    try {
      $("#loader").show();
      let ids = {
        id: id,
        order_id: order_id,
      };
      let res = await adminApiCall(
        `order/truck_order/get`,
        "POST",
        JSON.stringify(ids)
      );
      //   console.log(res, "respose of ORDER");
      console.log("api data==>", res);
      if (!res) {
        notify.show(res.message, "error", 1500);
        return false;
      }
      if (res.hasOwnProperty("status")) {
        if (res.status == 200) {
          console.log(res, "respose of ORDER");

          this.setState({ order_detail: res.data });
          this.billing();
        } else if (res.status == 400) {
          notify.show(res.message, "error", 1500);
          this.setState({ order_detail: {} });
        }
      }
    } catch (e) {
      console.log(e);
      notify.show("Something_gone_wrong", "error", 2000);
      this.setState({ order_detail: {} });
    } finally {
      $("#loader").hide();
    }
  };

  billing = async () => {
    //== total km  trvel - free km included
    let total_km_travel_min_free_km_included =
      Number(this.state?.order_detail?.order?.billing_detail?.total_km_travel) -
      Number(this.state?.order_detail?.order?.billing_detail?.free_km_included);
    //== total km trvel * mileage rate
    let total_km_travel_mul_mileage_rate =
      Number(total_km_travel_min_free_km_included) *
      Number(this.state?.order_detail?.order?.billing_detail?.mileage_rate);
    //==== check if any prmotional or discount rate is implemented or not and do calculation according to it
    let rate_obj = {};
    if (
      this.state?.order_detail?.order?.billing_detail?.today_promotional_rate &&
      this.state?.order_detail?.order?.billing_detail?.today_standered_rate &&
      this.state?.order_detail?.order?.billing_detail?.truck_discount_rate
    ) {
      rate_obj = {
        rate_type: "promotional_rate",
        type: "$",
        price_rate: Number(
          this.state?.order_detail?.order?.billing_detail
            ?.today_promotional_rate
        ),
      };
    } else if (
      this.state?.order_detail?.order?.billing_detail?.today_promotional_rate &&
      this.state?.order_detail?.order?.billing_detail?.today_standered_rate
    ) {
      rate_obj = {
        rate_type: "promotional_rate",
        type: "$",
        price_rate: Number(
          this.state?.order_detail?.order?.billing_detail
            ?.today_promotional_rate
        ),
      };
    } else if (
      this.state?.order_detail?.order?.billing_detail?.truck_discount_rate &&
      this.state?.order_detail?.order?.billing_detail?.today_standered_rate
    ) {
      if (
        this.state?.order_detail?.order?.billing_detail?.truck_discount_rate
          ?.discount_type === "percentage"
      ) {
        // let discount_rate = (this.state?.order_detail?.order?.billing_detail?.today_standered_rate- ((this.state?.order_detail?.order?.billing_detail?.truck_discount_rate?.daily_rate/100)* this.state?.order_detail?.order?.billing_detail?.today_standered_rate))
        rate_obj = {
          rate_type: "discount_rate",
          type: "%",
          discount_name:
            this.state?.order_detail?.order?.billing_detail?.truck_discount_rate
              ?.discount_name,
          price_rate: Number(
            this.state?.order_detail?.order?.billing_detail?.truck_discount_rate
              ?.daily_rate
          ),
        };
      } else {
        let discount_rate =
          this.state?.order_detail?.order?.billing_detail
            ?.today_standered_rate -
          this.state?.order_detail?.order?.billing_detail?.truck_discount_rate
            ?.daily_rate;
        rate_obj = {
          rate_type: "discount_rate",
          type: "$",
          discount_name:
            this.state?.order_detail?.order?.billing_detail?.truck_discount_rate
              ?.discount_name,
          price_rate: Number(
            this.state?.order_detail?.order?.billing_detail?.truck_discount_rate
              ?.daily_rate
          ),
        };
      }
      //fixed
    } else {
      rate_obj = {
        rate_type: "standard_rate",
        price_rate:
          Number(
            this.state?.order_detail?.order?.billing_detail
              ?.today_standered_rate
          ) *
          Number(
            this.state?.order_detail?.order?.billing_detail?.number_of_days
          ),
      };
    }
    //================= number of driver mul per driver fee
    let num_of_driver_mul_per_driver_fee =
      Number(
        this.state?.order_detail?.order?.billing_detail?.number_of_driver
      ) *
      Number(this.state?.order_detail?.order?.billing_detail?.per_driver_fee);
    this.setState({
      billing_state: {
        total_km_travel_min_free_km_included:
          total_km_travel_min_free_km_included,
        total_km_travel_mul_mileage_rate: total_km_travel_mul_mileage_rate,
        rate: rate_obj,
        num_of_driver_mul_per_driver_fee: num_of_driver_mul_per_driver_fee,
      },
    });
    console.log(this.state?.billing_state);
  };

  render() {
    const additionalFee =
      this.state?.order_detail?.order?.billing_detail?.extra_fee || [];
    const additionalFeePrice =
      this.state?.order_detail?.order?.billing_detail?.extra_fee?.map(
        (item) => item.price
      );

    const totalPrice = additionalFeePrice?.reduce(
      (acc, price) => acc + price,
      0
    );
    // console.log(totalPrice, "ttttt");
    return (
      /*Content Area*/
      <React.Fragment>
        <ChangeTitle title={"Order Detail"} />

        {/*SideBar*/}
        <SideBar />

        {/*Page Header*/}
        <PageHeader />
        <Notifications options={{ zIndex: 10000 }} />
        <BreadCrumb navigation={this.breadcrumbNavigation} />

        {/* main content starts here */}
        <section class="pageWrapper">
          <div class="container-fluid">
            <div className={"row"}></div>
            <div class="row">
              <div class="col-12">
                <div class="contentCard order-invoice-content-card">
                  <div className="order-invoice-header-flex">
                    <div className={"col col-md-6"}>
                      Order ID #
                      <strong>
                        {" "}
                        {this.state?.order_detail?.order?.order_id}{" "}
                        <span className="badge badge-pill badge-danger">
                          {this.state?.order_detail?.order?.order_status}
                        </span>{" "}
                      </strong>
                    </div>
                    <div className={"col col-md-6"}>
                      {/*<button className={"btn btn-sm btn-info float-right"}><i className={"fa fas fa-print"}> prints </i></button>*/}
                    </div>
                  </div>

                  <div class="cardBody">
                    <div class="row">
                      <div class="col-12 col-md-4">
                        <div className="order-invoice-form-data">
                          <strong>
                            {" "}
                            <u> Orderer </u>{" "}
                          </strong>
                        </div>
                        <br />
                        <div className="order-invoice-form-data">
                          name: &nbsp;
                          {`${this.state?.order_detail?.user_detail?.first_name}  ${this.state?.order_detail?.user_detail?.last_name}`}
                        </div>
                        <div className="order-invoice-form-data">
                          email: &nbsp;
                          {this.state?.order_detail?.user_detail?.email}
                        </div>
                        <div className="order-invoice-form-data">
                          phone: &nbsp;
                          {this.state?.order_detail?.user_detail?.phone}
                        </div>
                        <div className="order-invoice-form-data">
                          website: &nbsp;
                          {this.state?.order_detail?.user_detail?.website}
                        </div>
                      </div>
                      <div class="col-12 col-md-4">
                        <div className="order-invoice-form-data">
                          <strong>
                            <u> Company </u>
                          </strong>
                        </div>
                        <br />
                        <div className="order-invoice-form-data">
                          company name: &nbsp;
                          {this.state?.order_detail?.company_detail?.name}
                        </div>
                        <div className="order-invoice-form-data">
                          email: &nbsp;
                          {this.state?.order_detail?.company_detail?.email}
                        </div>
                        <div className="order-invoice-form-data">
                          phone: &nbsp;
                          {this.state?.order_detail?.company_detail?.phone}
                        </div>
                        <div className="order-invoice-form-data">
                          website: &nbsp;
                          {this.state?.order_detail?.company_detail?.website}
                        </div>
                      </div>
                      <div class="col-12 col-md-4">
                        <div className="order-invoice-form-data">
                          <strong>
                            <u>Detail</u>
                          </strong>
                        </div>
                        <br />
                        <div className="order-invoice-form-data">
                          order id: &nbsp;
                          <span className={"badge badge-pill badge-danger"}>
                            {" "}
                            {this.state?.order_detail?.order?.order_id}{" "}
                          </span>
                        </div>
                        <div className="order-invoice-form-data">
                          order date: &nbsp;
                          {dateTimeFormat(
                            this.state?.order_detail?.order?.created_at
                          )}
                        </div>
                        <div className="order-invoice-form-data">
                          pick up location: &nbsp;
                          {
                            this.state?.order_detail?.search_data
                              ?.pick_up_address?.address
                          }
                        </div>
                        <div className="order-invoice-form-data">
                          pick up date: &nbsp;
                          {this.state?.order_detail?.search_data?.pick_up_date}
                        </div>
                        {this.state?.order_detail?.search_data
                          ?.drop_of_address && (
                          <>
                            <div className="order-invoice-form-data">
                              drop of location: &nbsp;
                              {
                                this.state?.order_detail?.search_data
                                  ?.drop_of_address?.inputValue
                              }
                            </div>
                            <div className="order-invoice-form-data">
                              drop off date: &nbsp;
                              {
                                this.state?.order_detail?.search_data
                                  ?.drop_of_address?.pick_up_date
                              }
                            </div>
                          </>
                        )}
                      </div>
                    </div>

                    <hr />
                    <h2 className="cardInnerTitle">Ordered Product</h2>

                    <div className="table-responsive customStriped">
                      <table className="table">
                        <thead>
                          <tr>
                            <th scope="col">item</th>
                            <th scope="col">item type</th>
                            <th scope="col">price (standard rate)</th>
                            <th scope="col">(height)x(width)x(length)</th>
                            <th scope="col">storage capacity </th>
                            <th scope="col">load capacity </th>
                            <th scope="col">fuel type </th>
                            <th scope="col">free km</th>
                            <th scope="col">Mileage fee</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              {this.state?.order_detail?.truck?.truck_type}
                            </td>
                            <td>
                              {this.state?.order_detail?.truck?.equipment_type}
                            </td>
                            <td>
                              $
                              {
                                this.state?.order_detail?.order?.billing_detail
                                  ?.today_standered_rate
                              }
                            </td>
                            <td>{`${this.state?.order_detail?.truck?.height} * ${this.state?.order_detail?.truck?.width} * ${this.state?.order_detail?.truck?.length}`}</td>
                            <td>
                              {
                                this.state?.order_detail?.truck
                                  ?.storage_capacity
                              }
                            </td>
                            <td>
                              {this.state?.order_detail?.truck?.load_capacity}
                            </td>
                            <td>
                              {this.state?.order_detail?.truck?.fuel_type}
                            </td>
                            <td>
                              {
                                this.state?.order_detail?.order?.billing_detail
                                  ?.free_km_included
                              }
                            </td>
                            <td>
                              {
                                this.state?.order_detail?.order?.billing_detail
                                  ?.mileage_rate
                              }
                            </td>
                            {/*<td>*/}
                            {/*	<img className="img-80" src="/assets/images/placeholder.jpg"*/}
                            {/*		 alt="alt-img"/>*/}
                            {/*</td>*/}
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <hr />
                    {/*<h2 className="cardInnerTitle">Ordered Products</h2>*/}

                    <div className="table-responsive customStriped">
                      <table class="table">
                        <thead>
                          <tr>
                            <th scope="col">order id</th>
                            {/*<th scope="col">transaction id</th>*/}
                            {/*<th scope="col">transaction id</th>*/}
                            <th scope="col">card brand</th>
                            <th scope="col">
                              provider bill payment method type
                            </th>
                            <th scope="col">deposit fee payment method type</th>
                            <th scope="col">Quantity</th>
                            <th scope="col">standard rate</th>
                            <th scope="col">provider bill</th>
                            <th scope="col">total bill</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              <span className={"badge badge-pill badge-danger"}>
                                {" "}
                                {this.state?.order_detail?.order?.order_id}{" "}
                              </span>
                            </td>
                            <td>
                              {this.state?.order_detail?.order?.card_brand ??
                                "--"}{" "}
                            </td>
                            <td>
                              {this.state?.order_detail?.order
                                ?.provider_bill_payment_method_type ?? "--"}
                            </td>
                            <td>{""}</td>
                            <td>
                              {this.state?.order_detail?.order?.quantity ??
                                "--"}{" "}
                            </td>
                            <td>
                              <span className={"badge badge-pill badge-dark"}>
                                $
                                {
                                  this.state?.order_detail?.order
                                    ?.billing_detail?.today_standered_rate
                                }
                              </span>
                            </td>
                            <td>
                              <span className={"badge badge-pill badge-dark"}>
                                $
                                {parseFloat(
                                  this.state?.order_detail?.order?.provider_bill
                                ).toFixed(2)}
                              </span>
                            </td>
                            <td>
                              <span className={"badge badge-pill badge-dark"}>
                                {" "}
                                $
                                {parseFloat(
                                  Number(
                                    this.state?.order_detail?.order
                                      ?.provider_bill
                                  ) +
                                    Number(
                                      this.state?.order_detail?.order
                                        ?.billing_detail?.deposit_fee
                                    )
                                  // +
                                  // Number(totalPrice ? totalPrice : null)
                                ).toFixed(2)}{" "}
                              </span>{" "}
                            </td>
                            {/*<td>*/}
                            {/*	<img class="img-80" src="/assets/images/placeholder.jpg" alt="alt-img" />*/}
                            {/*</td>*/}
                          </tr>
                          <tr>
                            <td colSpan={"5"}></td>
                            <td>
                              <strong>
                                {" "}
                                {this.state?.billing_state?.rate?.rate_type &&
                                  replaceUnderScoreWithSpace(
                                    this.state?.billing_state?.rate?.rate_type
                                  )}{" "}
                                {this.state?.billing_state?.rate?.rate_type ===
                                  "discount_rate" &&
                                  "(" +
                                    this.state?.billing_state?.rate
                                      ?.discount_name +
                                    ")"}
                              </strong>
                            </td>
                            <td>
                              <span className={"badge badge-pill badge-dark"}>
                                {this.state?.billing_state?.rate?.type}
                                {this.state?.billing_state?.rate?.price_rate}
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <td colSpan={"5"}></td>
                            <td>
                              <strong> total km travel</strong>
                            </td>
                            <td>
                              <span className={"badge badge-pill badge-dark"}>
                                {
                                  this.state?.order_detail?.order
                                    ?.billing_detail?.total_km_travel
                                }
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <td colSpan={"5"}></td>
                            <td>
                              <strong> free km included</strong>
                            </td>
                            <td>
                              <span className={"badge badge-pill badge-dark"}>
                                {
                                  this.state?.order_detail?.order
                                    ?.billing_detail?.free_km_included
                                }
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <td colSpan={"5"}></td>
                            <td>
                              <strong> Mileage rate</strong>
                            </td>
                            <td>
                              <span className={"badge badge-pill badge-dark"}>
                                $
                                {
                                  this.state?.order_detail?.order
                                    ?.billing_detail?.mileage_rate
                                }
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <td colSpan={"5"}></td>
                            <td>
                              <strong> Rental time</strong>
                            </td>
                            <td>
                              <span className={"badge badge-pill badge-dark"}>
                                {
                                  this.state?.order_detail?.order
                                    ?.billing_detail?.number_of_days
                                }{" "}
                                days
                              </span>
                            </td>
                          </tr>
                          {this.state?.order_detail?.order?.billing_detail
                            ?.add_ons &&
                            this.state?.order_detail?.order?.billing_detail
                              ?.add_ons.length > 0 &&
                            this.state?.order_detail?.order?.billing_detail?.add_ons.map(
                              (addon) => {
                                return (
                                  <React.Fragment key={addon?._id}>
                                    <tr>
                                      <td colSpan={"5"}></td>
                                      <td>
                                        <strong>
                                          {" "}
                                          {addon?.title} (add on){" "}
                                        </strong>
                                      </td>
                                      <td>
                                        <span
                                          className={
                                            "badge badge-pill badge-dark"
                                          }
                                        >
                                          ${addon?.price}
                                        </span>
                                      </td>
                                    </tr>
                                  </React.Fragment>
                                );
                              }
                            )}
                          {this.state?.order_detail?.order?.billing_detail
                            ?.number_of_driver && (
                            <>
                              <tr>
                                <td colSpan={"5"}></td>
                                <td>
                                  <strong> Number of driver </strong>
                                </td>
                                <td>
                                  <span
                                    className={"badge badge-pill badge-dark"}
                                  >
                                    {
                                      this.state?.order_detail?.order
                                        ?.billing_detail?.number_of_driver
                                    }
                                  </span>
                                </td>
                              </tr>
                              <tr>
                                <td colSpan={"5"}></td>
                                <td>
                                  <strong> per driver fee</strong>
                                </td>
                                <td>
                                  <span
                                    className={"badge badge-pill badge-dark"}
                                  >
                                    $
                                    {
                                      this.state?.order_detail?.order
                                        ?.billing_detail?.per_driver_fee
                                    }
                                  </span>
                                </td>
                              </tr>
                            </>
                          )}
                          <tr>
                            <td colSpan={"5"}></td>
                            <td>
                              <strong> deposit fee</strong>
                            </td>
                            <td>
                              <span className={"badge badge-pill badge-dark"}>
                                $
                                {parseFloat(
                                  this.state?.order_detail?.order
                                    ?.billing_detail?.deposit_fee
                                ).toFixed(2)}
                              </span>
                            </td>
                          </tr>

                          {/* {console.log(,'thisisstate')} */}
                          {
                            <tr>
                              <td colSpan={"5"}></td>
                              <td>
                                <strong>Discount</strong>
                              </td>
                              <td>
                                <span className={"badge badge-pill badge-dark"}>
                                  $
                                  {this.state?.order_detail?.order?.order_type
                                    ?.length > 0 &&
                                  (this.state?.order_detail?.order?.order_type?.includes(
                                    "RESERVATION"
                                  ) ||
                                    this.state?.order_detail?.order?.order_type.includes(
                                      "LEAD"
                                    ))
                                    ? this.state?.order_detail?.order?.discount
                                    : "00"}{!this.state?.order_detail?.order?.discount && 0}
                                </span>
                              </td>
                            </tr>
                          }

                          {additionalFee.map((item, index) => (
                            <tr key={index}>
                              <td colSpan={"5"}></td>
                              <td>
                                {" "}
                                <strong> {item.name} </strong>
                              </td>
                              <td>
                                <span className={"badge badge-pill badge-dark"}>
                                  ${item.price}
                                </span>
                              </td>
                              {/* <td>{item.price}</td> */}
                            </tr>
                          ))}

                          <tr>
                            <td colSpan={"5"}></td>
                            <td className={"bg-dark"}>
                              <strong className={"text-light"}>
                                {" "}
                                total bill
                              </strong>
                            </td>
                            <td className={"bg-dark"}>
                              <span className={"text-light"}>
                                {/* {console.log(
                                  this.state?.order_detail?.order
                                    ?.provider_bill +
                                    this.state?.order_detail?.order
                                      ?.billing_detail?.deposit_fee +
                                    totalPrice +
                                    this.state?.order_detail?.order
                                      ?.billing_detail?.mileage_rate,
                                  "the total bill is"
                                )} */}
                                $
                                {parseFloat(
                                  Number(
                                    this.state?.order_detail?.order
                                      ?.provider_bill
                                  ) +
                                    Number(
                                      this.state?.order_detail?.order
                                        ?.billing_detail?.deposit_fee
                                    )
                                  // +
                                  // Number(totalPrice ? totalPrice : null)
                                ).toFixed(2)}{" "}
                              </span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    {/*<div class="col-12 col-md-12 text-right">
											<button class="myBtn btn-danger mr-2" >
												Cancel Order
											</button>
											<button class="myBtn">
												Accept Order
											</button>
										</div>*/}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <PageFooter />
      </React.Fragment>
    );
  }
}

export default TruckOrderDetail;
