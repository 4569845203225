import {adminApiCall} from "./ajax_request";
import {notify} from "react-notify-toast";

const multiSelectlisting = async (url, jsonObject) => {
    try{
        let res = await adminApiCall(url,'POST', jsonObject)
        if (!res) {
            notify.show("Something_went_wrong",'error',2000);
            return false;
        }
        if ( res.hasOwnProperty('status') ) {
            if ( res.status === 200 && res.data ) {
                return res.data
            } else if ( res.status === 400 ) {
                notify.show(res.message,'error',2000);
            }
        }
    }
    catch ( e ){
        notify.show("Something_gone_wrong",'error',2000);
    }
    finally {
    }
}

export {
    multiSelectlisting,
}
