/**
 * @author Basit Raza <razabasit88@gmail.com>
 * @link http://be.net/basit_raza Author Website
 * @since 2021-01-18
 */
import React from "react";
import PageHeader from "../../../../components/page-header";
import ChangeTitle from "../../../../components/change_page_title";
import PageFooter from "../../../../components/page-footer";
import SideBar from "../../../../components/side-bar";
import BreadCrumb from "../../../../components/bread-crumb";
import $ from "jquery";
import {
  adminApiCall,
  adminApiCallForFormData,
} from "../../../../utils/ajax_request";
import Notifications, { notify } from "react-notify-toast";
import Swal from "sweetalert2";
import ReactFancyBox from "react-fancybox";
import "react-fancybox/lib/fancybox.css";
import {
  baseDomain,
  storageProvider,
  truckRental,
  truckProvider,
} from "../../../../config";
import { Multiselect } from "multiselect-react-dropdown";
import { multiSelectlisting } from "../../../../utils/api";
import {
  onMultiRemoveHandle,
  onMultiSelectHandle,
} from "../../../../utils/helpers";

class AddCountry extends React.Component {
  breadcrumbNavigation = [
    {
      title: "Truck Management",
      link: "#",
    },
    {
      title: "Edit Truck Type",
      link: "#",
    },
  ];
  state = {
    recordDetail: "",
    isMediaFileExist: false,
    multi_select_selected_values: "",
    multi_select_preselected_values: "",
    amenities_list: [],

    multi_select_truck_type_selected_values: "",
    multi_select_truck_type_preselected_values: "",
    truck_type_list: [],
  };
  componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search);
    const id = urlParams.get("id");
    if (!id) {
      Swal.fire({
        title: "Error",
        text: "Invalid_access_error",
        icon: "error",
      });
    } else {
      this.getRecordDetail(id);
    }
  }
  amenitiesSelectListing = async () => {
    let jsonObject = {
      // type: storageProvider
      type: truckRental,
    };
    await multiSelectlisting(
      "amenities/amenities_select_list",
      JSON.stringify(jsonObject)
    ).then((result) => {
      if (
        this.state?.recordDetail?.amenities_ids &&
        this.state?.recordDetail?.amenities_ids?.length > 0 &&
        this.state?.recordDetail?.amenities_ids[0] !== " "
      ) {
        let multiSelectPreSelectOptionsList =
          this.state.recordDetail.amenities_ids.map((ele, index) => {
            return result.find((obj) => obj._id == ele);
          });
        this.setState({
          multi_select_preselected_values:
            multiSelectPreSelectOptionsList.includes(undefined)
              ? []
              : multiSelectPreSelectOptionsList,
        });
      }
      this.setState({ amenities_list: result?.length > 0 ? result : [] });
    });
  };

  truckTypeSelectListing = async () => {
    await multiSelectlisting(
      "truck_provider/truck_types/truck_type_select"
    ).then((result) => {
      if (
        this.state?.recordDetail?.truck_types_ids &&
        this.state?.recordDetail?.truck_types_ids?.length > 0 &&
        this.state?.recordDetail?.truck_types_ids[0] !== " "
      ) {
        let multiSelectPreSelectOptionsList =
          this.state.recordDetail.truck_types_ids.map((ele, index) => {
            return result.find((obj) => obj._id == ele);
          });
        this.setState({
          multi_select_truck_type_preselected_values:
            multiSelectPreSelectOptionsList.includes(undefined)
              ? []
              : multiSelectPreSelectOptionsList,
        });
      }
      this.setState({ truck_type_list: result?.length > 0 ? result : [] });
    });
  };

  getRecordDetail = async (id) => {
    try {
      $("#loader").show();
      let res = await adminApiCall(
        "search_filter_category/get",
        "POST",
        JSON.stringify({ search_filter_category_id: id, type: truckRental })
      );
      if (!res) {
        Swal.fire({
          title: "Error",
          text: "Something_went_wrong",
          icon: "error",
        });
        return false;
      }
      if (res.hasOwnProperty("status")) {
        console.log(res);
        if (res?.status === 200 && res?.data) {
          this.setState({
            recordDetail: res?.data,
            isMediaFileExist: res?.data?.media_file?.[0]?.url,
          });
          // this.multiSelectlisting()
          this.amenitiesSelectListing();
          this.truckTypeSelectListing();
        } else if (res?.status === 400) {
          Swal.fire({
            title: "Error",
            text: res?.message,
            icon: "error",
          });
        }
      }
    } catch (e) {
      Swal.fire({
        title: "Error",
        text: "Something_gone_wrong",
        icon: "error",
      });
    } finally {
      $("#loader").hide();
    }
  };
  handleSubmit = async (event) => {
    try {
      $("#loader").show();
      event.preventDefault();
      let formData = new FormData(event.target);
      formData.append(
        "search_filter_category_id",
        this.state?.recordDetail?._id
      );
      // formData.append("amenities_ids", this.state.multi_select_selected_values ? this.state?.multi_select_selected_values : this.state?.multi_select_preselected_values.map(e => e._id).join(","));
      formData.append("amenities_ids", this.state.multi_select_selected_values);

      formData.append(
        "truck_types_ids",
        this.state.multi_select_truck_type_selected_values
          ? this.state?.multi_select_truck_type_selected_values
          : this.state?.multi_select_truck_type_preselected_values
              .map((e) => e._id)
              .join(",")
      );
      formData.append("type", truckRental);
      let res = await adminApiCallForFormData(
        "search_filter_category/update",
        formData
      );
      if (!res) {
        notify.show("Something_went_wrong", "error", 2000);
        return false;
      }
      if (res.hasOwnProperty("status")) {
        if (res.status == 200) {
          notify.show(res.message, "success", 2000);
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        } else if (res.status == 400) {
          notify.show(res.message, "error", 2000);
        }
      }

      console.log(
        "this.state.multi_select_selected_values",
        this.state.multi_select_selected_values
      );
    } catch (e) {
      console.log(e);
      notify.show("Something_gone_wrong", "error", 2000);
    } finally {
      $("#loader").hide();
    }
  };
  onAmenitySelectHandle = async (selectedList, selectedItem) => {
    await onMultiSelectHandle(selectedList).then((result) => {
      this.setState({ multi_select_selected_values: result });
    });
  };
  onAmenityRemoveHandle = async (selectedList, selectedItem) => {
    await onMultiRemoveHandle(selectedList).then((result) => {
      this.setState({ multi_select_selected_values: result });
    });
  };
  onTruckTypeSelectHandle = async (selectedList, selectedItem) => {
    await onMultiSelectHandle(selectedList).then((result) => {
      this.setState({ multi_select_truck_type_selected_values: result });
    });
  };
  onTruckTypeRemoveHandle = async (selectedList, selectedItem) => {
    await onMultiRemoveHandle(selectedList).then((result) => {
      this.setState({ multi_select_truck_type_selected_values: result });
    });
  };
  render() {
    return (
      /*Content Area*/
      <React.Fragment>
        <ChangeTitle title={"Truck categories"} />

        {/*SideBar*/}
        <SideBar />

        {/*Page Header*/}
        <PageHeader />
        <Notifications options={{ zIndex: 10000 }} />
        <BreadCrumb navigation={this.breadcrumbNavigation} />

        {/* main content starts here */}

        <section class="pageWrapper">
          <div class="container-fluid">
            <div class="row">
              <div class="col-12">
                <div class="contentCard">
                  <div class="cardHeader clearfix">
                    <h1>Edit Truck Category</h1>
                  </div>
                  <div class="cardBody">
                    <form onSubmit={this.handleSubmit}>
                      <div className="row formWrapper">
                        <div className="col-12 col-md-12">
                          <div className="form-group inlineField">
                            <div className="row align-items-center">
                              <div className="col-12 col-md-4 col-lg-3">
                                <label>Name</label>
                              </div>
                              <div className="col-12 col-md-8 col-lg-9">
                                <input
                                  type="text"
                                  name={"name"}
                                  value={this.state?.recordDetail?.name}
                                  onChange={(e) =>
                                    this.setState({
                                      recordDetail: {
                                        ...this.state.recordDetail,
                                        name: e.target.value,
                                      },
                                    })
                                  }
                                  onKeyPress={this.onKeyPressHandle}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-md-12">
                          <div className="form-group inlineField">
                            <div className="row align-items-center">
                              <div className="col-12 col-md-4 col-lg-3">
                                <label>Truck Type(s) </label>
                              </div>
                              <div className="col-12 col-md-8 col-lg-9">
                                <Multiselect
                                  options={this.state?.truck_type_list}
                                  displayValue={"title"}
                                  selectedValues={
                                    this.state
                                      ?.multi_select_truck_type_preselected_values
                                  }
                                  onSelect={(list, item) =>
                                    this.onTruckTypeSelectHandle(list, item)
                                  }
                                  onRemove={(list, item) =>
                                    this.onTruckTypeRemoveHandle(list, item)
                                  }
                                ></Multiselect>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-md-12">
                          <div className="form-group inlineField">
                            <div className="row align-items-center">
                              <div className="col-12 col-md-4 col-lg-3">
                                <label>Amenities </label>
                              </div>
                              <div className="col-12 col-md-8 col-lg-9">
                                <Multiselect
                                  options={this.state?.amenities_list}
                                  displayValue={"title"}
                                  selectedValues={
                                    this.state?.multi_select_preselected_values
                                  }
                                  onSelect={(list, item) =>
                                    this.onAmenitySelectHandle(list, item)
                                  }
                                  onRemove={(list, item) =>
                                    this.onAmenityRemoveHandle(list, item)
                                  }
                                ></Multiselect>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-md-12">
                          <div className="form-group inlineField">
                            <div className="row align-items-center">
                              <div className="col-12 col-md-4 col-lg-3">
                                <label>Media Files</label>
                              </div>
                              <div className="col-12 col-md-8 col-lg-9">
                                {this.state?.isMediaFileExist ? (
                                  this.state?.recordDetail?.media_file?.[0]
                                    ?.mime_type !== "video" ? (
                                    <>
                                      <span
                                        className={
                                          "change-cursor-image-span-hover"
                                        }
                                        onClick={() =>
                                          this.setState({
                                            isMediaFileExist: false,
                                          })
                                        }
                                      >
                                        X
                                      </span>
                                      <ReactFancyBox
                                        thumbnail={
                                          this.state?.recordDetail &&
                                          baseDomain +
                                            this.state?.recordDetail
                                              ?.media_file?.[0]?.url
                                        }
                                        image={
                                          this.state?.recordDetail &&
                                          baseDomain +
                                            this.state?.recordDetail
                                              ?.media_file?.[0]?.url
                                        }
                                      />
                                    </>
                                  ) : (
                                    <>
                                      <video width="100" height="100" controls>
                                        <source
                                          src={
                                            this.state?.recordDetail &&
                                            baseDomain +
                                              this.state?.recordDetail
                                                ?.media_file?.[0]?.url
                                          }
                                          type="video/mp4"
                                        />
                                      </video>
                                      <span
                                        className={
                                          "change-cursor-image-span-hover"
                                        }
                                        onClick={() =>
                                          this.setState({
                                            isMediaFileExist: false,
                                          })
                                        }
                                      >
                                        X
                                      </span>
                                    </>
                                  )
                                ) : (
                                  <div className="inputFile">
                                    <input
                                      name="media_file"
                                      type="file"
                                      multiple
                                    />
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-md-12">
                          <div className="form-group inlineField">
                            <div className="row align-items-center">
                              <div className="col-12 col-md-4 col-lg-3">
                                <label>Description</label>
                              </div>
                              <div className="col-12 col-md-8 col-lg-9">
                                <textarea
                                  name="description"
                                  rows="5"
                                  onKeyPress={this.onKeyPressHandle}
                                  value={this.state?.recordDetail?.description}
                                  onChange={(e) =>
                                    this.setState({
                                      recordDetail: {
                                        ...this.state.recordDetail,
                                        description: e.target.value,
                                      },
                                    })
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-md-12">
                          <div className="form-group inlineField">
                            <div className="row align-items-center">
                              <div className="col-12 col-md-4 col-lg-3">
                                <label>Status</label>
                              </div>
                              <div className="col-12 col-md-8 col-lg-9">
                                <div className="toggleBtn">
                                  <input
                                    type="checkbox"
                                    id="togglePrice"
                                    checked={
                                      this.state?.recordDetail?.status === "1"
                                        ? "checked"
                                        : null
                                    }
                                    name={"status"}
                                    onChange={(e) =>
                                      this.setState({
                                        recordDetail: {
                                          ...this.state.recordDetail,
                                          status: e.target.checked,
                                        },
                                      })
                                    }
                                  />
                                  <label htmlFor="togglePrice">
                                    <span className="on">ON</span>
                                    <span className="off">OFF</span>
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-12 col-md-12 text-right">
                          <button
                            type={"submit"}
                            class="myBtn"
                            onClick={this.register}
                          >
                            Update{" "}
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <PageFooter />
      </React.Fragment>
    );
  }
}

export default AddCountry;
