import React from "react";
import ChangeTitle from "../../../../components/change_page_title";
import SideBar from "../../../../components/side-bar";
import PageHeader from "../../../../components/page-header";
import BreadCrumb from "../../../../components/bread-crumb";
import Notifications, { notify } from "react-notify-toast";
import Swal from "sweetalert2";
class EditLeads extends React.Component {
  state = {
    recordDetails: "",
    isIconExist: false,
  };
  breadcrumbNavigation = [
    {
      title: "Manage Leads",
      link: "#",
    },
    {
      title: "Edit Leads",
      link: "#",
    },
  ];
  listing = [
    {
      id: 1,
      user: "Mike Johnson",
      provider: "123 Freight",
      detail: "Details of the truck",
      truck: "Ram 1500",
      url: "www.youtube.com",
    },
    {
      id: 2,
      user: "Emily Davis",
      provider: "123 Freight",
      detail: "Details of the truck",
      truck: "Ram 1500",
      url: "www.youtube.com",
    },
    {
      id: 3,
      user: "Jane Smith",
      provider: "123 Freight",
      detail: "Details of the truck",
      truck: "Ram 1500",
      url: "www.youtube.com",
    },
    {
      id: 4,
      user: "John Doe",
      provider: "123 Freight",
      detail: "Details of the truck",
      truck: "Ram 1500",
      url: "www.youtube.com",
    },
    {
      id: 5,
      user: "Keenu Reves",
      provider: "123 Freight",
      detail: "Details of the truck",
      truck: "Ram 1500",
      url: "www.youtube.com",
    },
  ];
  componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search);
    const id = urlParams.get("id");
    console.log(id, "fadsfasdfasfdasId");
    if (!id) {
      Swal.fire({
        title: "Error",
        text: "Invalid_access_error",
        icon: "error",
      });
    } else {
      this.getRecordDetail(id);
    }
  }
  getRecordDetail = (id) => {
    const obj = this.listing.find((element) => element.id === Number(id));
    this.setState({
      recordDetails: obj,
      isIconExist: false,
    });
  };

  render() {
    return (
      <>
        <ChangeTitle title="Manage Leads" />
        <SideBar />
        <PageHeader />
        <Notifications options={{ zIndex: 10000 }} />
        <BreadCrumb navigation={this.breadcrumbNavigation} />
        <section class="pageWrapper">
          <div class="container-fluid">
            <div class="row">
              <div class="col-12">
                <div class="contentCard">
                  <div class="cardHeader clearfix">
                    <h1>Edit Leads</h1>
                  </div>
                  <div class="cardBody">
                    <form onSubmit={this.handleSubmit}>
                      <div className="row formWrapper">
                        <div className="col-12 col-md-12">
                          <div className="form-group inlineField">
                            <div className="row align-items-center">
                              <div className="col-12 col-md-4 col-lg-3">
                                <label>User</label>
                              </div>
                              <div className="col-12 col-md-8 col-lg-9">
                                <input
                                  value={this.state.recordDetails.user}
                                  onChange={(e) =>
                                    this.setState({
                                      recordDetails: {
                                        ...this.state.recordDetails,
                                        user: e.target.value,
                                      },
                                    })
                                  }
                                  type="text"
                                  name={"user"}
                                  placeholder="Please enter your name"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-md-12">
                          <div className="form-group inlineField">
                            <div className="row align-items-center">
                              <div className="col-12 col-md-4 col-lg-3">
                                <label>Provider</label>
                              </div>
                              <div className="col-12 col-md-8 col-lg-9">
                                <input
                                  value={this.state.recordDetails.provider}
                                  onChange={(e) =>
                                    this.setState({
                                      recordDetails: {
                                        ...this.state.recordDetails,
                                        provider: e.target.value,
                                      },
                                    })
                                  }
                                  type="text"
                                  name={"provider"}
                                  placeholder="Please enter email address"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-md-12">
                          <div className="form-group inlineField">
                            <div className="row align-items-center">
                              <div className="col-12 col-md-4 col-lg-3">
                                <label>Details</label>
                              </div>
                              <div className="col-12 col-md-8 col-lg-9">
                                <input
                                  value={this.state.recordDetails.detail}
                                  onChange={(e) =>
                                    this.setState({
                                      recordDetails: {
                                        ...this.state.recordDetails,
                                        detail: e.target.value,
                                      },
                                    })
                                  }
                                  name="Details"
                                  type="text"
                                  placeholder="Please enter your phone number"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-md-12">
                          <div className="form-group inlineField">
                            <div className="row align-items-center">
                              <div className="col-12 col-md-4 col-lg-3">
                                <label>Truck</label>
                              </div>
                              <div className="col-12 col-md-8 col-lg-9">
                                <input
                                  value={this.state.recordDetails.truck}
                                  onChange={(e) =>
                                    this.setState({
                                      recordDetails: {
                                        ...this.state.recordDetails,
                                        truck: e.target.value,
                                      },
                                    })
                                  }
                                  name="truck"
                                  type="text"
                                  placeholder="Please enter provider"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-md-12">
                          <div className="form-group inlineField">
                            <div className="row align-items-center">
                              <div className="col-12 col-md-4 col-lg-3">
                                <label>Edit Leads</label>
                              </div>
                              <div className="col-12 col-md-8 col-lg-9">
                                <input
                                  value={this.state.recordDetails.url}
                                  onChange={(e) =>
                                    this.setState({
                                      recordDetails: {
                                        ...this.state.recordDetails,
                                        url: e.target.value,
                                      },
                                    })
                                  }
                                  name="truck"
                                  type="text"
                                  placeholder="Please enter provider"
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="col-12 col-md-12 text-right">
                          <button
                            type={"submit"}
                            class="myBtn"
                          >
                            Add{" "}
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default EditLeads;
