import React from 'react';
import PageHeader from '../../../components/page-header';
import ChangeTitle from '../../../components/change_page_title';
import PageFooter from '../../../components/page-footer';
import SideBar from '../../../components/side-bar';
import BreadCrumb from '../../../components/bread-crumb';
import Notifications, {notify} from "react-notify-toast";
// date time picker
import DateRangePicker from 'react-bootstrap-daterangepicker';
// you will also need the css that comes with bootstrap-daterangepicker
import 'bootstrap-daterangepicker/daterangepicker.css';
import Swal from "sweetalert2";
import $ from "jquery";
import {adminApiCall, adminApiCallForFormData} from "../../../utils/ajax_request";

class EditUser extends React.Component {

	breadcrumbNavigation = [
		{
			title: 'Manage User',
			link: '#'
		},
		{
			title: 'Edit User',
			link: '#'
		}
	]
	state = {
		recordDetail: '',
		isIconExist : false,
	}
	componentDidMount () {
		const urlParams = new URLSearchParams(window.location.search);
		const id = urlParams.get('id');
		if(!id){
			Swal.fire({
				title: "Error",
				text: "Invalid_access_error",
				icon: 'error',
			})
		}
		else {
			this.getRecordDetail(id)
		}
	}
	getRecordDetail = async (id) => {
		try{
			$('#loader').show();
			let res = await adminApiCall('get_profile','POST' , JSON.stringify({user_id: id}))
			if (!res) {
				Swal.fire({
					title: "Error",
					text: "Something_went_wrong",
					icon: 'error',
				})
				return false;
			}
			if ( res.hasOwnProperty('status') ) {
				console.log(res);
				if ( res?.status === 200 && res?.data ) {
					this.setState({recordDetail: res?.data });
				} else if ( res?.status === 400 ) {
					Swal.fire({
						title: "Error",
						text: res?.message,
						icon: 'error',
					})
				}
			}
		}
		catch ( e ){
			Swal.fire({
				title: "Error",
				text: "Something_gone_wrong",
				icon: 'error',
			})
		}
		finally {
			$('#loader').hide();
		}
	}
	handleSubmit = async (event) => {
		try{
			$('#loader').show();
			event.preventDefault()
			let jsonData = {
				admin_id: this.state?.recordDetail?._id,
				...this.state?.recordDetail?.first_name && {first_name: this.state?.recordDetail?.first_name},
				...this.state?.recordDetail?.last_name && {last_name: this.state?.recordDetail?.last_name},
				...this.state?.recordDetail?.email && {email: this.state?.recordDetail?.email},
				...this.state?.recordDetail?.phone && {phone: this.state?.recordDetail?.phone},
				is_super_admin: this.state?.recordDetail?.role && (this.state?.recordDetail?.role === 'super_admin'? true: false),
				role: this.state?.recordDetail?.role
			}
			let res = await adminApiCall('update_profile',"POST",JSON.stringify(jsonData))
			if (!res) {
				notify.show("Something_went_wrong",'error',2000);
				return false;
			}
			if ( res.hasOwnProperty('status') ) {
				if ( res.status == 200 ) {
					notify.show(res.message,'success',2000);
					setTimeout(()=>{window.location.reload()},2000)
				} else if ( res.status == 400 ) {
					notify.show(res.message,'error',2000);
				}else{
					notify.show(res.message,'error',2000);
				}
			}
		}catch (e) {
			console.log(e);
			notify.show("Something_gone_wrong",'error',2000);
		}finally {
			$('#loader').hide();
		}

	}
	render() {
		return (
			/*Content Area*/
			<React.Fragment>

				<ChangeTitle title={'Manage User'} />

				{/*SideBar*/}
				<SideBar />

				{/*Page Header*/}
				<PageHeader />
				<Notifications options={{zIndex: 10000}}/>
				<BreadCrumb navigation={this.breadcrumbNavigation} />

				{/* main content starts here */}
				<section class="pageWrapper">
					<div class="container-fluid">
						<div class="row">
							<div class="col-12">
								<div class="contentCard">
									<div class="cardHeader">
										<h1>Edit User</h1>
									</div>
									<div class="cardBody formWrapper">
										<form>
											<div className="row">
												<div className="col-12">
													<h2 class="cardInnerTitle clearfix">User Information</h2>
												</div>
												<div className="col-12 col-md-4">
													<label>First Name</label>
													<input type="text" value={this.state?.recordDetail?.first_name}
														   onChange={(e)=> this.setState({recordDetail: {...this.state.recordDetail, first_name : e.target.value }})}
													>
													</input>
												</div>
												<div className="col-12 col-md-4">
													<label>Last Name</label>
													<input type="text" value={this.state?.recordDetail?.last_name}
														   onChange={(e)=> this.setState({recordDetail: {...this.state.recordDetail, last_name : e.target.value }})}
													>
													</input>
												</div>
												<div className="col-12 col-md-4">
													<label>Email</label>
													<input type="text" value={this.state?.recordDetail?.email}
														   onChange={(e)=> this.setState({recordDetail: {...this.state.recordDetail, email : e.target.value }})}
													>
													</input>
												</div>
												<div className="col-12 col-md-4">
													<label>Phone Number</label>
													<input type="text" value={this.state?.recordDetail?.phone}
														   onChange={(e)=> this.setState({recordDetail: {...this.state.recordDetail, phone : e.target.value }})}
													>
													</input>
												</div>
											</div>
											<div className="text-right">
												<button className="myBtn mt-2" onClick={this.handleSubmit}>Update</button>
											</div>
										</form>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
				<PageFooter />
			</React.Fragment>
		);
	}
}

export default EditUser;

