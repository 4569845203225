import React from "react";
import PageHeader from "../../../components/page-header";
import ChangeTitle from "../../../components/change_page_title";
import PageFooter from "../../../components/page-footer";
import SideBar from "../../../components/side-bar";
import BreadCrumb from "../../../components/bread-crumb";
import Notifications, { notify } from "react-notify-toast";
import Select from "react-select";
import $ from "jquery";
import { adminApiCall, adminRegister } from "../../../utils/ajax_request";
import {
  toLowerCase,
  snakeToCamel,
  dateTimeFormat,
} from "../../../utils/helpers";
// you will also need the css that comes with bootstrap-daterangepicker
import "bootstrap-daterangepicker/daterangepicker.css";
import Swal from "sweetalert2";
import DateRangePicker from "react-bootstrap-daterangepicker";
import Pagination from "react-js-pagination";
import {
  pending,
  inProgress,
  cancelled,
  complete,
  
} from "../../../config";

class TruckOrderList extends React.Component {
  breadcrumbNavigation = [
    {
      title: "Manage Orders",
      link: "#",
    },
    {
      title: "Truck Orders Listing",
      link: "truck-orders-list",
    },
  ];
  state = {
    list: [],
    filters_data: {
      order_date: "",
      user_email: "",
      company_name: "",
      order_id: "",
      order_status: "",
    },
    consumers_list: [],
    providers_list: [],
    pagination: {
      current_page: 1,
      total_pages: "",
      total_records: "",
      itemsCountPerPage: 24,
    },
  };

  componentDidMount() {
    this.listing();
    this.consumersListing();
    this.providersListing();
  }

  listing = async (page) => {
    try {
      $("#loader").show();
      // if(!data){
      let data = {
        current_page: page ? page : this.state?.pagination?.current_page,
        filters_data: this.state?.filters_data,
      };
      // }
      let res = await adminApiCall(
        "order/truck_order/list",
        "POST",
        JSON.stringify(data)
      );
      if (!res) {
        notify.show("Something_went_wrong", "error", 2000);
        return false;
      }
      if (res.hasOwnProperty("status")) {
        if (res.status === 200 && res.data) {
          this.setState({
            list: res.data,
            pagination: {
              ...this.state.pagination,
              current_page: res.current_page,
              total_pages: res.total_pages,
              total_records: res.total_records,
            },
          });
        } else if (res.status === 400) {
          this.setState({ list: [] });
          notify.show(res.message, "error", 2000);
        } else {
          this.setState({ list: [] });
        }
      }
    } catch (e) {
      notify.show("Something_gone_wrong", "error", 2000);
    } finally {
      $("#loader").hide();
    }
  };
  //=============== get consumers list her e=========
  consumersListing = async () => {
    try {
      let res = await adminApiCall(
        "order/storage_unit_order/consumer_select_list",
        "POST"
      );
      if (!res) {
        notify.show("Something_went_wrong", "error", 2000);
        return false;
      }
      if (res.hasOwnProperty("status")) {
        if (res.status === 200 && res.data) {
          let consumers = res.data.map((data) => {
            return {
              value: data.email,
              label: data.email,
            };
          });
          this.setState({ consumers_list: consumers });
        } else if (res.status === 400) {
          this.setState({ list: [] });
          notify.show(res.message, "error", 2000);
        } else {
          this.setState({ consumers_list: [] });
        }
      }
    } catch (e) {
      notify.show("Something_gone_wrong", "error", 2000);
    } finally {
    }
  };
  //=============== get consumers list her e=========
  providersListing = async () => {
    try {
      let res = await adminApiCall(
        "order/truck_order/truck_provider_select_list",
        "POST"
      );
      if (!res) {
        notify.show("Something_went_wrong", "error", 2000);
        return false;
      }
      if (res.hasOwnProperty("status")) {
        if (res.status === 200 && res.data) {
          let providers = res.data.map((data) => {
            return {
              value: data.user_name,
              label: data.user_name,
            };
          });
          this.setState({ providers_list: providers });
        } else if (res.status === 400) {
          this.setState({ list: [] });
          notify.show(res.message, "error", 2000);
        } else {
          this.setState({ providers_list: [] });
        }
      }
    } catch (e) {
      notify.show("Something_gone_wrong", "error", 2000);
    } finally {
    }
  };

  //===== change status of user =========
  changeStatus = async (e, email, id) => {
    let confirm = window.confirm(
      `Are you sure you want to ${
        e.target.checked === true ? `verified` : `Unverified`
      } this user?`
    );
    if (confirm) {
      if (email) {
        try {
          $("#loader").show();
          let res = await adminApiCall(
            "truck_provider/varify_truck_provider",
            "POST",
            JSON.stringify({
              user_id: id,
              email: email,
              status: e.target.checked,
            })
          );
          if (!res) {
            notify.show("Something_went_wrong", "error", 2000);
            return false;
          }
          if (res.hasOwnProperty("status")) {
            if (res.status === 200 && res.data) {
              notify.show(res.message, "success", 2000);
              setTimeout(() => {
                this.listing();
              }, 2000);
              this.renderListing();
            } else if (res.status === 400) {
              notify.show(res.message, "error", 2000);
            }
          }
        } catch (e) {
          notify.show("Something_gone_wrong", "error", 2000);
        } finally {
          $("#loader").hide();
        }
      } else {
        notify.show("Please choose admin to verify", "error", 2000);
      }
    }
  };

  renderListing = () => {
    let listing = [];
    if (this.state.list && this.state.list.length > 0) {
      console.log(this.state.list, "list of total");
      try {
        for (const [index, element] of this.state.list.entries()) {
          console.log(element.total_bill, "totalBillllllll");
          listing.push(
            <React.Fragment key={element?._id}>
              <tr>
                <td>{index + 1}</td>
                <td>
                  <span className={"badge badge-pill badge-danger"}>
                    {element?.order_id}
                  </span>
                </td>
                <td>{element?.Transaction_id}</td>
                <td>{element?.orderer_info?.email}</td>
                <td>{element?.company_info?.company_name}</td>
                <td>
                  <span className={"badge badge-pill badge-dark"}>
                    {parseFloat(element?.deposit_fee).toFixed(2)}
                  </span>
                </td>
                <td>
                  <span className={"badge badge-pill badge-dark"}>
                    {parseFloat(element?.provider_bill).toFixed(2)}
                  </span>
                </td>
                <td>
                  <span className={"badge badge-pill badge-dark"}>
                    {parseFloat(Number(element?.total_bill)).toFixed(2)}
                  </span>
                </td>
                <td>
                  <span className={"badge badge-pill badge-info"}>
                    {toLowerCase(element?.order_status)}
                  </span>
                </td>
                <td>
                  {element?.created_at && dateTimeFormat(element?.created_at)}
                </td>
                <td>
                  {/*<button className="myBtn btn-danger btnSm mx-1" onClick={this.hitFire}>*/}
                  {/*	<i class="fa fa-trash"></i>*/}
                  {/*</button>*/}
                  {/*<button class="myBtn btn-success btnSm mx-1" data-toggle="modal"
											data-target="#chagneInfo">
										<i class="fa fa-edit"></i>
									</button>*/}
                  <button
                    className="myBtn btn-success btnSm"
                    data-toggle="modal"
                    data-target="#changeInfo"
                    onClick={() =>
                      this.props.history.push(
                        `truck-order-detail?id=${element?._id}&order_id=${element?.order_id}`
                      )
                    }
                  >
                    <i className=" fa fa-eye"></i>
                  </button>
                </td>
              </tr>
            </React.Fragment>
          );
        }
      } catch (e) {
        notify.show("Something_gone_wrong", "error", 2000);
      }
      return listing;
    } else {
      listing.push(
        <tr>
          <td colSpan={10}>No Record found</td>
        </tr>
      );
      return listing;
    }
  };
  // notify toaste start here
  notify() {
    notify.show("res.message", "error", 2000);
  }

  hitFire = async () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      // let resp = await  adminApiCall('store_provider/list','POST' , JSON.stringify(data));
      if (result.isConfirmed) {
        Swal.fire("Deleted!", "Your file has been deleted.", "success");
      }
    });
  };

  hitBann() {
    Swal.fire({
      title: "Are you sure?",
      text: "You Want to bann this User",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes!",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire("Banned!", "This user is banned now.", "success");
      }
    });
  }

  searchHandle = () => {
    this.listing();
  };

  handlePageChange(pageNumber) {
    this.setState({
      pagination: { ...this.state.pagination, current_page: pageNumber },
    });
    this.listing(pageNumber);
  }
  //=======selected options
  selectedOption = (value, user_type) => {
    if (user_type === "consumer" && value)
      this.setState({
        filters_data: { ...this.state?.filters_data, user_email: value.value },
      });
    else if (user_type === "provider" && value)
      this.setState({
        filters_data: {
          ...this.state?.filters_data,
          company_name: value.value,
        },
      });
    else
      this.setState({
        filters_data: {
          ...this.state?.filters_data,
          company_name: "",
          user_email: "",
        },
      });
  };
  handleChangeDateEvent = (event) => {
    this.setState({
      filters_data: {
        ...this.state?.filters_data,
        order_date: event.target.value,
      },
    });
  };
  render() {
    return (
      /*Content Area*/
      <React.Fragment>
        <ChangeTitle title={"Manage User"} />

        {/*SideBar*/}
        <SideBar />

        {/*Page Header*/}
        <PageHeader />

        <Notifications options={{ zIndex: 10000 }} />

        <BreadCrumb navigation={this.breadcrumbNavigation} />

        {/* main content starts here */}
        <section class="pageWrapper">
          <div class="container-fluid">
            <div class="row">
              <div class="col-12">
                <div class="contentCard">
                  <div class="cardHeader clearfix">
                    <svg
                      className="mr-1"
                      enable-background="new 0 0 20 20"
                      height="20"
                      viewBox="0 0 512 512"
                      width="20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g>
                        <path d="m90.5 0h-30v362h-30v90h30v60h30v-60h30v-90h-30zm0 422h-30v-30h30z" />
                        <path d="m481.5 362h-30v-362h-30v362h-30v90h30v60h30v-60h30zm-30 60h-30v-30h30z" />
                        <path d="m331.5 0h-30v222h-30v90h30v200h30v-200h30v-90h-30zm0 282h-30v-30h30z" />
                        <path d="m210.5 0h-30v53.652h-30v90h30v368.348h30v-368.348h30v-90h-30zm0 113.652h-30v-30h30z" />
                      </g>
                    </svg>
                    <h1>Filters</h1>
                    <span class="toogleCard open">
                      <i class="fa fa-angle-down"></i>
                    </span>
                  </div>

                  <div class="cardBody">
                    <div class="row formWrapper">
                      <div class="col-6 col-md-6">
                        <div class="form-group inlineField">
                          <div class="row align-items-center">
                            <div class="col-12 col-md-3 col-lg-2">
                              <label>Order id:</label>
                            </div>
                            <div class="col-12 col-md-9 col-lg-10">
                              <input
                                type="text"
                                // value={this.state?.filterValues?.user_name}
                                onChange={(e) =>
                                  this.setState({
                                    filters_data: {
                                      ...this.state?.filters_data,
                                      order_id: e.target.value.trim(),
                                    },
                                  })
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-6 col-md-6">
                        <div class="form-group inlineField">
                          <div class="row align-items-center">
                            <div class="col-12 col-md-3 col-lg-2">
                              <label>Order status:</label>
                            </div>
                            <div class="col-12 col-md-9 col-lg-10">
                              <select
                                value={this.state?.filters_data?.order_status}
                                onChange={(e) =>
                                  this.setState({
                                    filters_data: {
                                      ...this.state?.filters_data,
                                      order_status: e.target.value.trim(),
                                    },
                                  })
                                }
                              >
                                <option value={pending}>
                                  {toLowerCase(pending)}
                                </option>
                                <option value={inProgress}>
                                  {toLowerCase(inProgress)}
                                </option>
                                <option value={cancelled}>
                                  {toLowerCase(cancelled)}
                                </option>
                                <option value={complete}>
                                  {toLowerCase(complete)}
                                </option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-6 col-md-6">
                        <div class="form-group inlineField">
                          <div class="row align-items-center">
                            <div class="col-12 col-md-3 col-lg-2">
                              <label>User email:</label>
                            </div>
                            <div class="col-12 col-md-9 col-lg-10">
                              <Select
                                onChange={(value) =>
                                  this.selectedOption(value, "consumer")
                                }
                                isSearchable
                                isClearable
                                options={this.state.consumers_list}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-6 col-md-6">
                        <div class="form-group inlineField">
                          <div class="row align-items-center">
                            <div class="col-12 col-md-3 col-lg-2">
                              <label>Company name:</label>
                            </div>
                            <div class="col-12 col-md-9 col-lg-10">
                              <Select
                                onChange={(value) =>
                                  this.selectedOption(value, "provider")
                                }
                                isSearchable
                                isClearable
                                options={this.state.providers_list}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-6 col-md-6">
                        <div class="form-group inlineField">
                          <div class="row align-items-center">
                            <div class="col-12 col-md-3 col-lg-2">
                              <label>Order Date:</label>
                            </div>
                            <div class="col-12 col-md-9 col-lg-10">
                              <DateRangePicker
                                initialSettings={{
                                  startDate: new Date(),
                                  endDate: new Date(),
                                }}
                                onEvent={this.handleChangeDateEvent}
                              >
                                <input type="text" name="" id="" />
                                {/* <button>Click Me To Open Picker!</button> */}
                              </DateRangePicker>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-12 col-md-12 text-right">
                        <button
                          className="myBtn"
                          onClick={() =>
                            this.setState(
                              {
                                filters_data: {
                                  order_id: "",
                                  order_status: "",
                                  order_date: "",
                                  user_email: "",
                                  company_name: "",
                                },
                              },
                              () => {
                                this.searchHandle();
                              }
                            )
                          }
                        >
                          Reset <i className="fa fa-refresh"></i>
                        </button>
                        <button
                          className="ml-3 myBtn"
                          onClick={this.searchHandle}
                        >
                          Search <i className="fa fa-search"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12">
                <div class="contentCard">
                  <div class="cardHeader">
                    <h1>All Truck Orders List</h1>
                  </div>
                  <div class="cardBody">
                    <div class="table-responsive customStriped">
                      <table class="table">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Order Id</th>
                            <th>Transaction Id</th>
                            <th>User Email</th>
                            <th>Company Name/Seller</th>
                            <th>Deposit Fee</th>
                            <th>Provider Bill</th>
                            <th>Total Bill</th>
                            <th>Order Status</th>
                            <th>Create Date</th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>{this.renderListing()}</tbody>
                      </table>
                    </div>
                    <div class="paginationWrapper clearfix">
                      <Pagination
                        activePage={this.state?.pagination?.current_page}
                        itemsCountPerPage={
                          this.state?.pagination?.itemsCountPerPage
                        }
                        totalItemsCount={this.state?.pagination?.total_records}
                        pageRangeDisplayed={this.state?.pagination?.total_pages}
                        onChange={this.handlePageChange.bind(this)}
                        itemClass={"page-item"}
                        linkClass={"page-link"}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div
          className="modal fade"
          id="changeInfo"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-xl" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Reset Password
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <svg
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    className="feather feather-x"
                  >
                    <line x1="18" y1="6" x2="6" y2="18"></line>
                    <line x1="6" y1="6" x2="18" y2="18"></line>
                  </svg>
                </button>
              </div>
              <div className="modal-body">
                <div className="row formWrapper">
                  <div className="col-12 col-md-6">
                    <div className="form-group">
                      <label>New Password:</label>
                      <input
                        type="text"
                        id={"new_password"}
                        value={this.state.changePassword?.newPassword}
                      />
                    </div>
                  </div>
                  <div className="col-12 col-md-6 generate-password-btn">
                    <button
                      className="myBtn btn-danger"
                      onClick={this.handleGeneratePassword}
                    >
                      {" "}
                      Generate Password
                    </button>
                  </div>
                </div>
                <div className={"row formWrapper"}>
                  <div className="col-12 col-md-6">
                    <span className={"error_msg"}>
                      {this.state?.changePassword?.errorMsg}
                    </span>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <div className="tableDetails text-center">
                  <button
                    className="myBtn btnSm btn-simple"
                    data-dismiss="modal"
                    onClick={() =>
                      this.setState({
                        changePassword: {
                          ...this.state?.changePassword,
                          newPassword: "",
                        },
                      })
                    }
                  >
                    {" "}
                    Cancel
                  </button>
                  <button
                    className="myBtn btnSm btn-success ml-2"
                    onClick={this.handleChangePassword}
                  >
                    Reset
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <PageFooter />
      </React.Fragment>
    );
  }
}

export default TruckOrderList;
