/**
 * @author Basit Raza <razabasit88@gmail.com>
 * @link http://be.net/basit_raza Author Website
 * @since 2021-01-18
 */
import React from 'react';
import PageHeader from '../../../components/page-header';
import ChangeTitle from '../../../components/change_page_title';
import PageFooter from '../../../components/page-footer';
import SideBar from '../../../components/side-bar';
import BreadCrumb from '../../../components/bread-crumb';
import $ from "jquery";
import {adminApiCall} from "../../../utils/ajax_request";
import Notifications, { notify } from 'react-notify-toast';
import Swal from "sweetalert2";
import Pagination from "react-js-pagination";
import { dateFormatForMail} from "../../../utils/helpers";

class EmailLog extends React.Component {

    breadcrumbNavigation = [
        {
            title: 'Emails',
            link: '#'
        },
        {
            title: "Email logs",
            link: '#'
        }
    ]
    state = {
        list: [],
        pagination: {
            current_page: 1,
            total_pages: '',
            total_records: '',
            itemsCountPerPage: 24
        },
        selected_rows: []
    }
    componentDidMount() {
        this.listing();
    }

    listing = async (page) => {
        try{
            $('#loader').show();
            // if(!data){
            let data = {
                current_page : page ? page : this.state?.pagination?.current_page
            }
            // }
            let res = await adminApiCall('email/email_logs','POST' , JSON.stringify(data))
            if (!res) {
                notify.show("Something_went_wrong",'error',2000);
                return false;
            }
            if ( res.hasOwnProperty('status') ) {
                if ( res.status === 200 && res.data ) {
                    this.setState({list: res.data,
                        pagination: {...this.state.pagination , current_page: res.current_page, total_pages: res.total_pages , total_records: res.total_records}})
                } else if ( res.status === 400 ) {
                    this.setState({list : []})
                    notify.show(res.message,'error',2000);
                }else if(res.status === 300){
                    this.setState({list:[]})
                }
            }
        }
        catch ( e ){
            notify.show("Something_gone_wrong",'error',2000);
        }
        finally {
            $('#loader').hide();
        }
    }

    //===== change status of user =========
    refreshEmails = async () => {
        try{
            $('#loader').show();
            let res = await adminApiCall('email/refresh_emails_logs','GET')
            if (!res) {
                notify.show("Something_went_wrong",'error',2000);
                return false;
            }
            if ( res.hasOwnProperty('status') ) {
                if ( res.status === 200 ) {
                    notify.show(res.message,'success',2000);
                    setTimeout(()=>{this.listing()}, 2000)
                    this.renderListing()
                } else if ( res.status === 400 ) {
                    notify.show(res.message,'error',2000);
                }
            }
        }
        catch ( e ){
            notify.show("Something_gone_wrong",'error',2000);
        }
        finally {
            $('#loader').hide();
        }
    }

    renderListing =  () => {
        let listing = [];
        if (this.state.list && this.state.list.length > 0) {
            try {
                for (const [index, element] of this.state.list.entries()) {
                    listing.push(
                        <tr key={element?._id+index}>
                            <td>
                                <div className="custom-control custom-checkbox">
                                    <input type="checkbox" className="custom-control-input customCheck" name={'selected_checkbox'} value={element?._id} id={element?._id}/>
                                    <label className="custom-control-label" htmlFor={element?._id}>&nbsp;</label>
                                </div>
                            </td>
                            <td className="pl-3">
                                {this.state?.pagination?.current_page > 1 ? (this.state?.pagination?.current_page*this.state?.pagination?.itemsCountPerPage)-this.state?.pagination?.itemsCountPerPage+index+1 :index + 1}
                            </td>

                            <td><i className="fa fa-star text-warning"/>
                                <span className="pl-2 mb-0 text-muted">{  JSON.parse(element?.email_content)?.data?.From.split("<")?.[0] }</span>

                            </td>
                            <td>
                                <span className="mb-0 text-muted">{  JSON.parse(element?.email_content)?.data?.To?.[0]?.Email }</span>
                            </td>

                            <td>
                                <a className="link"
                                   href="javascript: void(0)"
                                   onClick={() => this.props.history.push("view-email?id="+element?._id)}
                                >
                                    <span className="badge badge-pill text-white font-medium badge-danger mr-2">Subject</span>
                                    <span className="text-dark" >{  JSON.parse(element?.email_content)?.data?.Subject }</span>
                                </a>
                            </td>
                            <td className="text-muted">{  dateFormatForMail( JSON.parse(element?.email_content)?.data?.ReceivedAt ) }</td>
                        </tr>
                    )
                }
            } catch (e) {
                notify.show("Something_gone_wrong", 'error', 2000);
            }
            return listing;
        }else{
            listing.push(
                <tr>
                    <td colSpan={"6"}> No record found </td>
                </tr>
            )
            return listing
        }
    }

    deleteEmails(ids = '') {
        ids = ids.filter((ele) => {
            return ele !== "on"
        })
        if (ids){
            Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then(async (result) => {
                if (result.isConfirmed){
                    let res = await adminApiCall('email/delete','POST',JSON.stringify({email_ids:ids}))
                    if (!res) {
                        Swal.fire(
                            'Error!',
                            'Something went wrong Please try again.',
                            'error'
                        )
                        return false;
                    }
                    if ( res.hasOwnProperty('status') ) {
                        if ( res.status === 200) {
                            Swal.fire(
                                'Deleted!!',
                                'Record has been deleted.',
                                'success'
                            ).then(async  (result) => {
                                if (result.isConfirmed){
                                    this.listing()
                                }
                            })

                        } else if ( res.status === 400 ) {
                            notify.show(res.message,'error',1500);
                        }
                    }
                }
            })
        }else{
            notify.show("No record selected",'error',2000);
        }

    }
    handlePageChange(pageNumber){
        this.setState({pagination: {...this.state.pagination , current_page: pageNumber}} )
        this.listing(pageNumber)
    }

    selectAllCheckBox = (e) => {
        if (e.target.checked){
            $('.customCheck').each(function() {
                this.checked = true
            })
        }else{
            $('.customCheck').each(function() {
                this.checked = false
            })
        }
    }

    render() {
        return (
            /*Content Area*/
            <React.Fragment>

                <ChangeTitle title={'Emails'} />

                {/*SideBar*/}
                <SideBar />

                {/*Page Header*/}
                <PageHeader />
                <Notifications options={{zIndex: 10000}}/>
                <BreadCrumb navigation={this.breadcrumbNavigation} />
                {/* main content starts here */}
                <section className="pageWrapper">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="contentCard">
                                    <div className="cardHeader clearfix">
                                        <h1>Outbound Email logs</h1>
                                        <button type={"button"} className="myBtn btn-sm btn-primary mr-1"
                                                onClick={this.refreshEmails}
                                        > <i className={"fa fa-refresh mr-1"}/>  Refresh </button>
                                        <button type={"submit"} className="myBtn btn-sm btn-danger"
                                                onClick={() => {
                                                    let ids = []
                                                    $("input:checkbox[name=selected_checkbox]:checked").each(function () {
                                                        ids.push($(this).val());
                                                    });
                                                    if ( ids.length ) {
                                                        this.deleteEmails(ids)
                                                    }
                                                }}
                                        > <i className={"fa fa-trash mr-1"}/> Delete</button>
                                    </div>
                                    <div className="cardBody">
                                    {/*    =================================  */}
                                        <div className="tab-content" id="myTabContent">
                                            <div className="tab-pane fade active show" id="inbox"
                                                 aria-labelledby="inbox-tab" role="tabpanel">
                                                <div>
                                                    <div className="table-responsive customStriped">
                                                        <table
                                                            className="table email-table no-wrap table-hover v-middle mb-0 font-14">
                                                            <thead>
                                                                <tr>
                                                                    <th>
                                                                        <div className="custom-control custom-checkbox">
                                                                            <input type="checkbox" className="custom-control-input customCheck" id={"tableHeaderCheckbox"} name={'selected_checkbox'}  onChange={this.selectAllCheckBox} />
                                                                            <label className="custom-control-label" htmlFor={"tableHeaderCheckbox"}>&nbsp;</label>
                                                                        </div>
                                                                    </th>
                                                                    <th>#</th>
                                                                    <th>Send by</th>
                                                                    <th>Send to</th>
                                                                    <th>Subject</th>
                                                                    {/*<th>s3</th>*/}
                                                                    <th>Delivered date</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                            {this.renderListing()}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <div className="paginationWrapper mt-4 clearfix">
                                                        <span className={"table-result-info"}> Showing&nbsp;
                                                            {this.state?.pagination?.current_page * this.state?.pagination?.itemsCountPerPage - this.state?.pagination?.itemsCountPerPage + 1}&nbsp;
                                                            to&nbsp;
                                                            {this.state?.pagination?.current_page * this.state?.pagination?.itemsCountPerPage - this.state?.pagination?.itemsCountPerPage + this.state?.list?.length} of { this.state?.pagination?.total_records } entries </span>
                                                        <Pagination
                                                            activePage={this.state?.pagination?.current_page}
                                                            itemsCountPerPage={this.state?.pagination?.itemsCountPerPage}
                                                            totalItemsCount={Number(this.state?.pagination?.total_records)}
                                                            pageRangeDisplayed={5}
                                                            onChange={this.handlePageChange.bind(this)}
                                                            itemClass={"page-item"}
                                                            linkClass={"page-link"}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    {/*    =================================  */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <PageFooter />
            </React.Fragment>
        );
    }
}

export default EmailLog;

