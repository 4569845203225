/**
 * @author Basit Raza <razabasit88@gmail.com>
 * @link http://be.net/basit_raza Author Website
 * @since 2021-01-18
 */
import React from "react";
import PageHeader from "../../../../components/page-header";
import ChangeTitle from "../../../../components/change_page_title";
import PageFooter from "../../../../components/page-footer";
import SideBar from "../../../../components/side-bar";
import BreadCrumb from "../../../../components/bread-crumb";
import $ from "jquery";
import {
  adminApiCall,
  adminApiCallForFormData,
} from "../../../../utils/ajax_request";
import Notifications, { notify } from "react-notify-toast";
import Swal from "sweetalert2";
import { Editor } from "react-draft-wysiwyg";
import {
  ContentState,
  convertFromHTML,
  convertToRaw,
  convertFromRaw,
  EditorState,
} from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import EmailEditor from "react-email-editor";
// import TestEmail from "./TestEditor";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
class CreateEmailTemplate extends React.Component {
  breadcrumbNavigation = [
    {
      title: "Email Management",
      link: "#",
    },
    {
      title: "Create Email Template",
      link: "#",
    },
  ];
  state = {
    contentEditorState: EditorState.createEmpty(),
    contentEditorPlainHtml: "",

    recordDetail: "",
    subject: "",
    body: "",
    body_json: "",
  };
  emailEditorRef = React.createRef();
  formRef = React.createRef();


  //============== ck editors ===========
  // onContentEditorStateChange = (contentEditorState) => {
  //   let editorSourceHTML = draftToHtml(
  //     convertToRaw(contentEditorState.getCurrentContent())
  //   );
  //   this.setState({
  //     contentEditorState,
  //     contentEditorPlainHtml: editorSourceHTML,
  //   });
  //   console.log("editorSourceHTML", editorSourceHTML);
  // };
  //================ end ================
  // .....................................................................................................................
  //============== react email editor  ===========
  // onContentEditorStateChange = (contentEditorState) => {
  //   let editorSourceHTML = draftToHtml(
  //     convertToRaw(contentEditorState.getCurrentContent())
  //   );
  //   this.setState({
  //     contentEditorState,
  //     contentEditorPlainHtml: editorSourceHTML,
  //   });
  //   console.log("contentEditorState", contentEditorState);
  // };
  //================ end ================

  //
  componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search);
    const id = urlParams.get("id");
    console.log("id", id);
    console.log("id", urlParams);

    if (!id) {
      Swal.fire({
        title: "Error",
        text: "Invalid_access_error",
        icon: "error",
      });
    } else {
      this.getRecordDetail(id);
    }
  }
  getRecordDetail = async (id) => {
    try {
      $("#loader").show();
      let res = await adminApiCall(
        "email/email_template/get",
        "POST",
        JSON.stringify({ id: id })
      );
      if (!res) {
        Swal.fire({
          title: "Error",
          text: "Something_went_wrong",
          icon: "error",
        });
        return false;
      }
      if (res.hasOwnProperty("status")) {
        if (res?.status === 200 && res?.data) {
          console.log("api data ==>> ", res.data);
          this.setState({
            recordDetail: res?.data,
            subject: res?.data?.template?.subject,
            contentEditorPlainHtml: res?.data?.template?.body,
            contentEditorState: res?.data?.template?.body === ("" || null),
            // ? EditorState.createEmpty()
            // : EditorState.createWithContent(
            //     ContentState.createFromBlockArray(
            //       htmlToDraft(res?.data?.template?.body)
            //     )
            //   ),
            body_json: res?.data?.template?.body_json,
          });
          this.editor.loadDesign(JSON.parse(res?.data?.template?.body_json));
          console.log("body_json", this.state.body_json);
          // its give get result
          // this.editor.loadDesign({
          //   counters: { u_column: 1, u_row: 1, u_content_html: 1 },
          //   body: {
          //     id: "Q32KeIRuuC",
          //     rows: [
          //       {
          //         id: "tD9DxWmLvv",
          //         cells: [1],
          //         columns: [
          //           {
          //             id: "mDNkfAMHcy",
          //             contents: [
          //               {
          //                 id: "P5ylURpLZg",
          //                 type: "html",
          //                 values: {
          //                   html: "<strong>Hello, world!</strong>",
          //                   displayCondition: null,
          //                   containerPadding: "10px",
          //                   anchor: "",
          //                   _meta: {
          //                     htmlID: "u_content_html_1",
          //                     htmlClassNames: "u_content_html",
          //                   },
          //                   selectable: true,
          //                   draggable: true,
          //                   duplicatable: true,
          //                   deletable: true,
          //                   hideable: true,
          //                 },
          //               },
          //             ],
          //             values: {
          //               backgroundColor: "",
          //               padding: "0px",
          //               border: {},
          //               _meta: {
          //                 htmlID: "u_column_1",
          //                 htmlClassNames: "u_column",
          //               },
          //             },
          //           },
          //         ],
          //         values: {
          //           displayCondition: null,
          //           columns: false,
          //           backgroundColor: "",
          //           columnsBackgroundColor: "",
          //           backgroundImage: {
          //             url: "",
          //             fullWidth: true,
          //             repeat: "no-repeat",
          //             size: "custom",
          //             position: "center",
          //           },
          //           padding: "0px",
          //           anchor: "",
          //           hideDesktop: false,
          //           _meta: { htmlID: "u_row_1", htmlClassNames: "u_row" },
          //           selectable: true,
          //           draggable: true,
          //           duplicatable: true,
          //           deletable: true,
          //           hideable: true,
          //         },
          //       },
          //     ],
          //     values: {
          //       popupPosition: "center",
          //       popupWidth: "600px",
          //       popupHeight: "auto",
          //       borderRadius: "10px",
          //       contentAlign: "center",
          //       contentVerticalAlign: "center",
          //       contentWidth: "500px",
          //       fontFamily: {
          //         label: "Arial",
          //         value: "arial,helvetica,sans-serif",
          //       },
          //       textColor: "#000000",
          //       popupBackgroundColor: "#FFFFFF",
          //       popupBackgroundImage: {
          //         url: "",
          //         fullWidth: true,
          //         repeat: "no-repeat",
          //         size: "cover",
          //         position: "center",
          //       },
          //       popupOverlay_backgroundColor: "rgba(0, 0, 0, 0.1)",
          //       popupCloseButton_position: "top-right",
          //       popupCloseButton_backgroundColor: "#DDDDDD",
          //       popupCloseButton_iconColor: "#000000",
          //       popupCloseButton_borderRadius: "0px",
          //       popupCloseButton_margin: "0px",
          //       popupCloseButton_action: {
          //         name: "close_popup",
          //         attrs: {
          //           onClick:
          //             "document.querySelector('.u-popup-container').style.display = 'none';",
          //         },
          //       },
          //       backgroundColor: "#e7e7e7",
          //       backgroundImage: {
          //         url: "",
          //         fullWidth: true,
          //         repeat: "no-repeat",
          //         size: "custom",
          //         position: "center",
          //       },
          //       preheaderText: "",
          //       linkStyle: {
          //         body: true,
          //         linkColor: "#0000ee",
          //         linkHoverColor: "#0000ee",
          //         linkUnderline: true,
          //         linkHoverUnderline: true,
          //       },
          //       _meta: { htmlID: "u_body", htmlClassNames: "u_body" },
          //     },
          //   },
          //   schemaVersion: 12,
          // });
          // console.log('contentEditorPlainHtml',contentEditorPlainHtml)
        } else if (res?.status === 400) {
          Swal.fire({
            title: "Error",
            text: res?.message,
            icon: "error",
          });
        }
        // this.editor.loadDesign(JSON.parse(this.state.body_json));
      }
    } catch (e) {
      Swal.fire({
        title: "Error",
        text: "Something gone wrong",
        icon: "error",
      });
    } finally {
      $("#loader").hide();
    }
  };
  handleSubmit = async (event) => {
    try {
      event.preventDefault();
      let d = await this.getAll(this.editor);
    // this.exportHtml()
      console.log('promise object',{ d });
      $("#loader").show();
      let jsonData = {
        id: this?.state?.recordDetail?._id,
        template: {
          subject: this.state?.subject,
          //  body: this.state?.contentEditorPlainHtml,
          body:d.html,
          field: this.state?.recordDetail?.template?.field,
          // body_json: this.state?.body_json,
          body_json:JSON.stringify(d.design),
        },
      };
      console.log("data ==>> json", jsonData.body_json);
      console.log("data ==>> HTML", jsonData.body);

      // return;
      let res = await adminApiCall(
        "email/email_template/create_template",
        "POST",
        JSON.stringify(jsonData)
      );
      console.log("response", res);
      console.log("jsonData", res);

      if (!res) {
        notify.show("Something_went_wrong", "error", 2000);
        return false;
      }
      if (res.hasOwnProperty("status")) {
        if (res.status == 200) {
          notify.show(res.message, "success", 2000);
          // setTimeout(()=>{window.location.reload()},2000)
        } else if (res.status == 400) {
          notify.show(res.message, "error", 2000);
        }
      }
    } catch (e) {
      console.log(e);
      notify.show("Something_gone_wrong", "error", 2000);
    } finally {
      $("#loader").hide();
    }
  };



  // getBodyJson = (ref) => new Promise(function (resolve, reject) {

  //     this.editor.saveDesign((design) => {
  //         resolve(design)
  //     });
  // });

  onLoad = () => {
    // this.editor.addEventListener('onDesignLoad', this.onDesignLoad)
    // if(this.state.contentEditorPlainHtml) {
    //   let html = JSON.stringify(this.state.contentEditorPlainHtml);
    //   this.editor.loadDesign(html);
    //   console.log('contentEditorPlainHtml --------------------------',this.state.contentEditorPlainHtml)
    // }
  };

  getAll = (ref) =>
    new Promise(function (resolve, reject) {
      ref.exportHtml((data) => {
        resolve(data);
      });
    });

  exportHtml = () => {
     this.editor.exportHtml( (data) => {
      const { design, html } = data;
      console.log("json", JSON.stringify(design));
      console.log(html);
      this.setState({
        contentEditorPlainHtml: html,
        body_json: JSON.stringify(design),
      });
      notify.show(
        "Your Design Exported please Update the design now",
        "success",
        2000
      );
    });
  };
  render() {
    return (
      /*Content Area*/
      <React.Fragment>
        <ChangeTitle title={"Truck type"} />

        {/*SideBar*/}
        <SideBar />

        {/*Page Header*/}
        <PageHeader />
        <Notifications options={{ zIndex: 10000 }} />
        <BreadCrumb navigation={this.breadcrumbNavigation} />

        {/* main content starts here */}

        <section class="pageWrapper">
          <div class="container-fluid">
            <div class="row">
              <div class="col-12">
                <div class="contentCard">
                  <div class="cardHeader clearfix">
                    <h1>
                      {this.state?.recordDetail ? "Update" : "Create"} Email
                      Template
                    </h1>
                  </div>
                  <div className={"row"}>
                    <div className={"col-10 col-md-10"}>
                      <div class="cardBody">
                        <form onSubmit={this.handleSubmit}>
                          <div className="row formWrapper">
                            <div className="col-12 col-md-12">
                              <div className="form-group inlineField">
                                <div className="row align-items-center">
                                  <div className="col-12 col-md-4 col-lg-3">
                                    <label>Template Title</label>
                                  </div>
                                  <div className="col-12 col-md-8 col-lg-9">
                                    <input
                                      type="text"
                                      name={"title"}
                                      value={
                                        this.state?.recordDetail?.template_name
                                      }
                                      readOnly={true}
                                      style={{ background: "#e7e7e7" }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-12 col-md-12">
                              <div className="form-group inlineField">
                                <div className="row align-items-center">
                                  <div className="col-12 col-md-4 col-lg-3">
                                    <label>Subject </label>
                                  </div>
                                  <div className="col-12 col-md-8 col-lg-9">
                                    <input
                                      type="text"
                                      name={"subject"}
                                      value={this.state?.subject}
                                      onChange={(e) =>
                                        this.setState({
                                          subject: e.target.value,
                                        })
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/* <div dangerouslySetInnerHTML={{__html:this.state.contentEditorPlainHtml}}/> */}
                            <div className="col-12 col-md-12">
                              <div className="form-group inlineField">
                                <div className="row align-items-center">
                                  <div className="col-12 col-md-4 col-lg-3">
                                    <label>Body</label>
                                  </div>
                                  <div className="col-12 col-md-8 col-lg-9">
                                    {/* <Editor
																			id={'pageContent'}
																			name={'content'}
																			editorState={this.state.contentEditorState}
																			wrapperClassName="wrapperClassName"
																			editorClassName="editorClassName"
																			onEditorStateChange={this.onContentEditorStateChange}
																		/> */}
                                    {
                                      // this.state.contentEditorPlainHtml &&
                                      <EmailEditor
                                        ref={(editor) => (this.editor = editor)}
                                        onLoad={this.onLoad}
                                        onDesignLoad={this.onDesignLoad}
                                        // options={{ locale: "es-ES" }}

                                        // id={"pageContent"}
                                        // name={"content"}
                                        // editorState={
                                        //   this.state.contentEditorState
                                        // }
                                        // wrapperClassName="wrapperClassName"
                                        // editorClassName="editorClassName"
                                        // onEditorStateChange={
                                        //   this.onContentEditorStateChange
                                        // }
                                      />
                                    }
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-12 col-md-12 text-right">
                              <button
                                type={"submit"}
                                className="myBtn"
                                onClick={this.register}
                              >
                                Update
                              </button>
                            </div>
                            {/* <TestEmail /> */}
                            {/* <button
                              className="myBtn"
                              type="button"
                              onClick={this.exportHtml}
                            >
                              Export HTML
                            </button> */}
                          </div>
                        </form>
                      </div>
                    </div>
                    <div
                      className={"col-2 col-md-2"}
                      style={{ textAlign: "center" }}
                    >
                      <h6>Placeholders</h6>
                      <div
                        className="cardBody"
                        style={{
                          background: "#e7e2e2",
                          textAlign: "center",
                          border: "1px solid #cec8c8",
                          padding: "5px",
                          minHeight: "100px",
                          maxHeight: "400px",
                          overflow: "auto",
                        }}
                      >
                        {console.log(
                          "records list =====>>>>> ",
                          this.state?.recordDetail
                        )}
                        {this.state.recordDetail &&
                          this.state?.recordDetail?.template?.field &&
                          this.state?.recordDetail?.template?.field.map(
                            (val, index) => {
                              return (
                                <>
                                  <code style={{ color: "#354fcf" }}>
                                    {" "}
                                    {val}{" "}
                                  </code>
                                  {index !==
                                    this.state?.recordDetail?.template?.field
                                      .length -
                                      1 && <hr />}{" "}
                                </>
                              );
                            }
                          )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <PageFooter />
      </React.Fragment>
    );
  }
}

export default CreateEmailTemplate;
