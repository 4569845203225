import React from "react";
import ChangeTitle from "../../../../components/change_page_title";
import SideBar from "../../../../components/side-bar";
import PageHeader from "../../../../components/page-header";
import BreadCrumb from "../../../../components/bread-crumb";
class AddReservations extends React.Component {
  breadcrumbNavigation = [
    {
      title: "Manage Reservations",
      link: "#",
    },
    {
      title: "Add Reservation",
      link: "#",
    },
  ];
  handleSubmit = async(event) => {
    console.log(event,'fasdlfjasdhlk')
  } 
  render() {
    return (
      <React.Fragment>
        <ChangeTitle title="Reservations" />
        <SideBar />
        <PageHeader />
        <BreadCrumb navigation={this.breadcrumbNavigation} />
        <section class="pageWrapper">
          <div class="container-fluid">
            <div class="row">
              <div class="col-12">
                <div class="contentCard">
                  <div class="cardHeader clearfix">
                    <h1>Add Reservation</h1>
                  </div>
                  <div class="cardBody">
                    <form
                    onSubmit={this.handleSubmit}
                    >
                      <div className="row formWrapper">
                        <div className="col-12 col-md-12">
                          <div className="form-group inlineField">
                            <div className="row align-items-center">
                              <div className="col-12 col-md-4 col-lg-3">
                                <label>Name</label>
                              </div>
                              <div className="col-12 col-md-8 col-lg-9">
                                <input
                                  type="text"
                                  name={"name"}
                                  placeholder="Please enter your name"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-md-12">
                          <div className="form-group inlineField">
                            <div className="row align-items-center">
                              <div className="col-12 col-md-4 col-lg-3">
                                <label>Email</label>
                              </div>
                              <div className="col-12 col-md-8 col-lg-9">
                                <input
                                  type="text"
                                  name={"email"}
                                  placeholder="Please enter email address"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-md-12">
                          <div className="form-group inlineField">
                            <div className="row align-items-center">
                              <div className="col-12 col-md-4 col-lg-3">
                                <label>Phone</label>
                              </div>
                              <div className="col-12 col-md-8 col-lg-9">
                                <input
                                  name="telephone"
                                  type="number"
                                  placeholder="Please enter your phone number"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-md-12">
                          <div className="form-group inlineField">
                            <div className="row align-items-center">
                              <div className="col-12 col-md-4 col-lg-3">
                                <label>Provider</label>
                              </div>
                              <div className="col-12 col-md-8 col-lg-9">
                                <input
                                  name="provider"
                                  type="text"
                                  placeholder="Please enter provider"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-md-12">
                          <div className="form-group inlineField">
                            <div className="row align-items-center">
                              <div className="col-12 col-md-4 col-lg-3">
                                <label>Truck</label>
                              </div>
                              <div className="col-12 col-md-8 col-lg-9">
                                <input type="text" name={"truck"} />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-md-12">
                          <div className="form-group inlineField">
                            <div className="row align-items-center">
                              <div className="col-12 col-md-4 col-lg-3">
                                <label>Details</label>
                              </div>
                              <div className="col-12 col-md-8 col-lg-9">
                                <textarea type="text" name={"details"} />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-12 col-md-12 text-right">
                          <button
                            type={"submit"}
                            class="myBtn"
                            // onClick={this.register}
                          >
                            Add{" "}
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
export default AddReservations;
