/**
 * @author Basit Raza <razabasit88@gmail.com>
 * @link http://be.net/basit_raza Author Website
 * @since 2021-01-18
 */
import React from "react";
import PageHeader from "../../../components/page-header";
import ChangeTitle from "../../../components/change_page_title";
import PageFooter from "../../../components/page-footer";
import SideBar from "../../../components/side-bar";
import BreadCrumb from "../../../components/bread-crumb";
import $ from "jquery";
import {
  adminApiCall,
  adminApiCallForFormData,
} from "../../../utils/ajax_request";
import Notifications, { notify } from "react-notify-toast";
import Swal from "sweetalert2";
import ReactFancyBox from "react-fancybox";
import "react-fancybox/lib/fancybox.css";
import { baseDomain } from "../../../config";
class AddCountry extends React.Component {
  breadcrumbNavigation = [
    {
      title: "Manage Amenities",
      link: "#",
    },
    {
      title: "Edit Amenity",
      link: "#",
    },
  ];
  state = {
    recordDetail: "",
    isIconExist: false,
  };
  componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search);
    const id = urlParams.get("id");
    if (!id) {
      Swal.fire({
        title: "Error",
        text: "Invalid_access_error",
        icon: "error",
      });
    } else {
      this.getRecordDetail(id);
    }
  }
  getRecordDetail = async (id) => {
    try {
      $("#loader").show();
      let res = await adminApiCall(
        "amenities/get",
        "POST",
        JSON.stringify({ amenity_id: id })
      );
      if (!res) {
        Swal.fire({
          title: "Error",
          text: "Something_went_wrong",
          icon: "error",
        });
        return false;
      }
      if (res.hasOwnProperty("status")) {
        console.log(res);
        if (res?.status === 200 && res?.data) {
          this.setState({
            recordDetail: res?.data,
            isIconExist: res?.data?.icon[0]?.url,
          });
        } else if (res?.status === 400) {
          Swal.fire({
            title: "Error",
            text: res?.message,
            icon: "error",
          });
        }
      }
    } catch (e) {
      Swal.fire({
        title: "Error",
        text: "Something_gone_wrong",
        icon: "error",
      });
    } finally {
      $("#loader").hide();
    }
  };
  handleSubmit = async (event) => {
    try {
      $("#loader").show();
      event.preventDefault();
      let formData = new FormData(event.target);
      formData.append("amenity_id", this.state?.recordDetail?._id);
      let res = await adminApiCallForFormData("amenities/update", formData);
      if (!res) {
        notify.show("Something_went_wrong", "error", 2000);
        return false;
      }
      if (res.hasOwnProperty("status")) {
        if (res.status == 200) {
          notify.show(res.message, "success", 2000);
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        } else if (res.status == 400) {
          notify.show(res.message, "error", 2000);
        } else {
          notify.show(res.message, "error", 2000);
        }
      }
    } catch (e) {
      console.log(e);
      notify.show("Something_gone_wrong", "error", 2000);
    } finally {
      $("#loader").hide();
    }
  };

  render() {
    return (
      /*Content Area*/
      <React.Fragment>
        <ChangeTitle title={"Amenity"} />

        {/*SideBar*/}
        <SideBar />

        {/*Page Header*/}
        <PageHeader />
        <Notifications options={{ zIndex: 10000 }} />
        <BreadCrumb navigation={this.breadcrumbNavigation} />

        {/* main content starts here */}

        <section class="pageWrapper">
          <div class="container-fluid">
            <div class="row">
              <div class="col-12">
                <div class="contentCard">
                  <div class="cardHeader clearfix">
                    <h1>Edit Amenity</h1>
                  </div>
                  <div class="cardBody">
                    <form onSubmit={this.handleSubmit}>
                      <div className="row formWrapper">
                        <div className="col-12 col-md-12">
                          <div className="form-group inlineField">
                            <div className="row align-items-center">
                              <div className="col-12 col-md-4 col-lg-3">
                                <label>Title</label>
                              </div>
                              <div className="col-12 col-md-8 col-lg-9">
                                <input
                                  type="text"
                                  name={"title"}
                                  value={this.state?.recordDetail?.title}
                                  onChange={(e) =>
                                    this.setState({
                                      recordDetail: {
                                        ...this.state.recordDetail,
                                        title: e.target.value,
                                      },
                                    })
                                  }
                                  placeholder="Please enter a feature title."
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-md-12">
                          <div className="form-group inlineField">
                            <div className="row align-items-center">
                              <div className="col-12 col-md-4 col-lg-3">
                                <label>Feature Type </label>
                              </div>
                              <div className="col-12 col-md-8 col-lg-9">
                                <select
                                  name={"type"}
                                  value={this.state?.recordDetail?.type}
                                  onChange={(e) =>
                                    this.setState({
                                      recordDetail: {
                                        ...this.state.recordDetail,
                                        type: e.target.value,
                                      },
                                    })
                                  }
                                >
                                  <option value="storage_provider">
                                    Storage Provider
                                  </option>
                                  <option value="truck_rental">
                                    Truck Rental
                                  </option>
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-md-12">
                          <div className="form-group inlineField">
                            <div className="row align-items-center">
                              <div className="col-12 col-md-4 col-lg-3">
                                <label>Icon</label>
                              </div>
                              <div className="col-12 col-md-8 col-lg-9">
                                {/*<input name="icon" type="file" multiple />*/}
                                {this.state.isIconExist ? (
                                  <>
                                    <span
                                      className={
                                        "change-cursor-image-span-hover"
                                      }
                                      onClick={() =>
                                        this.setState({ isIconExist: false })
                                      }
                                    >
                                      X
                                    </span>
                                    <ReactFancyBox
                                      thumbnail={
                                        this.state?.recordDetail &&
                                        baseDomain +
                                          this.state?.recordDetail?.icon[0]?.url
                                      }
                                      image={
                                        this.state?.recordDetail &&
                                        baseDomain +
                                          this.state?.recordDetail?.icon[0]?.url
                                      }
                                    />
                                  </>
                                ) : (
                                  <div className="inputFile">
                                    <input name="icon" type="file" multiple />
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-md-12">
                          <div className="form-group inlineField">
                            <div className="row align-items-center">
                              <div className="col-12 col-md-4 col-lg-3">
                                <label>Description</label>
                              </div>
                              <div className="col-12 col-md-8 col-lg-9">
                                <textarea
                                  name="description"
                                  rows="5"
                                  value={this.state?.recordDetail?.description}
                                  onChange={(e) =>
                                    this.setState({
                                      recordDetail: {
                                        ...this.state.recordDetail,
                                        description: e.target.value,
                                      },
                                    })
                                  }
                                ></textarea>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-md-12">
                          <div className="form-group inlineField">
                            <div className="row align-items-center">
                              <div className="col-12 col-md-4 col-lg-3">
                                <label>Status</label>
                              </div>
                              <div className="col-12 col-md-8 col-lg-9">
                                <div className="toggleBtn">
                                  <input
                                    type="checkbox"
                                    id="togglePrice"
                                    checked={
                                      this.state?.recordDetail?.status === "1"
                                        ? "checked"
                                        : null
                                    }
                                    name={"status"}
                                    onChange={(e) =>
                                      this.setState({
                                        recordDetail: {
                                          ...this.state.recordDetail,
                                          status: e.target.checked,
                                        },
                                      })
                                    }
                                  />
                                  <label htmlFor="togglePrice">
                                    <span className="on">ON</span>
                                    <span className="off">OFF</span>
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-12 col-md-12 text-right">
                          <button
                            type={"submit"}
                            class="myBtn"
                            onClick={this.register}
                          >
                            Update{" "}
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <PageFooter />
      </React.Fragment>
    );
  }
}

export default AddCountry;
