import React from 'react';
import PageHeader from '../../../components/page-header';
import ChangeTitle from '../../../components/change_page_title';
import PageFooter from '../../../components/page-footer';
import SideBar from '../../../components/side-bar';
import BreadCrumb from '../../../components/bread-crumb';
// date time picker
import DateRangePicker from 'react-bootstrap-daterangepicker';
// you will also need the css that comes with bootstrap-daterangepicker
import 'bootstrap-daterangepicker/daterangepicker.css';
import $ from "jquery";
import {adminApiCall, adminRegister} from "../../../utils/ajax_request";
import Notifications, {notify} from "react-notify-toast";
// import {
// 	Async,
// 	FieldFeedback,
// 	FormWithConstraints
// } from 'react-form-with-constraints';
// import { FieldFeedbacks, FormGroup, FormControlLabel, FormControlInput } from 'react-form-with-constraints-bootstrap4';

class AddUser extends React.Component {
	breadcrumbNavigation = [
		{
			title: 'Manage Admin',
			link: '#'
		},
		{
			title: 'Add Admin',
			link: '#'
		}
	]

	state = {
		first_name: "",
		last_name: "",
		email: "",
		phone: "",
		role: ""
	}

	handleSubmit = async (event) => {
		try{
			$('#loader').show();
			event.preventDefault()
			let jsonData = {
				...this.state.first_name && {first_name: this.state.first_name},
				...this.state.last_name && {last_name: this.state.last_name},
				...this.state.email && {email: this.state.email},
				...this.state.phone && {phone: this.state.phone},
				role: "admin"
			}
			let res = await adminRegister('/auth/admin/register' ,JSON.stringify(jsonData))
			if(!res) {
				notify.show("Something_went_wrong",'error',2000);
				return false;
			}
			if ( res.hasOwnProperty('status') ) {
				if ( res.status === 200 ) {
					notify.show(res.message,'success',2000);
					setTimeout(()=>{window.location.reload()},2000)
				} else if ( res.status === 400 ) {
					notify.show(res.message,'error',2000);
				}
			}
		}catch (e) {
			console.log(e);
			notify.show("Something_gone_wrong",'error',2000);
		}finally {
			$('#loader').hide();
		}

	}

	handleChange = (e) => {
		if (e.target.id === 'first_name') {
			this.setState({ first_name: e.target.value });
		} else if (e.target.id === 'last_name') {
			this.setState({ last_name: e.target.value });
		} else if (e.target.id === 'email') {
			this.setState({ email: e.target.value });
		} else if (e.target.id === 'phone') {
			this.setState({ phone: e.target.value});
		}else if(e.target.id === 'role' ){
			this.setState({ role: "admin"});
		}
	}

	render() {
		return (
			/*Content Area*/
			<React.Fragment>

				<ChangeTitle title={'Manage Admin'} />

				{/*SideBar*/}
				<SideBar />

				{/*Page Header*/}
				<PageHeader />
				<Notifications options={{zIndex: 10000}}/>
				<BreadCrumb navigation={this.breadcrumbNavigation} />

				{/* main content starts here */}
				<section class="pageWrapper">
					<div class="container-fluid">
						<div class="row">
							<div class="col-12">
								<div class="contentCard">
									<div class="cardHeader">
										<h1>Create New Admin</h1>
									</div>
									<div class="cardBody formWrapper">

										<form
											onSubmit={this.handleSubmit}
										>
											<div className="row">
												<div className="col-12  col-md-4">
													<h2 class="cardInnerTitle clearfix">Select User Role</h2>
													<select id={"role"}
															onChange={this.handleChange}>
														{/*<option value="">User</option>*/}
														{/*<option value="">Staff</option>*/}
														<option value="admin">Admin</option>
													</select>
												</div>
											</div>
											<hr />
											<div className="row">
												<div className="col-12">
													<h2 class="cardInnerTitle clearfix">User Information</h2>
												</div>
												<div className="col-12 col-md-4">
													<label className="my-1">First Name</label>
													<input type="text" name={"first_name"}
															id={"first_name"}
															onChange={this.handleChange}/>
												</div>
												<div className="col-12 col-md-4">
													<label className="my-1">Last Name</label>
													<input type="text" name={"last_name"}
														   id={"last_name"}
														   onChange={this.handleChange}/>
												</div>
												<div className="col-12 col-md-4">
													<label className="my-1">Email</label>
													<input type="text" name={"email"}
														   id={"email"}
														   onChange={this.handleChange}/>
												</div>
												<div className="col-12 col-md-4">
													<label className="my-1">Phone Number</label>
													<input type="text"
														   id={"phone"}
														   onChange={this.handleChange}/>
												</div>
												{/*<div className="col-12 col-md-4">
													<label className="my-1">Account Password</label>
													<input type="text" ></input>
												</div>
												<div className="col-12 col-md-4">
													<label className="my-1">Confirm Password</label>
													<input type="text" ></input>
												</div>*/}

											</div>
											<div class="text-right">
												<button class="myBtn mt-2">Register</button>
											</div>
										</form>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
				<PageFooter />
			</React.Fragment>
		);
	}
}

export default AddUser;

