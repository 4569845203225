/**
 * @author Basit Raza <razabasit88@gmail.com>
 * @link http://be.net/basit_raza Author Website
 * @since 2021-01-18
 */
import React from 'react';
import PageHeader from '../../../components/page-header';
import ChangeTitle from '../../../components/change_page_title';
import PageFooter from '../../../components/page-footer';
import SideBar from '../../../components/side-bar';
import BreadCrumb from '../../../components/bread-crumb';

class AddRole extends React.Component {

	breadcrumbNavigation = [
		{
			title: 'Roles Management',
			link: '#'
		},
		{
			title: 'Create Roles',
			link: '#'
		}
	]

	render() {
		return (
			/*Content Area*/
			<React.Fragment>

				<ChangeTitle title={'Create Role'} />

				{/*SideBar*/}
				<SideBar />

				{/*Page Header*/}
				<PageHeader />

				<BreadCrumb navigation={this.breadcrumbNavigation} />

				{/* main content starts here */}
				<section class="pageWrapper">
					<div class="container-fluid">
						<div class="row">
							<div class="col-12">
								<div class="contentCard">
									<div class="cardHeader">
										<h1>Create New Role</h1>
									</div>
									<div className="cardBody">
										<div className="formWrapper">
											<form action="">
												<div className="row">
													<div className="col-12 col-md-4">
														<label>Role Title:</label>
														<input type="text" />
													</div>
												</div>
												<hr />
												<div className="row">
													<div className="col-12 col-md-6">
														<div className="form-group">
															<input class="customCheck" type="checkbox" id="all" name="all" value="all" />
															<label> Can view order </label>
														</div>
														<div className="form-group">
															<input class="customCheck" type="checkbox" id="all" name="all" value="all" />
															<label> Can view Users </label>
														</div>
														<div className="form-group">
															<input class="customCheck" type="checkbox" id="all" name="all" value="all" />
															<label> Can Create Users </label>
														</div>
													</div>
													<div className="col-12 col-md-6">
														<div className="form-group">
															<input class="customCheck" type="checkbox" id="all" name="all" value="all" />
															<label> Can Edit Users </label>
														</div>
														<div className="form-group">
															<input class="customCheck" type="checkbox" id="all" name="all" value="all" />
															<label> Can Disable Users </label>
														</div>
														<div className="form-group">
															<input class="customCheck" type="checkbox" id="all" name="all" value="all" />
															<label> Can View  </label>
														</div>
													</div>
												</div>
												<hr />	
												<div class="col-12 col-md-12 text-right">
													<button class="myBtn">Add</button>
												</div>
											</form>
										</div>
									</div>
								</div>

							</div>
						</div>
					</div>
				</section>
				<PageFooter />
			</React.Fragment>
		);
	}
}

export default AddRole;

