/**
 * @author Basit Raza <razabasit88@gmail.com>
 * @link http://be.net/basit_raza Author Website
 * @since 2021-01-18
 */
import React from 'react';
import PageHeader from '../../../components/page-header';
import ChangeTitle from '../../../components/change_page_title';
import PageFooter from '../../../components/page-footer';
import SideBar from '../../../components/side-bar';
import BreadCrumb from '../../../components/bread-crumb';

class RoleListing extends React.Component {

	breadcrumbNavigation = [
		{
			title: 'Roles Management',
			link: '#'
		},
		{
			title: 'Roles List',
			link: '#'
		}
	]

	render() {
		return (
			/*Content Area*/
			<React.Fragment>

				<ChangeTitle title={'Roles List'} />

				{/*SideBar*/}
				<SideBar />

				{/*Page Header*/}
				<PageHeader />

				<BreadCrumb navigation={this.breadcrumbNavigation} />

				{/* main content starts here */}
				<section class="pageWrapper">
					<div class="container-fluid">
						<div class="row">
							<div class="col-12">
								<div class="contentCard">
									<div class="cardHeader">
										<h1>All Roles List</h1>
										
										<button class="myBtn btn-success btnSm mr-2 pull-right" onClick={(e) => { this.props.history.push('add-role') }}>Create New Role</button>
									</div>
								</div>
								<div className="horizontalWrapper">
									<ul class="horizontalTabs nav nav-tabs" role="tablist">
										<li class="nav-item">
											<a class="nav-link active" data-toggle="tab" href="#popular" role="tab">User Role</a>
										</li>
										<li class="nav-item">
											<a class="nav-link" data-toggle="tab" href="#best" role="tab">Admin Role</a>
										</li>
									</ul>
									<div className="horizontalTabContent">
										<div class="tab-content">

											<div class="tab-pane active" id="popular" role="tabpanel">
												<div className="formWrapper">
													<form action="">
														<div className="form-group">
															<input class="customCheck" type="checkbox" checked id="all" name="all" value="all" />
															<label> Can view order </label>
														</div>
														<div className="form-group">
															<input class="customCheck" type="checkbox" checked id="all" name="all" value="all" />
															<label> Can view Users </label>
														</div>
														<div className="form-group">
															<input class="customCheck" type="checkbox" checked id="all" name="all" value="all" />
															<label> Can Create Users </label>
														</div>
														<div className="form-group">
															<input class="customCheck" type="checkbox" id="all" name="all" value="all" />
															<label> Can Edit Users </label>
														</div>
														<div className="form-group">
															<input class="customCheck" type="checkbox" id="all" name="all" value="all" />
															<label> Can Disable Users </label>
														</div>
														<div className="form-group">
															<input class="customCheck" type="checkbox" checked id="all" name="all" value="all" />
															<label> Can View  </label>
														</div>
														<hr />
														<button class="myBtn btn-success btnSm mr-2 pull-right">Save</button>
													</form>
												</div>
											</div>
											<div class="tab-pane" id="best" role="tabpanel">
												<div className="formWrapper">
													<form action="">
														<div className="form-group">
															<input class="customCheck" type="checkbox" checked id="all" name="all" value="all" />
															<label> Can view order </label>
														</div>
														<div className="form-group">
															<input class="customCheck" type="checkbox" checked id="all" name="all" value="all" />
															<label> Can view Users </label>
														</div>
														<div className="form-group">
															<input class="customCheck" type="checkbox" checked id="all" name="all" value="all" />
															<label> Can Create Users </label>
														</div>
														<div className="form-group">
															<input class="customCheck" type="checkbox" checked id="all" name="all" value="all" />
															<label> Can Edit Users </label>
														</div>
														<div className="form-group">
															<input class="customCheck" type="checkbox" id="all" name="all" value="all" />
															<label> Can Disable Users </label>
														</div>
														<div className="form-group">
															<input class="customCheck" type="checkbox" id="all" name="all" value="all" />
															<label> Can View  </label>
														</div>
														<hr />
														<button class="myBtn btn-success btnSm mr-2 pull-right">Save</button>
													</form>
												</div>
											</div>
										</div>
									</div>
								</div>

							</div>
						</div>
					</div>
				</section>
				<PageFooter />
			</React.Fragment>
		);
	}
}

export default RoleListing;

