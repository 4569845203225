/**
 * @author Basit Raza <razabasit88@gmail.com>
 * @link http://be.net/basit_raza Author Website
 * @since 2021-01-18
 */
import React from 'react';
import PageHeader from '../../../components/page-header';
import ChangeTitle from '../../../components/change_page_title';
import PageFooter from '../../../components/page-footer';
import SideBar from '../../../components/side-bar';
import BreadCrumb from '../../../components/bread-crumb';

class EditProduct extends React.Component {

	breadcrumbNavigation = [
		{
			title: 'Product Management',
			link: '#'
		},
		{
			title: 'Add Product',
			link: '#'
		}
	]
	render() {
		console.log(this.props);
		return (
			/*Content Area*/
			<React.Fragment>

				<ChangeTitle title={'Home'} />

				{/*SideBar*/}
				<SideBar />

				{/*Page Header*/}
				<PageHeader />

				<BreadCrumb navigation={this.breadcrumbNavigation} />

				{/* main content starts here */}

				<section class="pageWrapper">
					<div class="container-fluid">
						<div class="row">
							<div class="col-12">
								<div class="contentCard">
									<div class="cardHeader clearfix">
										<h1>Add Product</h1>
									</div>
									<div class="cardBody">
										<div class="row formWrapper">
											<div class="col-12 col-md-12">
												<div class="form-group inlineField">
													<div class="row align-items-center">
														<div class="col-12 col-md-4 col-lg-3">
															<label>Product Title <span>*</span></label>
														</div>
														<div class="col-12 col-md-8 col-lg-9">
															<input type="text" name="product_name"
																placeholder="Please enter a product name." />
														</div>
													</div>
												</div>
											</div>
											<div class="col-12 col-md-12">
												<div class="form-group inlineField">
													<div class="row align-items-center">
														<div class="col-12 col-md-4 col-lg-3">
															<label>Products Price Per unit <span>*</span> </label>
														</div>
														<div class="col-12 col-md-8 col-lg-9">
															<div className="inlineInput">
																<input type="number" name="price"
																	placeholder="$00.00" />
																<label class=" ml-2"> $</label>
															</div>
														</div>
													</div>
												</div>
											</div>
											<div class="col-12 col-md-12">
												<div class="form-group inlineField">
													<div class="row align-items-center">
														<div class="col-12 col-md-4 col-lg-3">
															<label>Products in Stock <span>*</span> </label>
														</div>
														<div class="col-12 col-md-8 col-lg-9">
															<input type="number" name="stock"
																placeholder="Quantity." />
														</div>
													</div>
												</div>
											</div>
											<div class="col-12 col-md-12">
												<div class="form-group inlineField">
													<div class="row align-items-center">
														<div class="col-12 col-md-4 col-lg-3">
															<label>Dimensions (in) <span>*</span> </label>
														</div>
														<div class="col-12 col-md-8 col-lg-9">
															<div className="row">
																<div class="col-12 col-md-4">
																	<div className="inlineInput">
																		<label>Length</label>
																		<input type="tel" name="length" placeholder="Length." />
																	</div>
																</div>
																<div class="col-12 col-md-4">
																	<div className="inlineInput">
																		<label>Width</label>
																		<input type="tel" name="width" placeholder="Width." />
																	</div>
																</div>
																<div class="col-12 col-md-4">
																	<div className="inlineInput">
																		<label>Height</label>
																		<input type="tel" name="height" placeholder="Height." />
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
											<div class="col-12 col-md-12">
												<div class="form-group inlineField">
													<div class="row align-items-center">
														<div class="col-12 col-md-4 col-lg-3">
															<label>Brand</label>
														</div>
														<div class="col-12 col-md-8 col-lg-9">
															<input type="tel" name="brand" placeholder="Brand." />
														</div>
													</div>
												</div>
											</div>
											<div class="col-12 col-md-12">
												<div class="form-group inlineField">
													<div class="row align-items-center">
														<div class="col-12 col-md-4 col-lg-3">
															<label>Product Code</label>
														</div>
														<div class="col-12 col-md-8 col-lg-9">
															<input type="tel" name="code" placeholder="Product Code." />
														</div>
													</div>
												</div>
											</div>
											<div class="col-12 col-md-12">
												<div class="form-group inlineField">
													<div class="row align-items-center">
														<div class="col-12 col-md-4 col-lg-3">
															<label>Color</label>
														</div>
														<div class="col-12 col-md-8 col-lg-9">
															<input type="color" name="code" placeholder="Product Code." />
														</div>
													</div>
												</div>
											</div>
											<div class="col-12 col-md-12">
												<div class="form-group inlineField">
													<div class="row align-items-center">
														<div class="col-12 col-md-4 col-lg-3">
															<label>Product thumbnail</label>
														</div>
														<div class="col-12 col-md-8 col-lg-9">
															<label>* File extension jpg, gif, png</label>
															<div className="inputFile">
																<input name="product_thumbnails" type="file" multiple />
															</div>
														</div>
													</div>
												</div>
											</div>
											<div class="col-12 col-md-12">
												<div class="form-group inlineField">
													<div class="row align-items-center">
														<div class="col-12 col-md-4 col-lg-3">
															<label>Product Multiple Images</label>
														</div>
														<div class="col-12 col-md-8 col-lg-9">
															<label>* File extension jpg, gif, png</label>
															<div className="inputFile">
																<input name="product_thumbnails" type="file" multiple />
															</div>
														</div>
													</div>
												</div>
											</div>
											<div class="col-12 col-md-12">
												<div class="form-group inlineField">
													<div class="row align-items-center">
														<div class="col-12 col-md-4 col-lg-3">
															<label>Product Description </label>
														</div>
														<div class="col-12 col-md-8 col-lg-9">
															<textarea name="product_description" rows="5"></textarea>
														</div>
													</div>
												</div>
											</div>
											<div class="col-12 col-md-12 text-right">
												<button class="myBtn">Register </button>
											</div>
										</div>

									</div>
								</div>
							</div>
						</div>
					</div>
				</section>

				<PageFooter />
			</React.Fragment>
		);
	}
}

export default EditProduct;

