import React from "react";
import ChangeTitle from "../../../../components/change_page_title";
import SideBar from "../../../../components/side-bar";
import PageHeader from "../../../../components/page-header";
import Notifications, { notify } from "react-notify-toast";
import BreadCrumb from "../../../../components/bread-crumb";
import Swal from "sweetalert2";
class EditReservations extends React.Component {
  breadcrumbNavigation = [
    {
      title: "Manage Reservation",
      link: "#",
    },
    {
      title: "Edit Reservations",
      link: "#",
    },
  ];
  state = {
    recordDetails: "",
    isIconExist: false,
  };
  listing = [
    {
      id: 1,
      name: "John Doe",
      email: "john.doe@example.com",
      phone: "123-456-7890",
      provider: "ABC Logistics",
      truck: "Ford F-150",
      details: {
        year: 2020,
        licensePlate: "XYZ 1234",
      },
    },
    {
      id: 2,
      name: "Jane Smith",
      email: "jane.smith@example.com",
      phone: "987-654-3210",
      provider: "XYZ Transport",
      truck: "Chevrolet Silverado",
      details: {
        year: 2019,
        licensePlate: "ABC 5678",
      },
    },
    {
      id: 3,
      name: "Mike Johnson",
      email: "mike.johnson@example.com",
      phone: "555-123-4567",
      provider: "123 Freight",
      truck: "Ram 1500",
      details: {
        year: 2021,
        licensePlate: "LMN 9101",
      },
    },
    {
      id: 4,
      name: "Emily Davis",
      email: "emily.davis@example.com",
      phone: "444-789-0123",
      provider: "Fast Movers",
      truck: "Toyota Tundra",
      details: {
        year: 2018,
        licensePlate: "JKL 3456",
      },
    },
  ];
  componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search);
    const id = urlParams.get("id");
    console.log(id, "fadsfasdfasfdasId");
    if (!id) {
      Swal.fire({
        title: "Error",
        text: "Invalid_access_error",
        icon: "error",
      });
    } else {
      this.getRecordDetail(id);
    }
  }
  getRecordDetail = (id) => {
    const obj = this.listing.find((element) => element.id === Number(id));
    this.setState({
      recordDetails: obj,
      isIconExist: false,
    });
  };
  render() {
    return (
      <React.Fragment>
        <ChangeTitle title="Manage User" />
        <SideBar />
        <PageHeader />
        <Notifications options={{ zIndex: 10000 }} />
        <BreadCrumb navigation={this.breadcrumbNavigation} />
        <section class="pageWrapper">
          <div class="container-fluid">
            <div class="row">
              <div class="col-12">
                <div class="contentCard">
                  <div class="cardHeader clearfix">
                    <h1>Edit Reservation</h1>
                  </div>
                  <div class="cardBody">
                    <form onSubmit={this.handleSubmit}>
                      <div className="row formWrapper">
                        <div className="col-12 col-md-12">
                          <div className="form-group inlineField">
                            <div className="row align-items-center">
                              <div className="col-12 col-md-4 col-lg-3">
                                <label>Name</label>
                              </div>
                              <div className="col-12 col-md-8 col-lg-9">
                                <input
                                  type="text"
                                  value={this.state.recordDetails.name}
                                  onChange={(e) =>
                                    this.setState({
                                      recordDetails: {
                                        ...this.state.recordDetails,
                                        name: e.target.value,
                                      },
                                    })
                                  }
                                  name={"name"}
                                  placeholder="Please enter your name"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-md-12">
                          <div className="form-group inlineField">
                            <div className="row align-items-center">
                              <div className="col-12 col-md-4 col-lg-3">
                                <label>Email</label>
                              </div>
                              <div className="col-12 col-md-8 col-lg-9">
                                <input
                                  value={this.state.recordDetails.email}
                                  onChange={(e) =>
                                    this.setState({
                                      recordDetails: {
                                        ...this.state.recordDetails,
                                        email: e.target.value,
                                      },
                                    })
                                  }
                                  type="text"
                                  name={"email"}
                                  placeholder="Please enter email address"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-md-12">
                          <div className="form-group inlineField">
                            <div className="row align-items-center">
                              <div className="col-12 col-md-4 col-lg-3">
                                <label>Phone</label>
                              </div>
                              <div className="col-12 col-md-8 col-lg-9">
                                <input
                                  value={this.state.recordDetails.phone}
                                  onChange={(e) =>
                                    this.setState({
                                      recordDetails: {
                                        ...this.states.recordDetail,
                                        phone: e.target.value,
                                      },
                                    })
                                  }
                                  name="phone"
                                  type="text"
                                  placeholder="Please enter your phone number"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-md-12">
                          <div className="form-group inlineField">
                            <div className="row align-items-center">
                              <div className="col-12 col-md-4 col-lg-3">
                                <label>Provider</label>
                              </div>
                              <div className="col-12 col-md-8 col-lg-9">
                                <input
                                  value={this.state.recordDetails.provider}
                                  onChange={(e) =>
                                    this.setState({
                                      recordDetails: {
                                        ...this.state.recordDetails,
                                        provider: e.target.value,
                                      },
                                    })
                                  }
                                  name="provider"
                                  type="text"
                                  placeholder="Please enter provider"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-md-12">
                          <div className="form-group inlineField">
                            <div className="row align-items-center">
                              <div className="col-12 col-md-4 col-lg-3">
                                <label>Truck</label>
                              </div>
                              <div className="col-12 col-md-8 col-lg-9">
                                <input
                                  value={this.state.recordDetails.truck}
                                  onChange={(e) =>
                                    this.setState({
                                      recordDetails: {
                                        ...this.state.recordDetails,
                                        truck: e.target.value,
                                      },
                                    })
                                  }
                                  type="text"
                                  name={"truck"}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-md-12">
                          <div className="form-group inlineField">
                            <div className="row align-items-center">
                              <div className="col-12 col-md-4 col-lg-3">
                                <label>Details</label>
                              </div>
                              <div className="col-12 col-md-8 col-lg-9">
                                <textarea
                                  value={
                                    this.state.recordDetails.details
                                      ?.licensePlate
                                  }
                                  onChange={(e) =>
                                    this.setState({
                                      recordDetail: {
                                        ...this.state.recordDetails.details,
                                        licensePlate: e.target.value,
                                      },
                                    })
                                  }
                                  type="text"
                                  name={"details"}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-12 col-md-12 text-right">
                          <button
                            type={"submit"}
                            class="myBtn"
                            // onClick={this.register}
                          >
                            Add{" "}
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
export default EditReservations;
