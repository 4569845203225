/**
 * @author Basit Raza <razabasit88@gmail.com>
 * @link http://be.net/basit_raza Author Website
 * @since 2021-01-18
 */
import React from "react";
import { Route, withRouter } from "react-router-dom";
import { logout, snakeToCamel } from "../../utils/helpers";

class PageHeader extends React.Component {
  // componentDidMount() {
  // 	if (!localStorage.getItem('adminToken')) {
  // 		window.location.href = '/';
  // 	}
  // }
  render() {
    return (
      <section class="topBar">
        <div class="topBarInnwe">
          <div class="logoWrapper">
            <a
              class="logo"
              href="javascript:void(0)"
              onClick={() => this.props.history.push("/dashboard")}
              style={{ color: "white !important" }}
            >
              {/*<img src="/assets/images/logowhite.svg" alt="" />*/}
              SpaceShuttle
            </a>
            <span class="toggleMenu">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-list"
              >
                <line x1="8" y1="6" x2="21" y2="6"></line>
                <line x1="8" y1="12" x2="21" y2="12"></line>
                <line x1="8" y1="18" x2="21" y2="18"></line>
                <line x1="3" y1="6" x2="3" y2="6"></line>
                <line x1="3" y1="12" x2="3" y2="12"></line>
                <line x1="3" y1="18" x2="3" y2="18"></line>
              </svg>
            </span>
          </div>
          <div class="navWrapper">
            <div class="searchField">
              <input type="text" placeholder="Search" />
              <button>
                <i class="fa fa-search"></i>
              </button>
            </div>
            <div class="dropdown">
              <button
                type="button"
                class="profileDropdown"
                data-toggle="dropdown"
              >
                <img src="/assets/images/user.jpg" />
              </button>
              <div class="dropdown-menu profileCard">
                <div class="profileHeader">
                  <div class="profileImg">
                    <img src="/assets/images/user.jpg" alt="" />
                  </div>
                  <div class="proDetails">
                    <h3>
                      {localStorage.getItem("adminName")
                        ? localStorage.getItem("adminName")
                        : "Admin"}
                    </h3>
                    <span>
                      {localStorage.getItem("role")
                        ? snakeToCamel(localStorage.getItem("role"))
                        : "Admin"}
                    </span>
                  </div>
                </div>
                <ul>
                  <li>
                    <a href="javascript:void(0)">
                      <i class="fa fa-user"></i>My Profile
                    </a>
                  </li>
                  <li>
                    <a
                      href="javascript:void(0)"
                      onClick={() => this.props.history.push("account-setting")}
                    >
                      <i class="fa fa-cog"></i>Account Settings
                    </a>
                  </li>
                  <li>
                    <a href="javascript:void(0)" onClick={logout}>
                      <i class="fa fa-sign-out"></i>Logout
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default withRouter(PageHeader);
